import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

type Anchor = "right";

export default function SideDrawer({
  state,
  setState,
  children,
}: {
  state: boolean;
  children: React.ReactNode;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const toggleDrawer = (newOpen: boolean) => () => {
    setState(newOpen);
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 500, padding: 5 }}
      role="presentation"
      // onClick={toggleDrawer(false)}
      // onKeyDown={toggleDrawer(false)}
    >
      {children}
    </Box>
  );

  return (
    <div>
      <Drawer anchor={"right"} open={state} onClose={toggleDrawer(false)}>
        {list("right")}
      </Drawer>
    </div>
  );
}
