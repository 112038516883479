import React, { useState } from "react";
import { GoDash } from "react-icons/go";
import { IoCopyOutline } from "react-icons/io5";
import { error } from "../../../../services/toastService";
import { useCreateCallLog } from "../../../../hooks/useHospital";
import { useGlobalContext } from "../../../../contexts/globalContext";
import { Controller, SubmitHandler, UseFormReturn } from "react-hook-form";
import { z } from "zod";

export const historyNoteSchema = z.object({
  note: z.string({ message: "Note is required" }).min(1, "Note is required"),
});
export type HistoryNoteFormData = z.infer<typeof historyNoteSchema>;
export default function VisitOrCallComponent({
  form,
  submit,
}: {
  form: UseFormReturn<HistoryNoteFormData>;
  submit: SubmitHandler<HistoryNoteFormData>;
}) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedOption, setSelectedOption] = useState("Needs Nerve Training");

  const [fileRequiredError, setFileRequiredError] = useState(false);
  const [optionRequiredError, setOptionRequiredError] = useState(false);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setFileRequiredError(false);
  };

  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
    setOptionRequiredError(false);
  };

  const dropDown = [
    {
      name: "Needs Nerve Training",
    },
    {
      name: "To learn Nerve",
    },
    {
      name: "Re-pitch",
    },
    {
      name: "Will place an order",
    },
  ];

  return (
    <div>
      <>
        <form
          className="w-11/12 mx-auto bg-[#FFFFFF] shadow-white shadow-lg p-3"
          onSubmit={form.handleSubmit(submit)}
        >
          <div className="mx-auto w-11/12 p-1">
            <p className="pb-4 text-2xl">Log notes</p>
            <Controller
              name="note"
              control={form.control}
              render={({ field }) => (
                <textarea
                  {...field}
                  placeholder="Post Reason..."
                  className={`w-full p-1 border ${
                    form.formState.errors.note
                      ? "!border-red-500"
                      : "border-gray-300"
                  } rounded-md focus:border-none active:ring-0 active:border-none`}
                />
              )}
            />

            {form.formState.errors.note && (
              <p className="text-red-500">
                {form.formState.errors.note.message}
              </p>
            )}
          </div>

          <div className="w-11/12 mx-auto mb-4">
            <label
              htmlFor="fileInput"
              className="flex items-center cursor-pointer"
            >
              <IoCopyOutline size={25} />
              <span className="ml-3 text-1xl">
                Attach File <span className="italic">(Optional)</span>
              </span>
              <p className="text-[#4D26A0] pl-2 pr-2">
                {selectedFile && selectedFile?.name}{" "}
              </p>
            </label>
            <input
              id="fileInput"
              type="file"
              accept="image/jpeg, image/png, .pdf"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            {fileRequiredError && (
              <p className="text-red-500">File is required</p>
            )}
          </div>
          <div className="w-11/12 mx-auto">
            <p className="mb-2"> File meeting result</p>
            <select
              className="w-full border border-black rounded p-2"
              value={selectedOption}
              onChange={handleSelectChange}
            >
              {dropDown.map((ele) => {
                return (
                  <option
                    key={ele.name}
                    className="border border-black rounded"
                  >
                    {ele.name}
                  </option>
                );
              })}
            </select>
            {optionRequiredError && (
              <p className="text-red-500">Meeting result is required</p>
            )}

            <div className="w-full mt-4 ">
              <button
                disabled={form.formState.isSubmitting}
                className="w-full text-2xl font-bold text-[white] bg-[#4D26A0] rounded p-2"
              >
                {form.formState.isSubmitting ? "Submitting" : "Submit Note"}
              </button>
            </div>
          </div>
        </form>
      </>
    </div>
  );
}
