import React, { useCallback, useEffect, useMemo, useState } from "react";

import Header from "../../../../components/blocks/header";
import SearchQuery from "../../../../components/forms/searchQuery";

import { useGlobalContext } from "../../../../contexts/globalContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import applyFilters from "../../../../helpers/urlState";
import ProductTable, {
  ProductTableColumn,
} from "../../../../components/common/ProductTable";
import { Hospital } from "../../../../ts-utils/types";
import { SyncLoader } from "react-spinners";
import ReactSelect from "../../../../components/common/ReactSelect";
import { useGetAgentAdminHospitals } from "../../../../hooks/agent/useAgent";
import { cn } from "../../../../@/lib/utils";

const filterStatus = [
  {
    value: "",
    label: "All",
  },
  {
    value: "pre-churn",
    label: "Pre Churn",
  },
  {
    value: "danger-group",
    label: "Danger",
  },
  {
    value: "general-ward-group",
    label: "Add Value",
  },
  {
    value: "check-in-group",
    label: "Check in",
  },
  {
    value: "healthy-group",
    label: "Upsell",
  },
];
const HealthScorePage = (): JSX.Element => {
  const { user } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");

  const pageSize: number = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState<string>(
    searchParams.get("status") || ""
  );
  const [search, setSearch] = useState(searchParams.get("search") || "");

  const { hospitals, paginate, isPending } = useGetAgentAdminHospitals({
    page: currentPage,
    limit: pageSize,
    name: search,
    userId: user?.id as string,
    health_status: status,
    status: "exodus",
  });

  const hospitalHealth = hospitals?.map(
    (health: {
      id: string | number;
      name: string;
      health_score: string | number;
      productlist: string;
      score_track: {
        group: string;
        hascomplaint: boolean;
        latedelivery: boolean;
        placedorder: boolean;
        productscount: number;
        usenerve: boolean;
      } | null;
      reward_track: {
        usenervepay: boolean;
        referredothers: boolean;
        onrewardprogramme: boolean;
        creditplan: boolean;
      } | null;
    }) => {
      const scoreTrack =
        typeof health?.score_track === "string"
          ? JSON.parse(health.score_track)
          : health.score_track;

      const rewardTrack =
        typeof health?.reward_track === "string"
          ? JSON.parse(health.reward_track)
          : health.reward_track;

      const id = health.id;
      const hospital_name = health.name;
      const health_score = health.health_score;
      const ordered = scoreTrack.placedorder ? "Yes" : "No";
      const product = scoreTrack.productscount;
      const nerve_usage = scoreTrack.usenerve ? "Yes" : "No";
      const hot_complaint = scoreTrack.hascomplaint ? "Yes" : "No";
      const late_delivery = scoreTrack.latedelivery ? "Yes" : "No";
      const referrals = rewardTrack.referredothers ? "Yes" : "No";
      const reward = rewardTrack.onrewardprogramme ? "Yes" : "No";
      const pay_plan = rewardTrack.creditplan ? "Yes" : "No";
      const segment =
        +health_score < 0
          ? "Pre-Churn"
          : +health_score <= 1.49
          ? "Danger-Group"
          : +health_score <= 2.49
          ? "General Ward Group"
          : +health_score <= 3.6
          ? "Check-in Group"
          : "Healthy Group";

      return {
        id,
        hospital_name,
        health_score,
        ordered,
        product,
        nerve_usage,
        hot_complaint,
        late_delivery,
        referrals,
        reward,
        pay_plan,
        segment,
      };
    }
  );

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "hospital_name":
        return (
          <>
            {hospital?.id ? (
              <Link
                className="text-[#49209F]  underline font-bold"
                to={`/hospitals/${hospital?.id}`}
              >
                {hospital?.hospital_name ?? "N/A"}
              </Link>
            ) : (
              <> {hospital?.hospital_name ?? "N/A"}</>
            )}
          </>
        );
      case "health_score":
        return (
          <span
            className={cn(
              +hospital[column.accessor] < 1.49 && "font-bold text-[#f44336]"
            )}
          >
            {hospital[column.accessor]}
          </span>
        );

      default:
        return (
          <span
            className={cn(
              hospital[column.accessor] === "Yes" && "font-bold text-[#3B82F6]"
            )}
          >
            {hospital[column.accessor] ?? "N/A"}
          </span>
        );
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Hospital Name", accessor: "hospital_name" },
    { header: "Ordered", accessor: "ordered" },
    { header: "Product", accessor: "product" },
    { header: "Nerve Usage", accessor: "nerve_usage" },
    { header: "Hot Complaint", accessor: "hot_complaint" },
    {
      header: "Late Delivery",
      accessor: "late_delivery",
      className: "text-nowrap",
    },
    { header: "Referrals", accessor: "referrals", className: "max-w-32 w-32" },
    { header: "Reward", accessor: "reward", className: "max-w-32 w-32" },
    { header: "Pay Plan", accessor: "pay_plan" },
    { header: "Health Score", accessor: "health_score" },
    { header: "Segment", accessor: "segment" },
  ];

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);

  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search]
  );

  const handleStatusChange = (status: React.SetStateAction<string>) => {
    setStatus(status);
    applyFilters(
      {
        page: 1,
        status,
      },
      navigate,
      pathname
    );
  };

  const handleSearch = (e: any) => {
    setSearch(e);
    applyFilters(
      {
        page: 1,
        search: e,
        status,
      },
      navigate,
      pathname
    );
  };
  return (
    <React.Fragment>
      <Header title="Health Score" />

      {isPending ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isPending}
            className="relative"
            size={30}
          />
        </div>
      ) : (
        <div className="hospitals-container">
          <div className="hospitals-container-top">
            <SearchQuery
              name="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => handleSearch(e.currentTarget.value)}
            />

            <div className="right-options">
              <ReactSelect
                placeholder="Filter by Status"
                defaultValue={status}
                options={filterStatus?.map(
                  (status: { value: string; label: string }) => ({
                    value: status.value,
                    label: status.label,
                  })
                )}
                onChange={handleStatusChange}
              />
            </div>
          </div>

          <div className="hospitals-container-bottom table-responsive mb-5">
            <>
              <ProductTable
                data={hospitalHealth ?? []}
                columns={columns}
                renderCell={renderCell}
                totalPages={paginate?.totalPages}
                currentPage={currentPage}
                goToPage={goToPage}
                noDataMessage={"You Currently don't have any Health Score"}
              />
            </>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default HealthScorePage;
