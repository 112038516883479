import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";

import Header from "../../../../components/blocks/header";
import SearchQuery from "../../../../components/forms/searchQuery";

import { getPrivileges } from "../../../../services/authService";

import { useGlobalContext } from "../../../../contexts/globalContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import applyFilters from "../../../../helpers/urlState";
import ProductTable, {
  ProductTableColumn,
} from "../../../../components/common/ProductTable";
import { Hospital } from "../../../../ts-utils/types";
import { SyncLoader } from "react-spinners";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { Button } from "../../../../@/components/ui/button";
import { Bell, ChevronDown, Loader, MoreVertical } from "lucide-react";
import { z } from "zod";
import {
  useChurnVisit,
  useGetAgentAdminHospitals,
  useGetAllAgents,
  useGetPitchDetails,
  useLogHistoryNote,
  usePartialChurnAction,
  useUpdateReboard,
} from "../../../../hooks/agent/useAgent";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { Separator } from "../../../../@/components/ui/separator";

import DateCell from "../../../../components/DateCell";
import { AgentFilter } from "../hospitals/hospitals";

import ChurnPipeline from "./ChurnPipeline";
import {
  useGetFeed,
  useGetHospitalsCount,
} from "../../../../hooks/useDashboard";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../@/components/ui/select";
import { Label } from "../../../../@/components/ui/label";
import utilities from "../../../../utils/utilities";
import utils from "../../../../utils/utilities";
import RequestTable from "../hospitals/RequestHistory";
import ActivityHistory from "../../../../components/modals/HistoryModal";
import LogVisit from "../../../../components/modals/logVisit";
import { cn } from "../../../../@/lib/utils";
import { HospitalActivityStream } from "../../../../components/exodusComponent/Card";
import { useGetAllExodus } from "../../../../hooks/useExodus";
import { AiOutlineContacts } from "react-icons/ai";
import { MdOutlineHome } from "react-icons/md";
import VisitOrCallComponent, {
  HistoryNoteFormData,
  historyNoteSchema,
} from "../hospitals/VisitOrCallComponent";
import { useReadyToPitch } from "../../../../hooks/usePipeline";
import OptimizedRequestInfo, {
  PitchFormData,
  pitchFormSchema,
} from "../pipeline/close/optimized";
import {
  useCloseAppointment,
  useGetPitchInformation,
  useGetSinglePendingRequest,
  useReserveAppointment,
  useScheduleAppointment,
  useSendToClose,
} from "../../../../hooks/sales/useAppointment";
import SaleType from "../pipeline/pitch/saleType";
import PitchDetails from "../pipeline/pitch/pitchDetails";
import TimePicker from "../../../../components/common/TimePicker";
import CustomDatePicker from "../../../../components/common/datePicker";
import RadioButton from "../../../../components/common/radio";
import { Card, CardContent } from "../../../../@/components/ui/card";
import Pitching from "../pipeline/pitch/Pitching";
import { error, success } from "../../../../services/toastService";
import dayjs from "dayjs";
import OnboardChecklist from "../pipeline/onboard/onboardChecklist";

const churnReasonSchema = z
  .object({
    reason: z
      .string({ message: "Reason is required" })
      .min(1, "Reason is required"),
    action: z
      .string({ message: "Action Step is required" })
      .min(1, "Action Step is required"),
    reboardable: z.enum(["Yes", "No"], {
      required_error: "Reboardable is required",
    }),
    reboardable_date: z.date().optional(),
  })
  .refine(
    (data) => data.reboardable !== "Yes" || data.reboardable_date !== undefined,
    {
      message: "Reboardable Date is required when Reboardable is Yes",
      path: ["reboardable_date"],
    }
  );

const visitSchema = z
  .object({
    hasReason: z.enum(["Yes", "No"], {
      required_error: "This is required",
    }),
    reason: z.string({ message: "Reason is required" }).optional(),
    reasonresolvable: z.enum(["Yes", "No"]).optional(),
  })
  .refine((data) => data.hasReason !== "Yes" || data.reason !== undefined, {
    message: "Reason is required when reason for churn is Yes",
    path: ["reason"],
  })
  .refine(
    (data) => data.hasReason !== "Yes" || data.reasonresolvable !== undefined,
    {
      message: "Reason Resolvable is required when reason for churn is Yes",
      path: ["reasonresolvable"],
    }
  );

type ChurnReasonFormData = z.infer<typeof churnReasonSchema>;
type VisitFormData = z.infer<typeof visitSchema>;
type ChurnHospital = {
  id: string | number;
  name: string;
  health_score: string;
  productlist: string;
  churn_visited: string;
  reasonresolvable: string;
  last_order_date: string;
  reason: string;
  type: string;
  score_track: {
    group: string;
    hascomplaint: boolean;
    latedelivery: boolean;
    placedorder: boolean;
    productscount: number;
    usenerve: boolean;
  } | null;
  reward_track: {
    usenervepay: boolean;
    referredothers: boolean;
    onrewardprogramme: boolean;
    creditplan: boolean;
  } | null;
};

const addColumn = (
  columns: ProductTableColumn[],
  newColumn: ProductTableColumn,
  index: number
) => {
  columns.splice(index, 0, newColumn);
};
const removeColumn = (columns: ProductTableColumn[], accessor: string) => {
  const index = columns.findIndex((col) => col.accessor === accessor);
  if (index !== -1) {
    columns.splice(index, 1);
  }
};
const addColumnToEnd = (
  columns: ProductTableColumn[],
  newColumn: ProductTableColumn
) => {
  columns.push(newColumn);
};

const ChurnType = (): JSX.Element => {
  const privileges = getPrivileges();
  const { user } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");
  const lastPathname = pathname.split("/").pop();

  const [search, setSearch] = useState(searchParams.get("search") || "");

  const [reasonForChurn, setReasonForChurn] = useState(false);
  const [selectedHospital, setSelectedHospital] = useState<Hospital | null>(
    null
  );

  const [churnExit, setChurnExit] = useState("");
  const { mutatePartialChurnAction, isPending: isUpdating } =
    usePartialChurnAction();

  const { mutateUpdateReboard, isPending: isUpdatingReboard } =
    useUpdateReboard();

  const { mutateLogHistoryNote, isPending: isLoggingHistory } =
    useLogHistoryNote();

  const [logData, setLogData] = useState<LogData | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const userId = searchParams.get("user_id") || "";

  const getUserId = useCallback(
    (user: string) => {
      if (!privileges.createAgent) {
        return user;
      } else if (userId) {
        return userId;
      }
      return "";
    },
    [privileges.createAgent, userId]
  );

  const filters = useMemo(() => {
    return {
      limit: 10,
      userId: getUserId(user?.id as string),
      page: currentPage,
      name: search,
      churn_status: lastPathname,
      churn_exit_type: churnExit,
    };
  }, [getUserId, user?.id, currentPage, search, lastPathname, churnExit]);

  const { hospitals, paginate, isPending } = useGetAgentAdminHospitals(filters);

  const { agents } = useGetAllAgents(1, 1000, "", "", "0");

  const usernameFilter = Array.from(
    new Set(
      agents
        ?.filter((agent: { position: string }) =>
          ["sales_rep", "sales_lead"].includes(agent.position)
        )
        .map((agent: { id: string; username: string }) => {
          const nameParts = agent.username.replace(/([a-z])([A-Z])/g, "$1 $2");
          return {
            id: agent.id,
            name: nameParts,
          };
        })
    )
  ).sort((a, b) =>
    (a as { name: string }).name.localeCompare((b as { name: string }).name)
  );

  const handleAgentFilter = (agent: string) => {
    applyFilters(
      {
        page: 1,
        user_id: agent,
      },
      navigate,
      pathname
    );
  };

  const churnHospital = hospitals?.map((churn: ChurnHospital) => {
    const scoreTrack =
      typeof churn?.score_track === "string"
        ? JSON.parse(churn.score_track)
        : churn.score_track;

    const rewardTrack =
      typeof churn?.reward_track === "string"
        ? JSON.parse(churn.reward_track)
        : churn.reward_track;

    const ordered = scoreTrack.placedorder ? "Yes" : "No";
    const product = scoreTrack.productscount;
    const nerve_usage = scoreTrack.usenerve ? "Yes" : "No";
    const hot_complaint = scoreTrack.hascomplaint ? "Yes" : "No";
    const late_delivery = scoreTrack.latedelivery ? "Yes" : "No";
    const referrals = rewardTrack.referredothers ? "Yes" : "No";
    const reward = rewardTrack.onrewardprogramme ? "Yes" : "No";
    const pay_plan = rewardTrack.creditplan ? "Yes" : "No";
    const productList = scoreTrack.products_list;
    // const list =
    //   churn?.productlist !== ""
    //     ? churn?.productlist?.replace(",", ", ")
    //     : "N/A";

    const bosq =
      Array.isArray(productList) && productList.length > 0
        ? productList.map((product) => product).join(", ")
        : // list ??
          "N/A";

    const visit =
      churn.churn_visited === "1"
        ? "Yes"
        : churn.churn_visited === "1"
        ? "No"
        : churn.churn_visited;
    const resolveable =
      churn.reasonresolvable === "1"
        ? "Yes"
        : churn.reasonresolvable === "0"
        ? "No"
        : churn.reasonresolvable;
    const reason =
      churn.reason === "1" ? "Yes" : churn.reason === "0" ? "No" : churn.reason;

    const duration = churn.last_order_date
      ? `${utils.getDurationFromDate(churn.last_order_date)} Days`
      : "N/A";

    const previous_full_churn =
      churn.type === "confirmed-partial-churn" ? "Yes" : "No";
    const nerve_lite = nerve_usage;

    return {
      ...churn,
      ordered,
      product,
      nerve_usage,
      hot_complaint,
      late_delivery,
      referrals,
      reward,
      pay_plan,
      visit,
      resolveable,
      reason,
      duration,
      previous_full_churn,
      bosq,
      nerve_lite,
    };
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<ChurnReasonFormData>({
    resolver: zodResolver(churnReasonSchema),
  });

  const isReboardable = watch("reboardable");

  const onSubmit = async (data: ChurnReasonFormData) => {
    const date = utilities.formatDate(
      data.reboardable_date,
      "YYYY-MM-DD HH:mm:ss"
    );

    const payload = {
      hospital_id: selectedHospital.id as string,
      agent_id: user?.id as string,
      reason: data.reason,
      action: data.action,
      reboardable: data.reboardable === "Yes" ? 1 : 0,
      reboardable_date: date,
    };
    mutatePartialChurnAction(payload, {
      onSuccess: () => {
        handleCloseModal();
      },
    });
  };

  const handleCloseModal = () => {
    reset();
    setReasonForChurn(false);
    setSelectedHospital(null);
  };

  const handleChurnReason = (hospital: Hospital): void => {
    setSelectedHospital(hospital);
    setReasonForChurn(true);
  };
  const [progress, setProgress] = useState(0);

  const handleProgressChange = (progress: React.SetStateAction<number>) => {
    setProgress(progress);
  };

  const { mutate: mutateSendtoClose, isPending: isPitchInfo } =
    useSendToClose(handleProgressChange);

  const [openHistory, setOpenHistory] = useState({
    view: false,
    contact: false,
    visit: false,
    location: false,
    note: false,
    reason: false,
    isChurnRisk: false,
    isPitch: false,
    isClose: false,
    isReboarded: false,
    isScript: false,
  });

  const handleReboard = (hospital: Hospital): void => {
    setSelectedHospital(hospital);
    setOpenHistory((prev) => ({
      ...prev,
      view: true,
      isClose: true,
    }));
  };
  const handleChecklist = (): void => {
    setOpenHistory((prev) => ({
      ...prev,
      view: true,
      isReboarded: true,
    }));
  };

  const handleCall = () => {
    setOpenHistory((prev) => ({
      ...prev,
      note: true,
      visit: false,
      contact: false,
    }));
  };
  const [pitch, setPitch] = useState<{
    page: number;
    log: {
      summary: string;
      successful: string;
      agent_id: string;
      hos_id: string;
      name: string;
      address: string;
    } | null;

    onboard: string;
    appointment: string;
    time: string;
    date: string;
    summary: string;
    closingDate: Date;
    closingTime: string;
    isPending: boolean;
    logData: {
      location: string;
      address: string;
    };
  }>({
    page: 1,
    log: {
      summary: "",
      successful: "",
      agent_id: "",
      hos_id: "",
      name: "",
      address: "",
    },

    onboard: "",
    appointment: "",
    time: "",
    date: "",
    summary: "",
    closingDate: new Date(),
    closingTime: "",
    isPending: isPitchInfo,
    logData: {
      location: "",
      address: "",
    },
  });

  const handleLogLocation = () => {
    setOpenHistory((prev) => ({
      ...prev,
      location: true,
      contact: false,
    }));
  };

  const handleVisit = () => {
    setOpenHistory((prev) => ({
      ...prev,
      note: true,
      location: false,
    }));
  };

  const handleOpenHistory = (hospital: Hospital): void => {
    setSelectedHospital(hospital);
    setOpenHistory((prev) => ({
      ...prev,
      view: true,
    }));
  };

  const handleOpenChurnRiskActions = (hospital: Hospital): void => {
    setSelectedHospital(hospital);
    setOpenHistory((prev) => ({
      ...prev,
      view: true,
      isChurnRisk: true,
    }));
  };

  const handlePitch = (hospital: Hospital): void => {
    setSelectedHospital(hospital);
    setOpenHistory((prev) => ({
      ...prev,
      view: true,
      isPitch: true,
    }));
  };

  const handleOpenContact = () => {
    setOpenHistory((prev) => ({
      ...prev,
      contact: true,
    }));
  };

  const handleOpenReason = () => {
    setOpenHistory((prev) => ({
      ...prev,
      note: false,
      reason: true,
    }));
  };

  const handleCloseLocation = () => {
    setOpenHistory((prev) => ({
      ...prev,
      contact: true,
      location: false,
    }));
  };

  const {
    control: visitControl,
    handleSubmit: visitHandleSubmit,
    watch: visitWatch,
    reset: visitReset,
    formState: { errors: visitError },
  } = useForm<VisitFormData>({
    resolver: zodResolver(visitSchema),
  });

  const watchHasReason = visitWatch("hasReason");

  const historyNoteForm = useForm<HistoryNoteFormData>({
    resolver: zodResolver(historyNoteSchema),
  });

  const handleLogHistoryNote = (data: HistoryNoteFormData) => {
    let location = [];
    if (logData) {
      location.push(parseFloat(logData.location.split(",")[0]));
      location.push(parseFloat(logData.location.split(",")[1]));
    }

    const payload = {
      hospital_id: selectedHospital.id,
      agent_id: user?.id as string,
      type: logData ? "visit" : "call",
      visited: logData ? 1 : 0,
      location_geocode: location,
      note: data.note,
    };

    mutateLogHistoryNote(payload, {
      onSuccess: (res) => {
        setLogData(null);
        historyNoteForm.reset();
        if (openHistory.isChurnRisk || openHistory.isScript) {
          handleCloseHistory();
        } else {
          handleOpenReason();
        }
      },
    });
  };

  const handleCloseHistory = () => {
    setSelectedHospital(null);
    visitReset();
    setOpenHistory({
      view: false,
      contact: false,
      visit: false,
      location: false,
      note: false,
      reason: false,
      isChurnRisk: false,
      isPitch: false,
      isClose: false,
      isReboarded: false,
      isScript: false,
    });

    setPitch({
      page: 1,
      log: {
        summary: "",
        successful: "",
        agent_id: "",
        hos_id: "",
        name: "",
        address: "",
      },

      onboard: "",
      appointment: "",
      time: "",
      date: "",
      summary: "",
      closingDate: new Date(),
      closingTime: "",
      isPending: isPitchInfo,
      logData: {
        location: "",
        address: "",
      },
    });
  };

  const { mutateChurnVisit, isPending: isVisiting } = useChurnVisit();

  const onSubmitVisit = async (data: VisitFormData) => {
    const payload = {
      hospital_id: selectedHospital.id as string,
      agent_id: user?.id as string,
      reason: (data.reason as string) ?? "No Churn Reason",
      reasonresolvable: data.reasonresolvable === "Yes" ? 1 : 0,
    };

    mutateChurnVisit(payload, {
      onSuccess: () => {
        handleCloseHistory();
      },
    });
  };

  const noteFilters = {
    user_id: getUserId(user?.id as string),
    limit: 10000,
    page: currentPage,
    hospitalid: selectedHospital?.id,
  };

  const { feed, isPending: isPendingNote } = useGetFeed(
    noteFilters,
    selectedHospital?.id as string
  );

  const { mutate: readyToPitch, isPending: isPitching } = useReadyToPitch();

  const [clickedRowId, setClickedRowId] = useState<number | string | null>(
    null
  );

  const handleReadyToPitch = useCallback(
    (hospital_id: string | number | null) => {
      const payload = {
        hospital_id,
        user_id: user?.id as string,
        pitched: 0,
      };
      setClickedRowId(hospital_id);
      readyToPitch(payload);
    },
    [readyToPitch, user?.id]
  );

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "name":
        return (
          <>
            {hospital?.id ? (
              <Link
                className={cn(
                  "text-ohaRed-500 hover:text-ohaRed-600 active:text-ohaRed-700 underline font-bold",
                  lastPathname === "risk" &&
                    "text-ohaYellow-700 hover:text-ohaYellow-800 active:text-ohaYellow-900"
                )}
                to={`/hospitals/${hospital?.id}`}
              >
                {hospital?.name ?? "N/A"}
              </Link>
            ) : (
              <> {hospital?.name ?? "N/A"}</>
            )}
          </>
        );

      case "last_order_date":
        return (
          <div>
            {lastPathname === "full" ? (
              <Button
                onClick={() => handleOpenHistory(hospital)}
                variant={"outline"}
              >
                <DateCell
                  dateString={hospital[column.accessor]}
                  format="datetime-12"
                />
              </Button>
            ) : (
              <DateCell
                dateString={hospital[column.accessor]}
                format="datetime-12"
              />
            )}
          </div>
        );

      case "action":
        return (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <>
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={() => {
                      //   handleUpdateSelect(hospital);
                    }}
                  >
                    <Bell className="mr-2 h-4 w-4" /> Notify
                  </DropdownMenuItem>
                </>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );

      case "history":
        return (
          <div>
            <Button
              onClick={() => handleOpenHistory(hospital)}
              variant={"outline"}
            >
              View
            </Button>
          </div>
        );
      case "churn_risk_action":
        return (
          <div>
            <Button
              className="hover:shadow-md hover:shadow-green-200 p-2 rounded-md bg-ohaYellow-600 bg-gradient-to-b from-ohaYellow-600 to-ohaYellow-700 hover:from-ohaYellow-700 hover:to-ohaYellow-800 active:from-ohaYellow-800 active:to-ohaYellow-900 hover:text-ohaYellow-100"
              onClick={() => {
                handleOpenChurnRiskActions(hospital);
              }}
              variant={"outline"}
            >
              Take Action
            </Button>
          </div>
        );
      case "start":
        return (
          <div>
            {hospital.type !== "reboarded" ? (
              <Button
                className="capitalize"
                disabled={
                  hospital.id === clickedRowId &&
                  (isPitching || isUpdatingReboard)
                }
                onClick={() => {
                  const step = !hospital.type
                    ? "prospect"
                    : hospital.type === "prospect"
                    ? "re-pitch"
                    : hospital.type === "re-pitch"
                    ? "close"
                    : hospital.type === "close"
                    ? "reboarded"
                    : "";
                  if (step === "close") {
                    handlePitch(hospital);
                  } else if (step === "reboarded") {
                    handleReboard(hospital);
                  } else {
                    handleUpdateReboardSteps(step, hospital);
                  }
                }}
              >
                {hospital.id === clickedRowId &&
                (isPitching || isUpdatingReboard) ? (
                  <div className="text-center text-white text-lg flex items-center justify-center space-x-2">
                    <Loader className="animate-spin h-6 w-6 text-white" />{" "}
                    {/* <span>Loading...</span> */}
                  </div>
                ) : !hospital.type ? (
                  "Start"
                ) : hospital.type === "prospect" ? (
                  "Send To Re-Pitch"
                ) : hospital.type === "re-pitch" ? (
                  "Send To Close"
                ) : hospital.type === "close" ? (
                  "Send To Re-board"
                ) : (
                  ""
                )}
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => {
                    handleChecklist();
                    window.sessionStorage.setItem(
                      "checklist",
                      JSON.stringify(hospital)
                    );
                    // navigate(`/checklist/${hospital.hospital_id}`);
                  }}
                >
                  Check List
                </Button>
                {/* hospital?.type */}
              </>
            )}
          </div>
        );
      case "partial_churn_action":
        return (
          <div>
            {hospital.type !== "confirmed-partial-churn" ? (
              <Button
                variant={"outline"}
                onClick={() => handleChurnReason(hospital)}
              >
                Post Reason
              </Button>
            ) : (
              <Button
                variant={"outline"}
                onClick={() => handleChurnReason(hospital)}
              >
                Send to Admin
              </Button>
            )}
          </div>
        );
      default:
        return <span>{hospital[column.accessor] ?? "N/A"}</span>;
    }
  }

  let columns: ProductTableColumn[] = [
    { header: "Hospital Name", accessor: "name", className: "max-w-44 w-44" },
    { header: "Address", accessor: "address", className: "max-w-32 w-32" },
    {
      header: "Last Known Order",
      accessor: "last_order_date",
      className: "max-w-32 w-32",
    },
    {
      header: "No of Product",
      accessor: "product",
      className: "max-w-32 w-32",
    },
    {
      header: "Lite Orders",
      accessor: "lite_order",
      className: "max-w-32 w-32",
    },

    {
      header: "Nerve Accounts",
      accessor: "nerve_usage",
      className: "max-w-32 w-32",
    },
    {
      header: "Last Known Health Score",
      accessor: "health_score",
      className: "max-w-32 w-32",
    },
    {
      header: "Complaints",
      accessor: "hot_complaint",
      className: "max-w-32 w-32",
    },
  ];

  //   if (privileges.createAgent) {
  //     columns.push({
  //       header: "Action",
  //       accessor: "action",
  //       className: "max-w-32 w-32",
  //     });
  //   }
  //   if (lastPathname === "exit") {
  //     const columnsToRemove = ["history", "start"];
  //     columns = columns.filter((col) => !columnsToRemove.includes(col.accessor));
  //   }

  if (lastPathname === "in-exit") {
    addColumn(
      columns,
      {
        header: "Full Hospital History",
        accessor: "history",
        className: "max-w-32 w-32",
      },
      4
    );
    addColumnToEnd(columns, {
      header: "Start",
      accessor: "start",
      className: "max-w-32 w-32",
    });
    addColumn(
      columns,
      {
        header: "Lite/Nerve",
        accessor: "nerve_lite",
        className: "max-w-32 w-32",
      },
      6
    );

    removeColumn(columns, "productlist");
    removeColumn(columns, "nerve_accounts");
    removeColumn(columns, "health_score");
    removeColumn(columns, "lite_order");
  } else if (lastPathname === "full") {
    addColumn(
      columns,
      {
        header: "Visit",
        accessor: "visit",
        className: "max-w-32 w-32",
      },
      6
    );
    addColumn(
      columns,
      {
        header: "Reason given",
        accessor: "reason",
        className: "max-w-32 w-32",
      },
      7
    );
    addColumn(
      columns,
      {
        header: "Reason solvable",
        accessor: "resolveable",
        className: "max-w-32 w-32",
      },
      8
    );
    addColumn(
      columns,
      {
        header: "Partial Churn",
        accessor: "partial_churn",
        className: "max-w-32 w-32",
      },
      9
    );
    addColumnToEnd(columns, {
      header: "Reserve List",
      accessor: "type",
      className: "max-w-32 w-32",
    });

    removeColumn(columns, "nerve_usage");
    removeColumn(columns, "health_score");
    removeColumn(columns, "hot_complaint");
  } else if (lastPathname === "partial") {
    addColumn(
      columns,
      {
        header: "Lite/Nerve",
        accessor: "nerve_lite",
        className: "max-w-32 w-32",
      },
      4
    );
    addColumn(
      columns,
      {
        header: "B/O/S/Q",
        accessor: "bosq",
        className: "max-w-32 w-32",
      },
      5
    );
    addColumn(
      columns,
      {
        header: "Previous Full Churn",
        accessor: "previous_full_churn",
        className: "max-w-32 w-32",
      },
      6
    );
    addColumn(
      columns,
      {
        header: "Reason for Churn",
        accessor: "reason",
        className: "max-w-32 w-32",
      },
      7
    );
    addColumn(
      columns,
      {
        header: "Action Step",
        accessor: "churn_note",
        className: "max-w-32 w-32",
      },
      8
    );
    addColumnToEnd(columns, {
      header: "Achieved Partial Churn",
      accessor: "partial_churn_action",
      className: "max-w-32 w-32",
    });
    removeColumn(columns, "product");
    removeColumn(columns, "lite_order");
    removeColumn(columns, "nerve_usage");
    removeColumn(columns, "health_score");
    removeColumn(columns, "hot_complaint");
  } else {
    removeColumn(columns, "last_order_date");
    removeColumn(columns, "lite_order");
    removeColumn(columns, "nerve_usage");
    removeColumn(columns, "health_score");
    removeColumn(columns, "product");
    removeColumn(columns, "order");
    columns.unshift({
      header: "Count Down",
      accessor: "duration",
      className: "max-w-32 w-32",
    });

    addColumn(
      columns,
      {
        header: "Health Score",
        accessor: "health_score",
        className: "max-w-32 w-32",
      },
      3
    );
    addColumn(
      columns,
      {
        header: "Lite/Nerve",
        accessor: "nerve_lite",
        className: "max-w-32 w-32",
      },
      4
    );
    addColumn(
      columns,
      {
        header: "B/O/S/Q",
        accessor: "bosq",
        className: "max-w-32 w-32",
      },
      5
    );
    addColumn(
      columns,
      {
        header: "Late Delivery",
        accessor: "late_delivery",
        className: "max-w-32 w-32",
      },
      7
    );
    addColumn(
      columns,
      {
        header: "Referral Program",
        accessor: "referrals",
        className: "max-w-32 w-32",
      },
      8
    );
    addColumn(
      columns,
      {
        header: "Reward Program",
        accessor: "reward",
        className: "max-w-32 w-32",
      },
      9
    );
    addColumn(
      columns,
      {
        header: "Request Credit",
        accessor: "pay_plan",
        className: "max-w-32 w-32",
      },
      10
    );
    addColumnToEnd(columns, {
      header: "Action",
      accessor: "churn_risk_action",
      className: "max-w-32 w-32",
    });
  }

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);

  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search]
  );

  const handleSearch = (e: any) => {
    setSearch(e);
    applyFilters(
      {
        page: 1,
        search: e,
      },
      navigate,
      pathname
    );
  };

  const [activeCategory, setActiveCategory] = useState<{
    title: string;
    tag: string;
    color: string;
    sortKey: string;
    data: string;
    icon: string;
  } | null>(null);

  const countFilters = {
    user_id: getUserId(user?.id as string),
  };

  const { count: fullChurnCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "full",
  });

  const { count: partialChurnCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "partial",
  });

  const { count: achievedPartialChurnCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "partial",
    churn_exit_type: "archived-partial-churn",
  });

  const handlePartialChurnFilter = (churn_exit_type: string) => {
    setChurnExit(churn_exit_type);
  };

  const { count: confirmedPartialChurnCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "partial",
    churn_exit_type: "confirmed-partial-churn",
  });

  const { count: prospectCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "in-exit",
    churn_exit_type: "prospect",
  });

  const { count: repitchCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "in-exit",
    churn_exit_type: "re-pitch",
  });

  const { count: closeCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "in-exit",
    churn_exit_type: "close",
  });

  const { count: reboardedCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "in-exit",
    churn_exit_type: "reboarded",
  });

  const { count: newExodusCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "in-exit",
    churn_exit_type: "new-exodus",
  });

  const { count: activeCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "in-exit",
    churn_exit_type: "active",
  });

  const handlePipelineItemClick = (pipelineItem: {
    title: string;
    tag: string;
    color: string;
    sortKey: string;
    data: string;
    icon: string;
  }) => {
    // console.log(pipelineItem);
    if (pipelineItem.tag === churnExit) {
      setChurnExit("");
    } else setChurnExit(pipelineItem.tag);
  };

  const pipelineItems = [
    {
      title: "Prospect",
      tag: "prospect",
      color: "#424200",
      icon: "hospital-o",
      sortKey: "created_at",
      data: prospectCount ?? 0,
    },
    {
      title: "Re-pitch",
      tag: "re-pitch",
      color: "#441AB7",
      icon: "newspaper-o",
      sortKey: "pitch_date",
      data: repitchCount ?? 0,
    },
    {
      title: "Close",
      tag: "close",
      color: "#b71C1C",
      icon: "plus-circle",
      sortKey: "prospect_date",
      data: closeCount ?? 0,
    },
    {
      title: "Re-boarded",
      tag: "reboarded",
      color: "#424200",
      icon: "book",
      sortKey: "onboard_date",
      data: reboardedCount ?? 0,
    },

    {
      title: "New Exodus",
      tag: "new-exodus",
      color: "#09A5C5",
      icon: "ticket",
      sortKey: "stalled_date",
      data: newExodusCount ?? 0,
    },
    {
      title: "Active",
      tag: "active",
      color: "#114B29",
      icon: "check-circle",
      sortKey: "active_date",
      data: activeCount ?? 0,
    },
  ];

  const handleUpdateReboardSteps = useCallback(
    (step: string, hospital: Hospital) => {
      const payload = {
        id: hospital.id,
        step: step,
      };

      setClickedRowId(hospital.id);

      if (step === "re-pitch") {
        const payload = {
          hospital_id: hospital.id,
          user_id: user?.id as string,
          pitched: 0,
        };
        readyToPitch(payload);
      }
      mutateUpdateReboard(payload);
      // if (
      //   !hospital.type
      //     ? "Start"
      //     : hospital.type === "prospect"
      //     ? "Re-Pitch"
      //     : hospital.type === "re-pitch"
      //     ? "Close"
      //     : hospital.type === "close"
      //     ? "Re-board"
      //     : ""
      // ) {
      // }
      //
    },
    [mutateUpdateReboard, readyToPitch, user?.id]
  );

  const { count: preFullChurnCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "churn",
  });

  const { count: riskChurnCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "risk",
  });

  const [type, setType] = useState("oxygen");

  const OrderHistoryFilters = {
    user_id: getUserId(user?.id as string),
    status: selectedHospital?.status,
    order_by: selectedHospital?.ref_id,
    type: type,
    limit: 5,
  };

  const { allExodus } = useGetAllExodus(
    { ...OrderHistoryFilters },
    "churn-hospital"
  );

  const recentActivities = [
    {
      label: "Oxygen",
      value: "oxygen",
      title: "Oxygen Activities",
      color: "#5286c3",
      items: allExodus
        ? allExodus
            .map(
              (item: {
                id: string;
                order_by: string;
                payment: string;
                qty: string;
                tym: string;
                urgency: string;
                product: string;
                unitprice: string;
                paymentdetails: string;
                schedule_date: string;
                schedule_time: string;
                order_state: string;
                complete_tym: string;
                created_at: string;
                hospital_name: string;
                cylinder: string;
              }) => {
                const formatDate = (
                  dateString: string | number
                ): JSX.Element => {
                  return (
                    <DateCell dateString={dateString} format="datetime-12" />
                  );
                };
                return {
                  id: item.id,
                  order_by: item.order_by,
                  payment: item.payment,
                  qty: item.qty,
                  tym: item.tym,
                  urgency: item.urgency,
                  product: item.product,
                  unitprice: item.unitprice,
                  paymentdetails: item.paymentdetails,
                  schedule_date: item.schedule_date,
                  schedule_time: item.schedule_time,
                  order_state: item.order_state,
                  complete_tym: item.complete_tym,
                  created_at: formatDate(item.tym),
                  hospital_name: item.hospital_name,
                  cylinder: item.cylinder,
                };
              }
            )
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },

    {
      label: "Blood",
      value: "blood",
      title: "Blood Activities",
      color: "#b70303",
      items: allExodus
        ? allExodus
            .map((item: { [x: string | number]: string | number }) => {
              const formatDate = (dateString: string | number): JSX.Element => {
                return (
                  <DateCell dateString={dateString} format="datetime-12" />
                );
              };

              return {
                id: item.order_id,
                cylinder: item.b_type,
                urgency: item.Request_Type,
                payment: item.payment_type,
                order_by: item.order_by,
                qty: item.qty,
                tym: item.tym,
                product: item.product,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: formatDate(item.tym),
                hospital_name: item.hospital_name,
              };
            })
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
    {
      label: "Quip",
      value: "quip",
      title: "Quip Activities",
      color: "#F16C03",
      items: allExodus
        ? allExodus
            .map(
              (item: {
                id: string;
                order_by: string;
                payment: string;
                qty: string;
                tym: string;
                urgency: string;
                product: string;
                unitprice: string;
                paymentdetails: string;
                schedule_date: string;
                schedule_time: string;
                order_state: string;
                complete_tym: string;
                created_at: string;
                hospital_name: string;
                b_type: string;
              }) => ({
                id: item.id,
                order_by: item.order_by,
                payment: item.payment,
                qty: item.qty,
                tym: item.tym,
                urgency: item.urgency,
                product: item.product,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: item.created_at,
                hospital_name: item.hospital_name,
                cylinder: item.b_type,
              })
            )
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
    {
      label: "StockBank",
      value: "stockbank",
      title: "StockBank Activities",
      color: "#215200",
      items: allExodus
        ? allExodus

            .map((item: { [x: string | number]: string | number }) => {
              const formatDate = (dateString: string | number): JSX.Element => {
                return (
                  <DateCell dateString={dateString} format="datetime-12" />
                );
              };
              return {
                id: item.order_id,
                cylinder: item.b_type,
                urgency: item.Request_Type,
                payment: item.payment,
                order_by: item.order_by,
                qty: item.qty,
                tym: formatDate(item.tym),
                product: item.product,
                unitprice: item.unitprice,
                paymentdetails: item.paymentdetails,
                schedule_date: item.schedule_date,
                schedule_time: item.schedule_time,
                order_state: item.order_state,
                complete_tym: item.complete_tym,
                created_at: formatDate(item.tym),
                hospital_name: item.hospital_name,
              };
            })
            .filter((item: { id: null }) => item.id !== null)
        : [],
    },
  ];

  const { mutate, isPending: isClosing } = useCloseAppointment();
  const { mutateReserve, isPending: isReserving } = useReserveAppointment();
  const { mutateSchedule } = useScheduleAppointment();

  const [createExpense, setCreateExpense] = useState(false);

  const handleLocationData = (location: LogData) => {
    setLogData(location);
  };

  const pitchForm = useForm<PitchFormData>({
    resolver: zodResolver(pitchFormSchema),
    defaultValues: {
      summary: "",
      grade: "1",
      key: "Yes",
    },
  });

  const { watch: pitchWatch } = pitchForm;

  const summary = pitchWatch("summary");

  const objectionType = pitchWatch("objectionType");
  const offerType = pitchWatch("offer_status");

  const isReserved = offerType === "No" || objectionType === "No";

  const onSubmitPitch = (data: PitchFormData) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append(key, value);
      } else if (value !== undefined && value !== null && value !== "") {
        formData.append(key, value.toString());
      }
    });

    if (pitchDetails.id) {
      formData.append("id", pitchDetails.id);
    }

    if (logData?.location) {
      formData.append("location", logData.location as string);
    }

    if (isReserved) {
      const payload = {
        agent_id: pitchDetails?.user_id,
        request_by: user?.id,
        hospital_id: pitchDetails?.hospital_id,
      };
      mutateReserve(payload, {
        onSuccess: () => {
          const reboardedPayload = {
            id: selectedHospital?.id,
            step: "reboarded",
          };
          mutateUpdateReboard(reboardedPayload);
          handleCloseHistory();
          pitchForm.reset();
          setCreateExpense(true);
        },
      });
    } else {
      mutate(formData, {
        onSuccess: () => {
          const scheduleData = {
            hospital_id: pitchDetails?.hospital_id,
            orders: "",
            when: "",
            frequency: "",
            amount: "",
          };
          const reboardedPayload = {
            id: selectedHospital?.id,
            step: "reboarded",
          };
          mutateUpdateReboard(reboardedPayload);
          handleCloseHistory();
          mutateSchedule(scheduleData);
          pitchForm.reset();
          setCreateExpense(true);
        },
      });
    }
  };

  const { pitchInformation } = useGetPitchInformation(
    selectedHospital?.id as string
  );

  const { pitchDetails, isPending: isPitchingInfo } = useGetPitchDetails(
    selectedHospital?.id as string
  );

  const pitchInfo = { ...pitchDetails, name: selectedHospital?.name };

  const pitchSingleInfo = {
    ...pitchInformation[0],
    hos_id: selectedHospital?.id,
  };

  const handleSendToClose = async () => {
    const payload = {
      summary: pitch.summary,
      successful: pitch.onboard,
      agent_id: user?.id,
      hos_id: selectedHospital?.id,
      location: pitch.logData.location,
      date: pitch.closingDate
        ? dayjs(pitch.closingDate).format("YYYY-MM-DD")
        : null,
      time: pitch.closingTime,
    };
    // setCreateExpense(true);
    mutateSendtoClose(payload, {
      onSuccess: (data: any) => {
        success(data?.data?.message, "success");
        const closePayload = {
          id: selectedHospital?.id,
          step: "close",
        };
        mutateUpdateReboard(closePayload);
        setCreateExpense(true);
        handleCloseHistory();
      },
      onError: (e: any) => {
        console.log(e);
        error(
          e?.response?.data?.message ||
            e?.message ||
            "Could not process this information!",
          "error"
        );
      },
    });
  };

  const [productType, setProductType] = useState("");

  const renderScript = () => {
    switch (productType) {
      case "nerve_lite":
        return (
          <>
            <h2 className="text-xl font-semibold">Nerve script </h2>

            <p>Have you placed an order?</p>
            <p>Is there a reason why you have not placed an order?</p>
            <p>Are you having any issues with Nerve?</p>
            <p>Do you have any issues with our Products or Service?</p>
            <p>Is there anything else I can help you with?</p>
          </>
        );

      case "bosq":
        return (
          <>
            <h2 className="text-xl font-semibold">
              Blood/Oxygen/Stockbank/Quip script
            </h2>
            <p>Have you placed an order?</p>
            <p>Is there a reason why you have not placed an order?</p>
            <p>Do you have any issues with our Products or Service?</p>
            <p>Is there anything else I can help you with?</p>
          </>
        );
      case "hot_complaint":
        return (
          <>
            <h2 className="text-xl font-semibold">Complaint script</h2>
            <p>Please what is your complaint?</p>
            <p>Do you have any issues with our Products or Service?</p>
            <p>Are you having any issues with Nerve?</p>
            <p>
              How will you like me to assist you with solving your complaint?
            </p>
            <p>Is there anything else I can help you with?</p>
          </>
        );
      case "late_delivery":
        return (
          <>
            <h2 className="text-xl font-semibold">Late Delivery script</h2>
            <p>Did you have a late delivery?</p>
            <p>How did this late delivery affect your business operations?</p>
            <p>
              Kindly let me know how I can assist you with managing the impact
              of our late delivery
            </p>
            <p>Is there anything else I can help you with?</p>
          </>
        );
      case "referrals":
        return (
          <>
            <h2 className="text-xl font-semibold">Referal script script</h2>

            <p>Have you placed an order?</p>
            <p>Is there a reason why you have not placed an order?</p>
            <p>Are you having any issues with Nerve?</p>
            <p>
              How will you like me to assist you with getting more refferals?
            </p>
            <p>Is there anything else I can help you with?</p>
          </>
        );
      case "reward":
        return (
          <>
            <h2 className="text-xl font-semibold">Reward script</h2>

            <p>Do you have any issues with our Products or Service?</p>
            <p>Are you having any issues with Nerve?</p>
            <p>How will you like me to assist you with getting more reward?</p>
            <p>Is there anything else I can help you with?</p>
          </>
        );
      case "pay_plan":
        return (
          <>
            <h2 className="text-xl font-semibold">Pay plan script</h2>

            <p>Do you have any issues with our Products or Service?</p>
            <p>Are you having any issues with Nerve?</p>
            <p>How will you like me to assist you with your credit plan?</p>
            <p>Is there anything else I can help you with?</p>
          </>
        );

      default:
        return null;
    }
  };

  const handleScript = (product: string) => {
    setProductType(product);
    setOpenHistory((prev) => ({
      ...prev,
      isChurnRisk: false,
      isScript: true,
    }));
  };

  return (
    <React.Fragment>
      <Header title={`${lastPathname} Churn`} />

      {isPending ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isPending}
            className="relative"
            size={30}
          />
        </div>
      ) : (
        <>
          <div className="hospitals-container">
            <div className="hospitals-container-top">
              <SearchQuery
                name="search"
                placeholder="Search"
                value={search}
                onChange={(e: any) => handleSearch(e.currentTarget.value)}
              />

              <div className="right-options gap-5">
                {privileges.createAgent && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="outline"
                        className="w-full md:w-44 flex justify-between capitalize text-blue-800 text-sm bg-white"
                      >
                        {userId
                          ? (
                              usernameFilter?.find((user) => {
                                return (user as AgentFilter).id === userId;
                              }) as AgentFilter
                            )?.name || "Filter by Agent"
                          : "Filter by Agent"}
                        <ChevronDown className="ml-2 h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="max-h-44 overflow-y-auto">
                      <DropdownMenuItem onSelect={() => handleAgentFilter("")}>
                        All
                      </DropdownMenuItem>
                      {usernameFilter?.map((agent, index) => (
                        <DropdownMenuItem
                          key={index + 1}
                          onSelect={() =>
                            handleAgentFilter((agent as AgentFilter).id)
                          }
                          className="capitalize"
                        >
                          {(agent as AgentFilter).name}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </div>
            </div>

            <p className="my-3 text-xl font-semibold">
              {lastPathname === "in-exit"
                ? "The hospital in Exit have not ordered in 2 years, and we don't know why. You need to re-board them and follow the protocol below:"
                : lastPathname === "full"
                ? `  You have ${
                    fullChurnCount ?? 0
                  } hospital suspected as full Churn. The first priority is to confirm full churn for all hospitals or move them to partial churn`
                : lastPathname === "partial"
                ? `  You have ${confirmedPartialChurnCount} hospital confirmed as partial Churn. ${partialChurnCount} have real time partial churn status. Start working on them right now`
                : `${riskChurnCount} hospitals are churn risk. This means they have not ordered between a quarter and a year (3-11 months). It is your job to find out why and take action to get them back to Active`}
            </p>
            {lastPathname === "in-exit" && (
              <ChurnPipeline
                activeCategory={activeCategory}
                onActiveChange={(category) => setActiveCategory(category)}
                items={pipelineItems}
                onClick={handlePipelineItemClick}
              />
            )}

            {lastPathname === "partial" && (
              <div className="max-w-md mx-auto mb-4">
                <div className="w-full grid grid-cols-2  gap-3">
                  <div
                    role="button"
                    className={cn(
                      "border hover:shadow-md hover:shadow-blue-200 p-2 rounded-md",
                      churnExit === "confirmed-partial-churn" && "bg-blue-200"
                    )}
                    onClick={() =>
                      handlePartialChurnFilter("confirmed-partial-churn")
                    }
                  >
                    <div className="w-full flex flex-col items-center text-center">
                      <h2 className="font-semibold">Confirmed Partial Churn</h2>
                      <p>(Was full churn but we have a resolvable reason)</p>
                    </div>
                  </div>
                  <div
                    role="button"
                    className={cn(
                      "border hover:shadow-md hover:shadow-orange-200 p-2 rounded-md"
                    )}
                    onClick={
                      () => handlePartialChurnFilter("")
                      //("archived-partial-churn")
                    }
                  >
                    <div className="w-full flex flex-col items-center text-center">
                      <h2 className="font-semibold">Achieved Partial Churn</h2>
                      <p>No order in a year and need a reason</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="hospitals-container-bottom table-responsive mb-5">
              <>
                <ProductTable
                  data={churnHospital ?? []}
                  columns={columns}
                  renderCell={renderCell}
                  totalPages={paginate?.totalPages}
                  currentPage={currentPage}
                  goToPage={goToPage}
                  noDataMessage={"You Currently don't have any churn hospitals"}
                />
              </>
            </div>
            <p>
              {lastPathname === "full" && (
                <>
                  You have{" "}
                  <span className="font-semibold">
                    {" "}
                    {preFullChurnCount ?? 0}
                  </span>{" "}
                  hospital in pre-full Churn.{" "}
                  <span className="font-semibold"> {fullChurnCount ?? 0}</span>{" "}
                  hospitals have been confirmed full churn and{" "}
                  <span className="font-semibold">
                    {" "}
                    {partialChurnCount ?? 0}
                  </span>{" "}
                  hospitals have been moved to partial churn.
                </>
              )}
            </p>
          </div>
        </>
      )}

      <Dialog.Root open={reasonForChurn} onOpenChange={handleCloseModal}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-xl mx-auto px-1"
          >
            <div className="bg-white rounded-md shadow-lg py-3">
              <div className="flex items-center justify-between px-4">
                <Dialog.Title className="text-lg font-medium text-gray-800 flex items-center flex-wrap">
                  <span className="text-nowrap font-semibold mr-2">
                    Reason For Churn
                  </span>
                </Dialog.Title>
                <Dialog.Close
                  className="p-2 text-black rounded-md hover:bg-gray-100"
                  onClick={() => {}}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <Separator className="my-2 bg-black" />
              <div className="mx-auto space-y-3 text-start ">
                <p className="px-4">
                  {selectedHospital?.name} last placed an order a year ago.
                  Investigate and post a reason plus action step
                </p>{" "}
                <div className="flex items-center justify-center p-4 w-full">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="space-y-4 w-full"
                  >
                    <div className="flex gap-3 justify-between w-full">
                      <Label className="font-semibold mb-2 w-1/4">
                        Reason for Churn:
                      </Label>{" "}
                      <div className="w-3/4">
                        <Controller
                          name="reason"
                          control={control}
                          render={({ field }) => (
                            <textarea
                              {...field}
                              placeholder="Post Reason..."
                              className={`w-full p-1 border ${
                                errors.reason
                                  ? "!border-red-500"
                                  : "border-gray-300"
                              } rounded-md focus:ring-2 focus:ring-indigo-500`}
                            />
                          )}
                        />{" "}
                        {errors.reason && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.reason.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex gap-3 justify-between w-full">
                      <Label className="font-semibold mb-2 w-1/4">
                        Action Step:
                      </Label>{" "}
                      <div className="w-3/4">
                        <Controller
                          name="action"
                          control={control}
                          render={({ field }) => (
                            <textarea
                              {...field}
                              placeholder="Post Action Step..."
                              className={`w-full p-1 border ${
                                errors.action
                                  ? "!border-red-500"
                                  : "border-gray-300"
                              } rounded-md focus:ring-2 focus:ring-indigo-500`}
                            />
                          )}
                        />{" "}
                        {errors.action && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.action.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex gap-3 justify-between items-center w-full">
                      <Label htmlFor="reboardable" className="font-bold w-1/4">
                        Can be Re-boarded?
                      </Label>
                      <div className="w-3/4">
                        <Controller
                          name="reboardable"
                          control={control}
                          render={({ field }) => (
                            <Select
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                            >
                              <SelectTrigger className="w-full">
                                <SelectValue placeholder="Can The Hospital be Re-boarded?" />
                              </SelectTrigger>
                              <SelectContent>
                                {["Yes", "No"].map((option) => (
                                  <SelectItem key={option} value={option}>
                                    {option}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          )}
                        />{" "}
                        {errors.reboardable && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.reboardable.message}
                          </p>
                        )}
                      </div>
                    </div>
                    {isReboardable === "Yes" && (
                      <div className="flex gap-3 justify-between items-center w-full">
                        <Label className="font-semibold mb-2 w-1/4">
                          Anticipated Re-boarding Date:
                        </Label>{" "}
                        <div className="w-3/4">
                          <Controller
                            name="reboardable_date"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="date"
                                {...field}
                                value={
                                  field.value
                                    ? field.value.toISOString().split("T")[0]
                                    : ""
                                }
                                onChange={(e) =>
                                  field.onChange(new Date(e.target.value))
                                }
                                placeholder="Select a date"
                                className="border rounded-md p-2"
                              />
                            )}
                          />
                          {errors.reboardable_date && (
                            <p className="text-red-500 text-sm mt-1">
                              {errors.reboardable_date.message}
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                    <Button
                      disabled={isUpdating}
                      type="submit"
                      className="w-full p-3 bg-blue-600 text-white rounded-md shadow-md transition-all"
                    >
                      {isUpdating ? "Sending, Please wait..." : "Send to Admin"}
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <ActivityHistory
        pointHistory={openHistory.view}
        openPointHistory={handleCloseHistory}
        title={`${
          openHistory.contact || openHistory.note
            ? "Log a contact"
            : "Hospital Activity"
        }`}
      >
        <div className="flex flex-col w-full gap-3 overflow-y-auto max-h-96">
          {openHistory.contact ? (
            <>
              <div className="w-9/12 flex justify-between mx-auto gap-4 pt-4">
                <div
                  className="shadow-white shadow-sm rounded w-5/12 flex flex-col items-center h-[200px] justify-around cursor-pointer bg-[#4D26A0] text-[white]"
                  onClick={handleCall}
                >
                  <span className="text-[#4D26A0]">
                    <AiOutlineContacts size={70} className="text-white" />
                  </span>
                  <p className="text-2xl">Log a call</p>
                </div>
                <div
                  className="shadow-white shadow-sm rounded w-5/12 flex flex-col items-center h-[200px] justify-around cursor-pointer bg-[#4D26A0] text-[white]"
                  onClick={handleLogLocation}
                >
                  <span className="text-[#4D26A0]">
                    <MdOutlineHome size={70} className="text-[#fff]" />
                  </span>
                  <p className="text-2xl text-[#fff]">Log a visit</p>
                </div>
              </div>
            </>
          ) : openHistory.location ? (
            <LogVisit
              handleClose={handleCloseLocation}
              changePage={handleVisit}
              setLogData={setLogData}
            />
          ) : openHistory.note ? (
            <VisitOrCallComponent
              form={historyNoteForm}
              submit={handleLogHistoryNote}
            />
          ) : openHistory.reason ? (
            <>
              <div className="flex items-center justify-center p-4 w-full">
                <form
                  onSubmit={visitHandleSubmit(onSubmitVisit)}
                  className="space-y-4 w-full"
                >
                  <div className="flex gap-3 justify-between items-center w-full">
                    <Label htmlFor="hasReason" className="font-bold w-1/4">
                      Do you have a churn reason?
                    </Label>
                    <div className="w-3/4">
                      <Controller
                        name="hasReason"
                        control={visitControl}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Do you have a churn reason?" />
                            </SelectTrigger>
                            <SelectContent>
                              {["Yes", "No"].map((option) => (
                                <SelectItem key={option} value={option}>
                                  {option}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />{" "}
                      {visitError.hasReason && (
                        <p className="text-red-500 text-sm mt-1">
                          {visitError.hasReason.message}
                        </p>
                      )}
                    </div>
                  </div>
                  {watchHasReason === "Yes" && (
                    <>
                      <div className="flex gap-3 justify-between w-full">
                        <Label className="font-semibold mb-2 w-1/4">
                          Reason for Churn:
                        </Label>{" "}
                        <div className="w-3/4">
                          <Controller
                            name="reason"
                            control={visitControl}
                            render={({ field }) => (
                              <textarea
                                {...field}
                                placeholder="Post Reason..."
                                className={`w-full p-1 border ${
                                  visitError.reason
                                    ? "!border-red-500"
                                    : "border-gray-300"
                                } rounded-md focus:ring-2 focus:ring-indigo-500`}
                              />
                            )}
                          />{" "}
                          {visitError.reason && (
                            <p className="text-red-500 text-sm mt-1">
                              {visitError.reason.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex gap-3 justify-between items-center w-full">
                        <Label
                          htmlFor="reasonresolvable"
                          className="font-bold w-1/4"
                        >
                          Can this reason be solved?
                        </Label>
                        <div className="w-3/4">
                          <Controller
                            name="reasonresolvable"
                            control={visitControl}
                            render={({ field }) => (
                              <Select
                                onValueChange={field.onChange}
                                defaultValue={field.value}
                              >
                                <SelectTrigger className="w-full">
                                  <SelectValue placeholder="Can this reason be solved?" />
                                </SelectTrigger>
                                <SelectContent>
                                  {["Yes", "No"].map((option) => (
                                    <SelectItem key={option} value={option}>
                                      {option}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            )}
                          />{" "}
                          {visitError.reasonresolvable && (
                            <p className="text-red-500 text-sm mt-1">
                              {visitError.reasonresolvable.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <Button
                    disabled={isVisiting}
                    type="submit"
                    className="w-full p-3 bg-blue-600 text-white rounded-md shadow-md transition-all"
                  >
                    {isVisiting ? "Submitting..." : "Submit"}
                  </Button>
                </form>
              </div>
            </>
          ) : openHistory.isChurnRisk ? (
            <>
              <div>
                <h2>
                  These are actions you can take to improve{" "}
                  <span className="font-semibold">{selectedHospital.name}</span>{" "}
                  health score and ultimately moving them from churn risk.
                </h2>

                <div className="space-y-">
                  {selectedHospital && (
                    <>
                      {[
                        "nerve_lite",
                        "bosq",
                        "hot_complaint",
                        "late_delivery",
                        "referrals",
                        "reward",
                        "pay_plan",
                      ].map((key) => (
                        <div
                          key={key}
                          className="flex justify-between items-center border-b border-[#474747] p-1"
                        >
                          <p className="text-sm font-semibold capitalize w-1/3 text-atomGray text-nowrap p-1 text-start">
                            {key === "hmo"
                              ? "HMO Name"
                              : key === "offer"
                              ? "Offer Amount"
                              : key === "amount"
                              ? "Claim"
                              : key === "name"
                              ? "HMO Name"
                              : key === "note"
                              ? "Notes from Lifebank"
                              : key.replace("_", " ")}
                            :
                          </p>
                          <p
                            className={cn(
                              "text-sm w-2/3 text-atomGray font-light text-start flex items-center p-1 gap-10",
                              key === "note" &&
                                "border border-dashed rounded-md bg-atomGraySecondary/25"
                            )}
                          >
                            {String(
                              selectedHospital[
                                key as keyof typeof selectedHospital
                              ]
                            )}

                            {/* Conditional Button Rendering */}
                            {!(
                              // Hide the button if any of the conditions are true
                              (
                                (key === "hot_complaint" &&
                                  String(
                                    selectedHospital[
                                      key as keyof typeof selectedHospital
                                    ]
                                  ) === "No") ||
                                (key === "late_delivery" &&
                                  String(
                                    selectedHospital[
                                      key as keyof typeof selectedHospital
                                    ]
                                  ) === "No") ||
                                (key === "referrals" &&
                                  String(
                                    selectedHospital[
                                      key as keyof typeof selectedHospital
                                    ]
                                  ) === "Yes") ||
                                (key === "reward" &&
                                  String(
                                    selectedHospital[
                                      key as keyof typeof selectedHospital
                                    ]
                                  ) === "Yes") ||
                                (key === "pay_plan" &&
                                  String(
                                    selectedHospital[
                                      key as keyof typeof selectedHospital
                                    ]
                                  ) === "Yes") ||
                                (key === "bosq" &&
                                  String(
                                    selectedHospital[
                                      key as keyof typeof selectedHospital
                                    ]
                                  ).split(",").length > 1)
                              )
                            ) && (
                              <span className="flex gap-3">
                                <Button
                                  variant={"link"}
                                  onClick={() => {
                                    console.log(key);
                                    handleScript(key);
                                  }}
                                >
                                  View Script
                                </Button>
                                <Button
                                  onClick={() => {
                                    console.log(key);
                                    handleOpenContact();
                                  }}
                                >
                                  Log Call/Visit
                                </Button>
                              </span>
                            )}
                          </p>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : openHistory.isScript ? (
            <>
              {renderScript()}{" "}
              <Button
                onClick={() => {
                  handleOpenContact();
                }}
              >
                Log a Call/Visit
              </Button>
            </>
          ) : openHistory.isPitch ? (
            <>
              <Pitching
                pitch={pitch}
                setPitch={setPitch}
                selectedPitch={pitchSingleInfo}
                handleSendToClose={handleSendToClose}
              />
            </>
          ) : openHistory.isClose && !isPitchingInfo ? (
            <>
              <OptimizedRequestInfo
                hasHeader={false}
                form={pitchForm}
                onSubmit={onSubmitPitch}
                pitchInfo={pitchInfo}
                handleLocationData={handleLocationData}
              />
            </>
          ) : openHistory.isReboarded ? (
            <>
              <OnboardChecklist hasHeader={false} />
            </>
          ) : (
            <>
              {lastPathname === "full" && (
                <div className="flex justify-end">
                  <Button onClick={handleOpenContact}>Contact Hospital</Button>
                </div>
              )}
              <HospitalActivityStream
                items={recentActivities}
                setType={setType}
                type={type}
                status={selectedHospital?.status}
              />
              {/* <RequestTable
                isLoading={isPendingNote}
                data={feed ?? []}
                columns={noteColumns}
              /> */}
            </>
          )}
        </div>
      </ActivityHistory>
    </React.Fragment>
  );
};

export default ChurnType;

const noteColumns = [
  { label: "Date/Time", key: "date" },
  //   { label: "Reward Type", key: "reward" },
  //   { label: "Activity", key: "event" },
  { label: "Status", key: "status" },
  { label: "Note", key: "note" },
];

interface LogData {
  location: string;
  address: string;
}
