import { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import httpService from "./httpService";
import queryString from "query-string";

export const getAgents = (
  page?: number,
  limit?: number,
  search?: string,
  position?: string,
  status?: string
) => {
  const queryParams = new URLSearchParams();

  if (position) {
    queryParams.append("position", position);
  }

  if (page) {
    queryParams.append("page", page.toString());
  }

  if (limit) {
    queryParams.append("limit", limit.toString());
  }

  if (search) {
    queryParams.append("username", search);
  }
  if (status) {
    queryParams.append("disable", status);
  }

  return httpService.get(`/authentication/users?${queryParams.toString()}`);
};

export const getCityAgents = (city: string): Promise<any> => {
  return httpService.get("/leader/" + city);
};

export const getSaleAgents = (city: string) => {
  return httpService.get("/leader/position/sale/" + city);
};

export const assignAgent = (
  data: any,
  handleProgressChange?: (progress: number) => void
): Promise<any> => {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange &&
          handleProgressChange(
            (progressEvent.loaded * 100) / progressEvent.total
          );
      }
    },
  };

  return httpService.post("/manage/assign/hos", data, options);
};

export const reassignAgent = (
  data: Record<string, any>,
  handleProgressChange: { (progress: any): void; (arg0: number): any }
) => {
  const options: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    },
  };

  return httpService.post(
    "/manage/reassign/pitch",
    queryString.stringify(data),
    options
  );
};

export const createAgent = (
  data: {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    position: string;
    city: string;
    country: string;
  },
  handleProgressChange?: { (progress: number): void; (arg0: number): any }
) => {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange &&
          handleProgressChange(
            (progressEvent.loaded * 100) / progressEvent.total
          );
      }
    },
  };

  return httpService.post("/leader/agent/add", data, options);
};

export const changePassword = (
  data: { email: string; password: string; confirmPassword: string },
  handleProgressChange: { (progress: any): void; (arg0: number): any }
) => {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    },
  };

  return httpService.post("/authentication/password/change", data, options);
};

export const disableAgent = (user_id: number | string) => {
  return httpService.post("/leader/agent/disable", { user_id });
};

export const getAssignedHospitals = (agentId: string) => {
  return httpService.get("/manage/agent/assign/hos/" + agentId);
};

export const getScoreCard = (agentId: string) => {
  return httpService.get("/admin/target/indie/" + agentId);
};

export const getAgentAdminHospitals = (filters: {
  page?: number | string;
  limit?: number;
  status?: string;
  name?: string;
  userId?: string;
  health_status?: string;
  username?: string;
  churn_status?: string;
  churn_exit_type?: string;
}) => {
  const queryParams = new URLSearchParams();

  if (
    filters.userId !== null &&
    filters.userId !== undefined &&
    filters.userId !== ""
  ) {
    queryParams.append("userid", filters.userId.toString());
  }

  if (
    filters.page !== null &&
    filters.page !== undefined &&
    filters.page !== 0 &&
    filters.page !== ""
  ) {
    queryParams.append("page", filters.page.toString());
  }
  if (
    filters.limit !== null &&
    filters.limit !== undefined &&
    filters.limit !== 0
  ) {
    queryParams.append("limit", filters.limit.toString());
  }
  if (
    filters.status !== null &&
    filters.status !== undefined &&
    filters.status !== ""
  ) {
    queryParams.append("status", filters.status);
  }
  if (
    filters.name !== null &&
    filters.name !== undefined &&
    filters.name !== ""
  ) {
    queryParams.append("name", filters.name);
  }

  if (
    filters.health_status !== null &&
    filters.health_status !== undefined &&
    filters.health_status !== ""
  ) {
    queryParams.append("health_status", filters.health_status);
  }
  if (
    filters.churn_status !== null &&
    filters.churn_status !== undefined &&
    filters.churn_status !== ""
  ) {
    queryParams.append("churn_status", filters.churn_status);
  }
  if (
    filters.churn_exit_type !== null &&
    filters.churn_exit_type !== undefined &&
    filters.churn_exit_type !== ""
  ) {
    queryParams.append("churn_exit_type", filters.churn_exit_type);
  }

  return httpService.get(`/admin/hospitals/all?${queryParams.toString()}`);
};

export const reassign = (data: any) => {
  return httpService.post("/admin/hospital/assign/agent", data);
};

export const getRewardList = (
  type: string,
  page: number,
  limit: number,
  status: string,
  user_id?: string
) => {
  const queryParams = new URLSearchParams();

  if (user_id !== null && user_id !== undefined && user_id !== "") {
    queryParams.append("userid", user_id.toString());
  }

  if (page !== null && page !== undefined && page !== 0) {
    queryParams.append("page", page.toString());
  }
  if (limit !== null && limit !== undefined && limit !== 0) {
    queryParams.append("limit", limit.toString());
  }
  if (status !== null && status !== undefined && status !== "") {
    queryParams.append("status", status);
  }
  if (type !== null && type !== undefined && type !== "") {
    queryParams.append("type", type);
  }

  return httpService.get(`/admin/users/rewards/list?${queryParams.toString()}`);
};

export const getRewardBalance = (user_id: string) => {
  return httpService.get("/admin/users/rewards/balance/" + user_id);
};

export const getRewardValue = (
  type: string,
  status: string,
  userid?: string
) => {
  const queryParams = new URLSearchParams();

  if (userid !== null && userid !== undefined && userid !== "") {
    queryParams.append("userid", userid.toString());
  }

  if (status !== null && status !== undefined && status !== "") {
    queryParams.append("status", status);
  }

  if (type !== null && type !== undefined && type !== "") {
    queryParams.append("type", type);
  }

  return httpService.get(`admin/users/rewards/value?${queryParams.toString()}`);
};
export const getHospitalOrderHistory = (data: {
  refId: string;
  hospitalId: string;
}) => {
  return httpService.post("/manage/details/pending", data);
};

export const postCadre = (data: any) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };

  return httpService.post("/manage/request/carde/change", data, options);
};

export const getCadreRequest = () => {
  return httpService.get("/manage/get/carde/change/request");
};

export const getCadreList = () => {
  return httpService.get(`/manage/get/carde/list/all`);
};

export const approveCadreRequest = (data: any) => {
  return httpService.post(`/manage/approve/carde/request/${data.id}`, data);
};

export const cancelledOrders = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
}) => {
  const queryParams = new URLSearchParams();
  if (
    filters.page !== null &&
    filters.page !== undefined &&
    filters.page !== 0 &&
    filters.page !== ""
  ) {
    queryParams.append("page", filters.page.toString());
  }
  if (
    filters.limit !== null &&
    filters.limit !== undefined &&
    filters.limit !== 0
  ) {
    queryParams.append("limit", filters.limit.toString());
  }

  if (
    filters.name !== null &&
    filters.name !== undefined &&
    filters.name !== ""
  ) {
    queryParams.append("name", filters.name.toLowerCase());
  }
  if (
    filters.user_id !== null &&
    filters.user_id !== undefined &&
    filters.user_id !== ""
  ) {
    queryParams.append("user_id", filters.user_id);
  }
  return httpService.get(`/manage/cancelled/orders?${queryParams.toString()}`);
};
export const reservedHospitals = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  status?: string;
}) => {
  const queryParams = new URLSearchParams();
  if (
    filters.page !== null &&
    filters.page !== undefined &&
    filters.page !== 0 &&
    filters.page !== ""
  ) {
    queryParams.append("page", filters.page.toString());
  }
  if (
    filters.limit !== null &&
    filters.limit !== undefined &&
    filters.limit !== 0
  ) {
    queryParams.append("limit", filters.limit.toString());
  }

  if (
    filters.name !== null &&
    filters.name !== undefined &&
    filters.name !== ""
  ) {
    queryParams.append("name", filters.name.toLowerCase());
  }
  if (
    filters.status !== null &&
    filters.status !== undefined &&
    filters.status !== ""
  ) {
    queryParams.append("status", filters.status);
  }
  return httpService.get(`/admin/hospitals/reserved?${queryParams.toString()}`);
};

export const acceptReserveRequest = (data: any) => {
  return httpService.post(`/admin/hospitals/reserved/accept`, data);
};
export const updateChurn = (data: any) => {
  return httpService.post(`/admin/churn/agent/update`, data);
};
export const churnNotify = (data: any) => {
  return httpService.post(`/admin/churn/agent/notify`, data);
};

export const getSingleHospitalHistory = (hospital_id?: string) => {
  return httpService.get(`/admin/${hospital_id}`);
};

export const mergeAccount = (data: any) => {
  return httpService.post(
    `/manage/merge/accounts
`,
    data
  );
};
export const completeMergeAccount = (data: {
  action: string;
  hospital_id: string;
  merge_ref_id: string;
}) => {
  return httpService.post(
    `/manage/merge/accounts/complete

`,
    data
  );
};

export const getSingleHospitalChurnStatus = (ref_id?: string) => {
  return httpService.get(`/manage/hospital/churn/status/${ref_id}`);
};

export const churnPartialAction = (data: {
  hospital_id: string;
  agent_id: string;
  reason: string;
  action: string;
  reasonresolvable?: number;
  reboardable: number;
  reboardable_date: string;
}) => {
  return httpService.post(`/admin/churn/partial/action`, data);
};
export const churnVisit = (data: {
  hospital_id: string;
  agent_id: string;
  reason: string;
  reasonresolvable: number;
}) => {
  return httpService.post(`/admin/churn/agent/visit`, data);
};

export const updateReboard = (data: { step: string; id: string }) => {
  const payload = {
    step: data.step,
  };
  return httpService.put(`/manage/hospital/churn/reboard/${data.id}`, payload);
};
export const logHistoryNote = (data: {
  hospital_id: string;
  agent_id: string;
  type: string;
  visited: number;
  location_geocode: number[];
  note: string;
}) => {
  return httpService.post(`/admin/churn/agent/notes`, data);
};
export const getPitchDetails = (id: string) => {
  return httpService.get(`/manage/hospital/get-pitch-details/${id}`);
};

const agentService = {
  assignAgent,
  changePassword,
  createAgent,
  disableAgent,
  getAgents,
  getAssignedHospitals,
  getSaleAgents,
  getScoreCard,
  reassignAgent,
  getCityAgents,
  getAgentAdminHospitals,
  reassign,
  getRewardList,
  getRewardBalance,
  getRewardValue,
  getHospitalOrderHistory,
  postCadre,
  getCadreRequest,
  getCadreList,
  approveCadreRequest,
  cancelledOrders,
  reservedHospitals,
  acceptReserveRequest,
  updateChurn,
  churnNotify,
  getSingleHospitalHistory,
  mergeAccount,
  completeMergeAccount,
  getSingleHospitalChurnStatus,
  churnPartialAction,
  churnVisit,
  updateReboard,
  logHistoryNote,
  getPitchDetails,
};

export default agentService;
