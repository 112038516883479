// const applyFilters = (
//   filters: { [s: string | number]: unknown } | ArrayLike<unknown>,
//   history: any,
//   path: any
// ) => {
//   const queryParams = new URLSearchParams();
//   Object.entries(filters).forEach(([key, value]) => {
//     if (value !== null && value !== undefined && value !== "") {
//       queryParams.append(key, String(value));
//     }
//   });

//   history(`/${path}?${queryParams.toString()}`);
// };

// export default applyFilters;

const applyFilters = (
  filters: { [s: string | number]: unknown } | ArrayLike<unknown>,
  history: any,
  path: string
) => {
  // Get current URL search parameters
  const currentUrl = new URL(window.location.href);
  const currentParams = new URLSearchParams(currentUrl.search);

  // Add new filters or overwrite existing ones
  Object.entries(filters).forEach(([key, value]) => {
    if (value !== null && value !== undefined && value !== "") {
      currentParams.set(key, String(value)); // Overwrite or add filter
    } else {
      currentParams.delete(key); // Remove the key if value is null/undefined/empty
    }
  });

  // Navigate to the new URL with merged parameters
  history(`/${path}?${currentParams.toString()}`);
};

export default applyFilters;
