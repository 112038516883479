import React from "react";
import { Link } from "react-router-dom";

const HealthCard = ({
  preChurn,
  danger,
  addValue,
  checkIn,
  upsell,
}: {
  preChurn: number;
  danger: number;
  addValue: number;
  checkIn: number;
  upsell: number;
}) => {
  const healthTypes = [
    { label: "Pre Churn", value: preChurn ?? 0, filter: "pre-churn" },
    { label: "Danger(ICU)", value: danger ?? 0, filter: "danger-group" },
    {
      label: "General Ward",
      value: addValue ?? 0,
      filter: "general-ward-group",
    },
    { label: "Check In", value: checkIn ?? 0, filter: "check-in-group" },
    { label: "Healthy", value: upsell ?? 0, filter: "healthy-group" },
  ];

  return (
    <div className="rounded-lg border-2 border-purple-900 p-4 bg-white hover:shadow-lg">
      <h2 className="text-2xl font-bold text-purple-900 uppercase">
        Health Score
      </h2>
      <div className="space-y-3">
        {healthTypes.map((type, index) => (
          <Link
            to={`/health-score?status=${type.filter}&page=1`}
            key={index}
            className="flex justify-between"
          >
            <span className="font-semibold text-gray-700">{type.label}</span>
            <div className="text-gray-600">{type.value} hospitals</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default HealthCard;
