import React from "react";
import { getPrivileges } from "../../services/authService";
// import ChangeCityForm from "../forms/changeCity";
import { useGlobalContext } from "../../contexts/globalContext";

interface HeaderProps {
  title: string;
}

const Header = ({ title }: HeaderProps) => {
  // const [showModal, setShowModal] = useState<boolean>(false);

  // const handleShowModal = (): void => {
  //   setShowModal((prev) => !prev);
  // };

  const { city } = useGlobalContext();
  const { viewByState, createAgent } = getPrivileges();

  return (
    <React.Fragment>
      <header>
        <h1 className="capitalize">{title}</h1>

        {!createAgent && (
          <div className="header-right-options">
            <button
              type="button"
              className="toggle-city first-item"
              disabled={!viewByState}
              // onClick={handleShowModal}
            >
              <i className="fa fa-globe"></i>
              {city}
            </button>
          </div>
        )}
      </header>

      {/* {viewByState && showModal && (
        <ChangeCityForm handleClose={handleShowModal} />
      )} */}
    </React.Fragment>
  );
};

export default Header;
