// import { useEffect, useState } from "react";
// import dayjs from "dayjs";

import dayjs from "dayjs";
import { useEffect, useState } from "react";

// const useChurnDateRange = (selectedType: string) => {
//   const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);

//   useEffect(() => {
//     const today = dayjs(); // Current date
//     const yesterdayThisYear = today.month(today.month()).date(today.date() - 1); // Yesterday's date of the current year

//     // Helper function to create date ranges
//     const createRange = (startConfig: dayjs.Dayjs, endConfig: dayjs.Dayjs) => {
//       return [
//         startConfig.startOf("day"),    // 00:00:00 of start date
//         endConfig.endOf("day")          // 23:59:59 of end date
//       ];
//     };

//     // Calculate key dates based on yesterdayThisYear
//     const oneYearAgo = yesterdayThisYear.subtract(1, "year"); // Yesterday's date last year
//     const twoYearsAgo = yesterdayThisYear.subtract(3, "year"); // Yesterday's date two years ago
//     const inceptionDate = dayjs("2015-01-01"); // Adjust inception date if needed

//     let newRange;

//     switch (selectedType) {
//       case "risk":
//         // Not ordered within the last year
//         newRange = createRange(oneYearAgo, yesterdayThisYear);
//         break;

//       case "full":
//       case "partial":
//         // Not ordered between 1-2 years ago
//         newRange = createRange(twoYearsAgo, oneYearAgo);
//         break;

//       case "exit":
//         // Not ordered in 2+ years
//         newRange = createRange(inceptionDate, twoYearsAgo);
//         break;

//       default:
//         // Default case: range covers only today
//         newRange = createRange(today, today);
//         break;
//     }

//     setDateRange(newRange);
//   }, [selectedType]);

//   const formatDate = (date: dayjs.Dayjs, format = "YYYY-MM-DD") => {
//     return date.format(format);
//   };

//   const getReadableRange = () => {
//     return {
//       start: formatDate(dateRange[0]),
//       end: formatDate(dateRange[1]),
//       readable: `${formatDate(dateRange[0], "MMM D, YYYY")} - ${formatDate(
//         dateRange[1],
//         "MMM D, YYYY"
//       )}`,
//     };
//   };

//   const getMilliseconds = () => {
//     return {
//       start: dateRange[0].valueOf(),
//       end: dateRange[1].valueOf(),
//       readable: `${dateRange[0].valueOf()},${dateRange[1].valueOf()}`,
//     };
//   };

//   const getSeconds = () => {
//     // Return the date range in Unix seconds (not milliseconds)
//     return {
//       start: Math.floor(dateRange[0].valueOf() / 1000),
//       end: Math.floor(dateRange[1].valueOf() / 1000),
//       readable: `${Math.floor(dateRange[0].valueOf() / 1000)},${Math.floor(
//         dateRange[1].valueOf() / 1000
//       )}`,
//     };
//   };

//   const getChurnClassification = () => {
//     switch (selectedType) {
//       case "risk":
//         return {
//           title: "Churn Risk",
//           description: `Haven't ordered between ${formatDate(
//             dateRange[0],
//             "MMM D, YYYY"
//           )} and ${formatDate(
//             dateRange[1],
//             "MMM D, YYYY"
//           )} (within the last year)`,
//           period: "Not ordered within a year",
//         };
//       case "full":
//         return {
//           title: "Full Churn",
//           description: `Haven't ordered between ${formatDate(
//             dateRange[0],
//             "MMM D, YYYY"
//           )} and ${formatDate(
//             dateRange[1],
//             "MMM D, YYYY"
//           )} (between 1-2 years ago)`,
//           period: "Not ordered between 1-2 years, no clear reason",
//         };
//       case "partial":
//         return {
//           title: "Partial Churn",
//           description: `Haven't ordered between ${formatDate(
//             dateRange[0],
//             "MMM D, YYYY"
//           )} and ${formatDate(
//             dateRange[1],
//             "MMM D, YYYY"
//           )} (between 1-2 years ago)`,
//           period: "Not ordered between 1-2 years, with known reason",
//         };
//       case "exit":
//         return {
//           title: "In Exit",
//           description: `Haven't ordered between ${formatDate(
//             dateRange[0],
//             "MMM D, YYYY"
//           )} and ${formatDate(dateRange[1], "MMM D, YYYY")} (2+ years)`,
//           period: "Not ordered in two years or more",
//         };
//       default:
//         return {
//           title: "",
//           description: "",
//           period: "",
//         };
//     }
//   };

//   return {
//     dateRange,
//     formatDate,
//     getReadableRange,
//     getMilliseconds,
//     getSeconds,
//     getChurnClassification,
//     startDate: dateRange[0],
//     endDate: dateRange[1],
//   };
// };

// export default useChurnDateRange;

const useChurnDateRange = (selectedType: string) => {
  const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);

  useEffect(() => {
    const today = dayjs(); // Current date
    const threeMonthsAgoThisYear = today.subtract(3, "month"); // Three months ago from today
    const tenMonthsAgoThisYear = today.subtract(13, "month"); // Ten months ago from today

    // Helper function to create date ranges
    const createRange = (startConfig: dayjs.Dayjs, endConfig: dayjs.Dayjs) => {
      return [
        startConfig.startOf("day"), // 00:00:00 of start date
        endConfig.endOf("day"), // 23:59:59 of end date
      ];
    };

    // Calculate key dates based on threeMonthsAgoThisYear and tenMonthsAgoThisYear
    const oneYearAgo = tenMonthsAgoThisYear.subtract(2, "year"); // Three months ago last year
    // const twoYearsAgo = threeMonthsAgoThisYear.subtract(3, "year"); // Three months ago two years ago
    const inceptionDate = dayjs("2016-01-01"); // Adjust inception date if needed

    let newRange;

    switch (selectedType) {
      case "risk":
        // Define range from 10 months ago to 3 months ago this year
        newRange = createRange(tenMonthsAgoThisYear, threeMonthsAgoThisYear);
        break;

      case "full":
      case "partial":
        // Not ordered between 1-2 years ago
        newRange = createRange(
          oneYearAgo.add(1, "month"),
          tenMonthsAgoThisYear
        );
        break;

      case "exit":
        // Not ordered in 2+ years
        newRange = createRange(inceptionDate, oneYearAgo.add(1, "month"));
        break;

      default:
        // Default case: range covers only today
        newRange = createRange(inceptionDate, threeMonthsAgoThisYear);
        break;
    }

    setDateRange(newRange);
  }, [selectedType]);

  const formatDate = (date: dayjs.Dayjs, format = "YYYY-MM-DD") => {
    return date.format(format);
  };

  const getReadableRange = () => {
    return {
      start: formatDate(dateRange[0]),
      end: formatDate(dateRange[1]),
      readable: `${formatDate(dateRange[0], "MMM D, YYYY")} - ${formatDate(
        dateRange[1],
        "MMM D, YYYY"
      )}`,
    };
  };

  const getMilliseconds = () => {
    return {
      start: dateRange[0].valueOf(),
      end: dateRange[1].valueOf(),
      readable: `${dateRange[0].valueOf()},${dateRange[1].valueOf()}`,
    };
  };

  const getSeconds = () => {
    // Return the date range in Unix seconds (not milliseconds)
    return {
      start: Math.floor(dateRange[0].valueOf() / 1000),
      end: Math.floor(dateRange[1].valueOf() / 1000),
      readable: `${Math.floor(dateRange[0].valueOf() / 1000)},${Math.floor(
        dateRange[1].valueOf() / 1000
      )}`,
    };
  };

  const getChurnClassification = () => {
    switch (selectedType) {
      case "risk":
        return {
          title: "Churn Risk",
          description: `Haven't ordered between ${formatDate(
            dateRange[0],
            "MMM D, YYYY"
          )} and ${formatDate(
            dateRange[1],
            "MMM D, YYYY"
          )} (within the last 10 months)`,
          period: "Not ordered in the past 7 months ending 3 months ago",
        };
      case "full":
        return {
          title: "Full Churn",
          description: `Haven't ordered between ${formatDate(
            dateRange[0],
            "MMM D, YYYY"
          )} and ${formatDate(
            dateRange[1],
            "MMM D, YYYY"
          )} (between 1-2 years ago)`,
          period: "Not ordered between 1-2 years, no clear reason",
        };
      case "partial":
        return {
          title: "Partial Churn",
          description: `Haven't ordered between ${formatDate(
            dateRange[0],
            "MMM D, YYYY"
          )} and ${formatDate(
            dateRange[1],
            "MMM D, YYYY"
          )} (between 1-2 years ago)`,
          period: "Not ordered between 1-2 years, with known reason",
        };
      case "exit":
        return {
          title: "In Exit",
          description: `Haven't ordered between ${formatDate(
            dateRange[0],
            "MMM D, YYYY"
          )} and ${formatDate(dateRange[1], "MMM D, YYYY")} (2+ years)`,
          period: "Not ordered in two years or more",
        };
      default:
        return {
          title: "",
          description: "",
          period: "",
        };
    }
  };

  return {
    dateRange,
    formatDate,
    getReadableRange,
    getMilliseconds,
    getSeconds,
    getChurnClassification,
    startDate: dateRange[0],
    endDate: dateRange[1],
  };
};

export default useChurnDateRange;
