import { AxiosProgressEvent } from "axios";
import httpService from "./httpService";

export const getComplaints = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
  status?: string;
  hospital_id?: string;
}) => {
  const queryParams = new URLSearchParams();

  if (
    filters.page !== null &&
    filters.page !== undefined &&
    filters.page !== 0 &&
    filters.page !== ""
  ) {
    queryParams.append("page", filters.page.toString());
  }
  if (
    filters.limit !== null &&
    filters.limit !== undefined &&
    filters.limit !== 0
  ) {
    queryParams.append("limit", filters.limit.toString());
  }

  if (
    filters.name !== null &&
    filters.name !== undefined &&
    filters.name !== ""
  ) {
    queryParams.append("name", filters.name.toLowerCase());
  }
  if (
    filters.user_id !== null &&
    filters.user_id !== undefined &&
    filters.user_id !== ""
  ) {
    queryParams.append("user_id", filters.user_id);
  }
  if (
    filters.status !== null &&
    filters.status !== undefined &&
    filters.status !== ""
  ) {
    queryParams.append("status", filters.status);
  }

  if (
    filters.hospital_id !== null &&
    filters.hospital_id !== undefined &&
    filters.hospital_id !== ""
  ) {
    queryParams.append("hospital_id", filters.hospital_id);
  }

  return httpService.get(
    `/manage/complaint/list/${filters.user_id}?${queryParams.toString()}`
  );
};

export const getOrderComplaints = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  title?: string;
  status?: string;
  ref_id?: string;
  assign?: string;
}) => {
  const queryParams = new URLSearchParams();

  if (
    filters.page !== null &&
    filters.page !== undefined &&
    filters.page !== 0 &&
    filters.page !== ""
  ) {
    queryParams.append("page", filters.page.toString());
  }
  if (
    filters.assign !== null &&
    filters.assign !== undefined &&
    filters.assign !== ""
  ) {
    queryParams.append("assign", filters.assign.toString());
  }
  if (
    filters.limit !== null &&
    filters.limit !== undefined &&
    filters.limit !== 0
  ) {
    queryParams.append("limit", filters.limit.toString());
  }

  if (
    filters.name !== null &&
    filters.name !== undefined &&
    filters.name !== ""
  ) {
    queryParams.append("name", filters.name.toLowerCase());
  }
  if (
    filters.title !== null &&
    filters.title !== undefined &&
    filters.title !== ""
  ) {
    queryParams.append("title", filters.title);
  }
  if (
    filters.status !== null &&
    filters.status !== undefined &&
    filters.status !== ""
  ) {
    queryParams.append("status", filters.status);
  }

  if (
    filters.ref_id !== null &&
    filters.ref_id !== undefined &&
    filters.ref_id !== ""
  ) {
    queryParams.append("ref_id", filters.ref_id);
  }

  // manage/orders/complaints?name=Rosemary&ref_id=460728159&title&status&assign
  return httpService.get(`/manage/orders/complaints?${queryParams.toString()}`);

  // return httpService.get(
  //   `/manage/complaint/list/${filters.user_id}?${queryParams.toString()}`
  // );
};

export const getHospitalComplaints = (hospitalId: string): Promise<any> => {
  return httpService.get("/manage/hospitals/complaint/list/" + hospitalId);
};

export const getComplaintCategories = () => {
  return httpService.get("/manage/categories");
};

export const addComplaint = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressChange(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      ),
  };

  return httpService.post("/manage/complaint", data, options);
};

export const resolveComplaint = (
  complaint: { complaint_id: any; hospital_id: any; return: any },
  handleProgressChange: { (progress: any): void; (arg0: number): any }
) => {
  const options = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressChange(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      ),
  };

  const apiData = {
    complaint_id: "",
    hospital_id: "",
    return: "",
  };

  apiData.complaint_id = complaint.complaint_id;
  apiData.hospital_id = complaint.hospital_id;
  apiData.return = complaint.return;

  return httpService.put("/manage/complaint/resolve", apiData, options);
};

const complaintService = {
  addComplaint,
  getComplaints,
  getComplaintCategories,
  getHospitalComplaints,
  resolveComplaint,
  getOrderComplaints,
};

export default complaintService;
