import React, { Dispatch, SetStateAction } from "react";
import { Card, CardContent } from "../../../../../@/components/ui/card";
import LogVisit from "../../../../../components/modals/logVisit";
import RadioButton from "../../../../../components/common/radio";
import CustomDatePicker from "../../../../../components/common/datePicker";
import TimePicker from "../../../../../components/common/TimePicker";
import { useGlobalContext } from "../../../../../contexts/globalContext";

export default function Pitching({
  pitch,
  setPitch,
  selectedPitch,
  handleSendToClose,
}: {
  pitch: {
    page: number;
    log: {
      summary: string;
      successful: string;
      agent_id: string;
      hos_id: string;
      name: string;
      address: string;
    } | null;

    onboard: string;
    appointment: string;
    time: string;
    date: string;
    summary: string;
    closingDate: Date;
    closingTime: string;

    isPending: boolean;
    logData: {
      location: string;
      address: string;
    };
  };
  handleSendToClose: () => void;
  selectedPitch: any;
  setPitch: Dispatch<
    SetStateAction<{
      page: number;
      log: {
        summary: string;
        successful: string;
        agent_id: string;
        hos_id: string;
        name: string;
        address: string;
      } | null;

      onboard: string;
      appointment: string;
      time: string;
      date: string;
      summary: string;
      closingDate: Date;
      closingTime: string;
      isPending: boolean;
      logData: {
        location: string;
        address: string;
      };
    }>
  >;
}) {
  console.log(selectedPitch);
  console.log(pitch);
  const { user } = useGlobalContext();
  const viewAppointment = (
    value: {
      time: string;
      date: string;
    } | null
  ) => {
    setPitch((prev) => ({
      ...prev,
      time: value?.time as string,
      date: value?.date as string,
    }));
  };

  const handleOnboard = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPitch((prev) => ({
      ...prev,
      onboard: e.target.value as string,
    }));
  };

  const handleStartDate = (startDate: React.SetStateAction<Date | null>) => {
    setPitch((prev) => ({
      ...prev,
      startDate,
    }));
  };

  const handleClosingDate = (
    closingDate: React.SetStateAction<Date | null>
  ) => {
    setPitch((prev) => ({
      ...prev,
      closingDate: closingDate as Date,
    }));
  };

  const handleTimeChange = (e: { target: { value: any } }) => {
    setPitch((prev) => ({
      ...prev,
      selectedTime: e.target.value,
    }));
  };

  const handleClosingTime = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPitch((prev) => ({
      ...prev,
      closingTime: e.target.value as string,
    }));
  };
  return (
    <div>
      <Card>
        {pitch.page === 1 && (
          <div className="pitch-main">
            <h4
              style={{
                textAlign: "center",
                marginTop: "1rem",
                textDecoration: "underline",
                fontWeight: "600",
              }}
            >
              PREPARE FOR PITCH
            </h4>
            <div className="flex items-center justify-between px-4 md:px-10 w-full border-b py-4">
              <span className="label font-bold text-[0.8rem] md:text-base">
                Hospital Name:
              </span>
              <span className="value">{selectedPitch?.name}</span>
            </div>
            <div className="flex items-center justify-between px-4 md:px-10 w-full border-b py-4">
              <span className="label font-bold text-[0.8rem] md:text-base">
                Phone Number:
              </span>
              <span className="value ">{selectedPitch?.phone}</span>
            </div>
            <div className="flex items-center justify-between px-4 md:px-10 w-full border-b py-4">
              <span className="label font-bold text-[0.8rem] md:text-base">
                Agent:
              </span>
              <span className="value ">{selectedPitch?.agent}</span>
            </div>
            <div className="flex items-center justify-between px-4 md:px-10 w-full border-b py-4 gap-3">
              <span className="label font-bold text-[0.8rem] md:text-base">
                Location:
              </span>
              <span className="value ">{selectedPitch?.address}</span>
            </div>
            <div className="flex items-center justify-between px-4 md:px-10 w-full border-b py-4">
              <span className="label font-bold text-[0.8rem] md:text-base">
                Prospecting
              </span>
              <span className="label">{selectedPitch?.prospecting}</span>
            </div>
            <div className="flex items-center justify-between px-4 md:px-10 w-full border-b py-4">
              <span className="label font-bold text-[0.8rem] md:text-base">
                Appointment Details
              </span>
              <div className="value flex items-center">
                <span>{`${selectedPitch?.date ?? "-"} | ${
                  selectedPitch?.time ?? "-"
                } `}</span>
                <span
                  className="link"
                  onClick={() =>
                    viewAppointment({
                      time: selectedPitch?.time,
                      date: selectedPitch?.date,
                    })
                  }
                >
                  Edit
                </span>
              </div>
            </div>
            <div className="flex items-center justify-between px-4 md:px-10 w-full border-b py-4">
              <span className="label font-bold text-[0.8rem] md:text-base">
                Prep for Pitch
              </span>
              <div className=" value flex items-center">
                <p
                  className="link"
                  onClick={() =>
                    window.open(
                      "https://docs.google.com/presentation/d/1wjhLw9nOekEipmE1EcB3Ngd2IUwiw0jt/edit?usp=sharing&ouid=102254922731981175623&rtpof=true&sd=true",
                      "_blank"
                    )
                  }
                >
                  Conversational Deck
                </p>
                <p
                  className="link"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/watch?app=desktop&v=kGFPS2v-uwE",
                      "_blank"
                    )
                  }
                >
                  Video
                </p>
              </div>
            </div>

            <div className="custom-buttons mx-auto py-3 ">
              <button
                className="shadow-sm transition duration-500 ease-in-out bg-[#441AB7] text-white hover:bg-[#9575e7] transform hover:-translate-y-1 hover:scale-110 py-2 px-4 rounded-md"
                onClick={(e) => {
                  e.preventDefault();
                  setPitch((prev) => ({
                    ...prev,
                    page: 2,
                    log: {
                      summary: pitch.summary,
                      successful: pitch.onboard,
                      agent_id: user?.id as string,
                      hos_id: selectedPitch.hos_id as string,
                      name: selectedPitch?.name,
                      address: selectedPitch?.address,
                    },
                  }));
                }}
              >
                I'm ready
              </button>
            </div>
          </div>
        )}

        {pitch.page === 2 && pitch.log && (
          <CardContent className="p-4">
            <LogVisit
              handleClose={() => {
                setPitch((prev) => ({ ...prev, page: 1, log: null }));
              }}
              changePage={() => setPitch((prev) => ({ ...prev, page: 3 }))}
              setLogData={() =>
                setPitch((prev) => ({ ...prev, logData: { ...prev.logData } }))
              }
              label="Good luck with your pitch. Proceed to submit your result"
            />
          </CardContent>
        )}

        {pitch.page === 3 && (
          <div className="pitch-main">
            <h4
              style={{
                textAlign: "center",
                marginTop: "1rem",
                textDecoration: "underline",
                fontWeight: "600",
              }}
            >
              POST PITCH PAGE
            </h4>
            <div className="pitch-data2">
              <span className="label">Summary</span>
              <div className="value">
                <textarea
                  id="summary"
                  name="summary"
                  onChange={(e) =>
                    setPitch((prev) => ({ ...prev, summary: e.target.value }))
                  }
                  placeholder="Provide information about objections and complaints"
                  rows={4}
                  // cols={50}
                  className="form-field-input-text outline p-2 rounded-md w-full outline-gray-300"
                ></textarea>
              </div>
            </div>

            <div className="pitch-data2">
              <span className="label">
                Would {selectedPitch?.name} like to use lifebank{" "}
              </span>
              <div className="value">
                <RadioButton
                  name="yes"
                  value="yes"
                  message="Yes"
                  onChange={handleOnboard}
                  checked={pitch?.onboard === "yes"}
                />
                <RadioButton
                  name="no"
                  value="no"
                  message="No"
                  onChange={handleOnboard}
                  checked={pitch?.onboard === "no"}
                />
                <RadioButton
                  name="maybe"
                  value="maybe"
                  message="Maybe"
                  onChange={handleOnboard}
                  checked={pitch?.onboard === "maybe"}
                />
              </div>
            </div>

            <div className="pitch-data2">
              <span className="label">Closing Appointment </span>
              <div className="value">
                <div className="row">
                  <div className="col-md">
                    <CustomDatePicker
                      label="Appointment Date"
                      minDate={new Date()}
                      selectedDate={pitch.closingDate}
                      onDateChange={handleClosingDate}
                    />
                  </div>
                </div>

                <div className="col-md">
                  <TimePicker
                    label="Appointment Time"
                    name="time"
                    onChange={handleClosingTime}
                    value={pitch.closingTime}
                  />
                </div>
              </div>
            </div>
            <div className="custom-buttons mx-auto py-3">
              <button
                className={`shadow-md transition duration-500 ease-in-out bg-[#441AB7] text-white hover:bg-[#9575e7] py-2 px-4 rounded-md active:translate-y-2 active:scale-95 ${
                  !pitch.summary ||
                  !pitch?.onboard ||
                  !pitch.closingDate ||
                  !pitch.closingTime
                    ? "disabled"
                    : ""
                }`}
                onClick={handleSendToClose}
                disabled={
                  !pitch.summary ||
                  !pitch?.onboard ||
                  !pitch.closingDate ||
                  !pitch.closingTime ||
                  pitch.isPending
                }
              >
                {pitch.isPending
                  ? "Closing, Please wait..."
                  : "Prep for Closing"}
              </button>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}
