import { cn } from "../../../../@/lib/utils";

const ChurnPipeline = ({
  activeCategory,
  onActiveChange,
  items,
  onClick,
}: {
  activeCategory: {
    title: string;
    tag: string;
    color: string;
    sortKey: string;
    data: string;
    icon: string;
  } | null;
  onActiveChange: (data: {
    title: string;
    tag: string;
    color: string;
    sortKey: string;
    data: string;
    icon: string;
  }) => void;
  items: {
    title: string;
    tag: string;
    color: string;
    sortKey: string;
    data: string;
    icon: string;
  }[];
  onClick: (item: {
    title: string;
    tag: string;
    color: string;
    sortKey: string;
    data: string;
    icon: string;
  }) => void;
}): JSX.Element => {
  return (
    <ul id="breadcrumb">
      {items.map(
        (item: {
          title: string;
          tag: string;
          color: string;
          sortKey: string;
          data: string;
          icon: string;
        }) => {
          const isActive: boolean =
            item.tag.toLowerCase() === activeCategory?.tag.toLowerCase();
          return (
            <li
              key={item.title}
              className={cn(isActive ? "active" : "inactive", "text-sm")}
              style={{ width: `calc(100%/${items.length})` }}
              onClick={() => onClick(item)}
            >
              <span
                className="arrowLeft"
                style={{
                  borderColor: item.color,
                  borderLeftColor: "transparent",
                }}
              ></span>
              <p className="flex gap-2" style={{ backgroundColor: item.color }}>
                <i className={"fa fa-" + item.icon}></i>{" "}
                <span>{item.title} </span>
                <span>{item.data ? item.data : "0"}</span>
              </p>
              <span
                className="arrowRight"
                style={{ borderLeftColor: item.color }}
              ></span>
            </li>
          );
        }
      )}
    </ul>
  );
};

export default ChurnPipeline;
