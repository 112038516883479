import React, { useState, useMemo } from "react";

import { Button as Btn } from "../../../../@/components/ui/button";
import { ReloadIcon } from "@radix-ui/react-icons";
import { MoreVertical, Eye } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { Checkbox } from "../../../../@/components/ui/checkbox";
import LoadingButton from "../../../../components/common/loadingButton";
import ProgressLoader from "../../../../components/common/progressLoader";
import TransitionsModal from "../../../../components/modals/mod/CustomModal";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import EmailContent from "./EmailContent";

import {
  useGetDrips,
  useCreateDrip,
} from "../../../../hooks/marketing/useDrips";
import CreateDrips from "./CreateDrips";
import toastService from "../../../../services/toastService";
import DataTable from "../../../../components/tables/tableEvents/data-table";
import Header from "../../../../components/blocks/header";

const platformOptions = [
  { value: "WhatsApp", label: "WhatsApp" },
  { value: "Email", label: "Email" },
  { value: "SMS", label: "SMS" },
];
const Drips = () => {
  const { drips, isPending, baseUrl } = useGetDrips();
  const [progress, setProgress] = useState(0);
  const handleProgressChange = (progress: any) => {
    setProgress(progress);
  };

  const { mutate, isPending: isCreating } = useCreateDrip(handleProgressChange);

  const [asset, setAsset] = useState<File | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const [viewDrip, setViewDrip] = useState<any>(null);

  const handleCloseViewDrip = () => {
    setViewDrip(null);
  };

  const transformedDrips = drips
    .map((drip: { title: string }) => {
      return {
        ...drip,
        name: drip.title,
      };
    })
    .reverse();

  const columns = useMemo(
    () => [
      {
        id: "select",
        header: ({ table }: { table: any }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) => {
              table.toggleAllPageRowsSelected(!!value);
            }}
            aria-label="Select all"
          />
        ),
        cell: ({ row }: { row: any }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              row.toggleSelected(!!value);
            }}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Drip title",
      },
      {
        accessorKey: "description",
        header: "Description",
        cell: ({ row }: { row: any }) => {
          const description = row.getValue("description") as string;
          return (
            <div className="max-h-10 w-32 overflow-hidden text-ellipsis">
              {description ?? "N/A"}
            </div>
          );
        },
      },
      {
        accessorKey: "message",
        header: "Message",
        cell: ({ row }: { row: any }) => {
          const sms = row.original.message;
          const email = row.original.email;
          const whatsapp = row.original.whatsapp;

          return (
            <div className="max-h-10 w-32 overflow-hidden text-ellipsis">
              {email ? (
                <EmailContent htmlContent={email} />
              ) : sms ? (
                sms
              ) : (
                whatsapp
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "value",
        header: "Hospital value",
        cell: ({ row }: { row: any }) => {
          const value = row.getValue("value") as string;
          return <div>{value ?? "N/A"}</div>;
        },
      },
      {
        accessorKey: "type",
        header: "Drip type",
        cell: ({ row }: { row: any }) => {
          const type = row.getValue("type") as string;
          return <div>{type ?? "N/A"}</div>;
        },
      },

      {
        accessorKey: "platform",
        header: "Channel(s)",
        cell: ({ row }: { row: any }) => {
          const platform = row.getValue("platform") as string;
          return <div>{platform?.split(",").join(", ") ?? "N/A"}</div>;
        },
      },
      {
        accessorKey: "usage",
        header: "Views",
        cell: ({ row }: { row: any }) => {
          const usage = row.getValue("usage") as number;
          return <div>{usage ?? "N/A"}</div>;
        },
      },

      {
        accessorKey: "id",
        header: " ",
        cell: ({ row }: { row: any }) => {
          return (
            <div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Btn variant="ghost" className="h-8 w-8 p-0">
                    <span className="sr-only">Open menu</span>
                    <MoreVertical className="h-4 w-4" />
                  </Btn>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    onClick={() => {
                      setViewDrip(row.original);
                    }}
                    className="cursor-pointer"
                  >
                    <Eye className="mr-2 h-4 w-4" /> View
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        },
      },
    ],
    []
  );

  const schema = z
    .object({
      title: z
        .string()
        .min(3, { message: "Title must be at least 3 characters." }),
      value: z.object({
        value: z.string().min(1, { message: "Hospital value is required." }),
      }),
      description: z
        .string()
        .min(10, { message: "Description must be at least 10 characters." }),
      type: z.object({
        value: z.string().min(1, { message: "Drip type is required." }),
      }),
      // asset: z
      //   .string()
      //   .min(3, { message: "Asset must be at least 3 characters." }),
      // platform: z.object({
      //   value: z.string().min(1, { message: "Platform is required." }),
      // }),

      platform: z.array(
        z.string().min(1, { message: "Platform is required." })
      ),
      whatsappMessage: z.string().optional(),
      emailMessage: z.string().optional(),
      smsMessage: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      const platforms = data.platform;
      if (platforms.includes("WhatsApp") && !data.whatsappMessage) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "WhatsApp message is required.",
          path: ["whatsappMessage"],
        });
      }
      if (platforms.includes("Email") && !data.emailMessage) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Email message is required.",
          path: ["emailMessage"],
        });
      }
      if (platforms.includes("SMS") && !data.smsMessage) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "SMS message is required.",
          path: ["smsMessage"],
        });
      }
    });
  // .superRefine((data, ctx) => {
  //   if (data.platform.value === "WhatsApp" && !data.whatsappMessage) {
  //     ctx.addIssue({
  //       code: z.ZodIssueCode.custom,
  //       message: "WhatsApp message is required.",
  //       path: ["whatsappMessage"],
  //     });
  //   } else if (data.platform.value === "Email" && !data.emailMessage) {
  //     ctx.addIssue({
  //       code: z.ZodIssueCode.custom,
  //       message: "Email message is required.",
  //       path: ["emailMessage"],
  //     });
  //   } else if (data.platform.value === "SMS" && !data.smsMessage) {
  //     ctx.addIssue({
  //       code: z.ZodIssueCode.custom,
  //       message: "SMS message is required.",
  //       path: ["smsMessage"],
  //     });
  //   }
  // });

  const form = useForm({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      title: "",
      value: "",
      description: "",
      type: {
        value: "",
      },
      // asset: "",
      platform: [],
      // platform: {
      //   value: "Email",
      // },
      whatsappMessage: "",
      emailMessage: "",
      smsMessage: "",
    },
  });

  const { watch, handleSubmit, reset } = form;

  const selectedDripType = watch("type");
  const selectedPlatforms = watch("platform") as string[];
  const selectedPlatformsString = selectedPlatforms.join(",");

  const onSubmit = async (data: any) => {
    console.log(data);
    if (!asset) {
      toastService.error("Asset is required. Please upload an asset.");
      return;
    }

    const fileType = asset.type;
    let typeCategory = "";

    if (fileType.startsWith("image/")) {
      typeCategory = "picture";
    } else if (fileType === "application/pdf") {
      typeCategory = "pdf";
    } else if (fileType.startsWith("video/")) {
      typeCategory = "video";
    }

    if (typeCategory !== selectedDripType?.value.toLowerCase()) {
      toastService.error(
        "The uploaded file type does not match the selected drip type."
      );

      return;
    }

    const formData = new FormData();
    formData.append("description", data.description);
    formData.append("title", data.title);
    formData.append("type", data.type.value);
    formData.append("value", data.value.value);
    formData.append("platform", selectedPlatformsString);
    if (asset) {
      formData.append("asset", asset);
    }
    if (selectedPlatforms.includes("WhatsApp")) {
      formData.append("whatsapp", data.whatsappMessage);
    }
    if (selectedPlatforms.includes("Email")) {
      formData.append("email", data.emailMessage);
    }
    if (selectedPlatforms.includes("SMS")) {
      formData.append("sms", data.smsMessage);
    }

    mutate(formData, {
      onSettled: () => {
        setProgress(0);
        reset();
        handleCloseModal();
      },
    });
  };

  const createDrips = () => {
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
    setAsset(null);
  };

  const handleBackdropClose = () => setViewDrip(null);

  const renderPreview = (assetType: string, previewURL: string | undefined) => {
    if (!previewURL) return null;

    switch (assetType) {
      case "picture":
        return (
          <img
            className="rounded-md object-fit max-h-full"
            src={`https://${baseUrl}${previewURL}`}
            alt="preview"
          />
        );
      case "pdf":
        return (
          <embed
            src={`https://${baseUrl}${previewURL}`}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        );
      case "video":
        return (
          <video className="rounded-md object-fit max-h-full" controls>
            <source
              src={`https://${baseUrl}${previewURL}`}
              type={asset?.type}
            />
            Your browser does not support the video tag.
          </video>
        );
      default:
        return null;
    }
  };
  return (
    <React.Fragment>
      <ProgressLoader progress={progress} />
      <Header title={`Drips`} />

      <div className="hospitals-container">
        <div className="">
          {isPending ? (
            <LoadingButton
              visible={isPending}
              message="Fetching Drips... Please wait"
            />
          ) : (
            <DataTable
              columns={columns}
              data={transformedDrips}
              onClick={createDrips}
              btnTitle="ADD NEW DRIP"
            />
          )}
        </div>
      </div>

      <TransitionsModal
        open={isOpen}
        setOpen={setIsOpen}
        handleCloseModal={handleCloseModal}
        title="Add new drip"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: "10px",
        }}
        footer={
          <div className="flex items-center justify-center w-1/2 gap-3">
            <Btn
              className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-210 w-full active:translate-y-2 active:scale-95"
              disabled={form.formState.isSubmitting || isCreating}
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              {isCreating && (
                <ReloadIcon className="animate-spin mr-2 h-4 w-4" />
              )}
              {isCreating ? "Adding..." : "Add Drip"}
            </Btn>
          </div>
        }
      >
        <CreateDrips
          form={form}
          platformOptions={platformOptions}
          selectedPlatforms={selectedPlatforms}
          setAsset={setAsset}
          asset={asset}
          selectedDripType={selectedDripType}
        />
      </TransitionsModal>
      <TransitionsModal
        open={!!viewDrip}
        setOpen={setViewDrip}
        handleCloseModal={handleCloseViewDrip}
        handleBackdropClose={handleBackdropClose}
        title="Drip Information"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: "10px",
        }}
      >
        <>
          <div className="w-full border rounded-md">
            <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
              <div className="w-1/3 min-h-full">
                <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start rounded-tl-md">
                  Drip title:
                </p>
              </div>

              <div className="bg-white h-full w-full">
                <p className="px-4 py-3 flex-1 w-full text-start">
                  {viewDrip?.title}
                </p>
              </div>
            </div>
            <div className="flex items-start justify-between w-full border-b bg-[#A0A0A0A0]">
              <div className="w-1/3 min-h-full">
                <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start">
                  Description:
                </p>
              </div>
              <div className="bg-white h-full w-full">
                <p className="px-4 py-3 flex-1 w-full text-start">
                  {viewDrip?.description}
                </p>
              </div>
            </div>
            {viewDrip?.email && (
              <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
                <div className="w-1/3 min-h-full">
                  <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start">
                    Email:
                  </p>
                </div>
                <div className="bg-white h-full w-full">
                  <div className="px-4 py-3 flex-1 w-full text-start ">
                    <EmailContent htmlContent={viewDrip?.email} />
                  </div>
                </div>
              </div>
            )}
            {viewDrip?.message && (
              <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
                <div className="w-1/3 min-h-full">
                  <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start">
                    SMS:
                  </p>
                </div>
                <div className="bg-white h-full w-full">
                  <div className="px-4 py-3 flex-1 w-full text-start ">
                    {viewDrip?.message}
                  </div>
                </div>
              </div>
            )}
            {viewDrip?.whatsapp && (
              <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
                <div className="w-1/3 min-h-full">
                  <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start">
                    WhatsApp:
                  </p>
                </div>
                <div className="bg-white h-full w-full">
                  <div className="px-4 py-3 flex-1 w-full text-start ">
                    {viewDrip?.whatsapp}
                  </div>
                </div>
              </div>
            )}
            <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
              <div className="w-1/3 min-h-full">
                <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start">
                  Drip Type:
                </p>
              </div>

              <div className="bg-white h-full w-full">
                <p className="px-4 py-3 flex-1 w-full text-start">
                  {viewDrip?.type}
                </p>
              </div>
            </div>
            <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
              <div className="w-1/3 min-h-full">
                <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start">
                  Hospital value:
                </p>
              </div>
              <div className="bg-white h-full w-full">
                <p className="px-4 py-3 flex-1 w-full text-start">
                  {viewDrip?.value ?? "N/A"}
                </p>
              </div>
            </div>
            <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
              <div className="w-1/3 min-h-full">
                <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start">
                  Platform(s):
                </p>
              </div>

              <div className="bg-white h-full w-full">
                <p className="px-4 py-3 flex-1 w-full text-start">
                  {viewDrip?.platform?.split(",").join(", ")}
                </p>
              </div>
            </div>
            <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
              <div className="w-1/3 min-h-full">
                <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start">
                  Views:
                </p>
              </div>

              <div className="bg-white h-full w-full">
                <p className="px-4 py-3 flex-1 w-full text-start">
                  {viewDrip?.usage}
                </p>
              </div>
            </div>
            <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
              <div className="w-1/3 min-h-full">
                <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start rounded-bl-md">
                  Asset:
                </p>
              </div>

              <div className="bg-white h-full w-full">
                <div className="px-4 py-3 flex-1 w-full">
                  {renderPreview(
                    viewDrip?.type?.toLowerCase(),
                    viewDrip?.asset
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      </TransitionsModal>
    </React.Fragment>
  );
};

export default Drips;
