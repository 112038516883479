import React, { useCallback } from "react";

import DashboardTopRow from "../../../../components/blocks/dashboardTopRow";
import Header from "../../../../components/blocks/header";

import LeadershipBoard from "../../../../components/blocks/leadershipBoard";

import MarketingTop from "../../../../components/blocks/marketingTop";
import CustomerDashboard from "../customerDashboard";
import HeaderContainer from "../../../../components/blocks/HeaderContainer";
import { Focus } from "./components/focus";

import { useGlobalContext } from "../../../../contexts/globalContext";
import ActivityFeed from "../activities/activityFeed";
import ChurnInfoCard from "../churn/ChurnInfoCard";

import { getPrivileges } from "../../../../services/authService";

import { useGetHospitalsCount } from "../../../../hooks/useDashboard";

const Home = () => {
  const privileges = getPrivileges();
  const { user } = useGlobalContext();

  const getUserId = useCallback(
    (user: any) => {
      if (!privileges.createAgent) {
        return user;
      }
      return "";
    },
    [privileges.createAgent]
  );

  const countFilters = {
    user_id: getUserId(user?.id) as string,
  };

  const { count: fullChurnCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "full",
  });
  const { count: partialChurnCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "partial",
  });
  const { count: riskChurnCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "risk",
  });
  const { count: exitChurnCount } = useGetHospitalsCount({
    ...countFilters,
    churn_status: "in-exit",
  });

  return user?.position === "marketing" ? (
    <React.Fragment>
      <Header title={`Hello, ${user.username}`} />
      <MarketingTop />
    </React.Fragment>
  ) : user?.position === "customer_success" ? (
    <React.Fragment>
      <Header title={`Hello, ${user.username}`} />
      <CustomerDashboard />
    </React.Fragment>
  ) : user?.position === "sales_rep" ? (
    <React.Fragment>
      <Header title={`Hello, ${user?.username}`} />
      <HeaderContainer />
      <div className="flex flex-col justify-center items-center">
        <Focus />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Header title={`Hello, ${user?.username}`} />
      <div className="px-3">
        <DashboardTopRow />
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-3">
        <div className="w-full">
          <ActivityFeed pageSize={3} />
        </div>
        <div className="mt-3 lg:hidden">
          <ChurnInfoCard
            full={fullChurnCount}
            partial={partialChurnCount}
            risk={riskChurnCount}
            exit={exitChurnCount}
          />
        </div>
        <div className="bottom-row-breakdown">
          <div className="bottom-row-breakdown-right">
            <div className="bottom-row-breakdown-right-inner">
              <LeadershipBoard />
            </div>
            <div className="mt-3 hidden lg:block">
              <ChurnInfoCard
                full={fullChurnCount}
                partial={partialChurnCount}
                risk={riskChurnCount}
                exit={exitChurnCount}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
