import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useCompleteMergeAccounts } from "../../../hooks/agent/useAgent";
import { resetBaseUrl, updateBaseUrl } from "../../../services/httpService";

export default function MergeAccount() {
  const [searchParams] = useSearchParams();
  const action = searchParams.get("action") || "";
  const hospitalId = searchParams.get("hospital_id") || "";
  const mergeRef = searchParams.get("merge_ref_id") || "";

  const { mutateCompleteMergeAccounts, isPending, isError } =
    useCompleteMergeAccounts();

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const host = `${window.location.protocol}//${window.location.host}`;

    if (host === "https://one.lifebanktest.com") {
      updateBaseUrl(host as string);
    } else {
      resetBaseUrl(host as string);
    }

    const payload = {
      action: action,
      hospital_id: hospitalId,
      merge_ref_id: mergeRef,
    };
    mutateCompleteMergeAccounts(payload, {
      onSuccess: (res) => {
        setIsSuccess(res.data.success);
      },
    });
  }, [action, hospitalId, mergeRef, mutateCompleteMergeAccounts]);

  return (
    <div>
      <div className="mx-auto max-w-7xl p-20 text-center space-y-10">
        {isSuccess && (
          <>
            <p className="text-green-600 font-semibold">
              Your account has been successfully merged!
            </p>
            <p>You can close this window and login to your account</p>
            {/* <DidYouKnow /> */}
            {/* <Button
              //   onClick={() => router.push("/login")}
              variant="outline"
              className="border-atomRed text-atomRed mt-4"
            >
              GO TO LOGIN
            </Button> */}
          </>
        )}

        {(isError || !isSuccess) && !isPending && (
          <>
            <p className="text-red-600">
              Merging failed. Please try again or contact Support.
            </p>
            <div className="mt-4">
              {/* <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="max-w-xs mx-auto mb-2"
              />
              <Button onClick={handleResendActivationLink} disabled={loading}>
                {loading ? "Sending..." : "Resend Activation Link"}
              </Button> */}
            </div>
          </>
        )}

        {isPending && (
          <>
            <p>Merging your account...</p>
            {/* <DidYouKnow /> */}
          </>
        )}
      </div>
    </div>
  );
}
