import { useCallback, useEffect, useMemo, useState } from "react";
import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";
import { GoDash } from "react-icons/go";
import Search from "../../../../components/exodusComponent/Search";
import { useNavigate } from "react-router";
import LoadingButton from "../../../../components/common/loadingButton";

// import { useGetAllExodus } from "../../../../hooks/useExodus";
import { useGlobalContext } from "../../../../contexts/globalContext";
import applyFilters from "../../../../helpers/urlState";

import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { RiCloseLargeFill } from "react-icons/ri";
import ProductTable, {
  ProductTableColumn,
} from "../../../../components/common/ProductTable";
import { Hospital } from "../../../../ts-utils/types";
import { Link, useLocation } from "react-router-dom";
import { useGetAgentAdminHospitals } from "../../../../hooks/agent/useAgent";

const LogAContact = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [search, setSearch] = useState("");

  const { user } = useGlobalContext();

  const handleHospital = (item: any) => {
    navigate("/exodus/log-a-contact/contact", {
      state: {
        id: item.id,
        name: item?.name || "",
        address: item?.hospital_details?.address || "",
        averageMonthlySpent: item?.hospital_details?.average_spend || "",
        lastOrder: item?.hospital_details?.last_order_date || "",
      },
    });
  };

  const handleSearch = (e: any) => {
    setSearch(e);
    applyFilters(
      {
        page: 1,
        search: e,
        status,
      },
      navigate,
      "exodus/log-a-contact"
    );
  };

  const status = "exodus";
  const [currentPage, setCurrentPage] = useState(1);

  const pageSize: number = 10;
  const filters = {
    userId: user?.id as string,
    status: status,
    limit: pageSize,
    page: currentPage,
    name: search,
  };
  // const { allExodus } = useGetAllExodus({ ...filters }, "lite");
  // console.log(allExodus);

  const { hospitals, paginate, isPending } = useGetAgentAdminHospitals(filters);

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);

  const pathname = location.pathname.replace("/", "");

  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
          status,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search, status]
  );

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "id":
        return <p className="text-[#49209F] font-bold">{hospital?.id}</p>;
      case "name":
        return (
          <Link
            className="text-[#49209F]  underline font-bold"
            to={`/hospitals/${hospital?.id}`}
          >
            {hospital?.name}
          </Link>
        );
      case "":
        return (
          <button
            onClick={() => handleHospital(hospital)}
            className="text-[#49209F]  underline font-bold"
          >
            Process
          </button>
        );
      // case "channel":
      //   return <span>{hospital?.channel ?? "N/A"}</span>;
      default:
        // For boolean fields, show Yes/No
        return hospital[column.accessor as keyof Hospital] === "Yes" ? (
          <>
            <span className="text-green-600 font-semibold flex items-end md:items-center justify-end md:justify-center text-center">
              <IoCheckmarkDoneSharp />
            </span>
          </>
        ) : (
          <>
            {" "}
            <span className="text-red-500 flex items-end md:items-center justify-end md:justify-center text-center">
              <RiCloseLargeFill />
            </span>
          </>
        );
    }
  }

  const columns: ProductTableColumn[] = [
    { header: "ID", accessor: "id" },
    { header: "Hospital Name", accessor: "name" },
    { header: "Channel", accessor: "channel" },
    { header: "Blood", accessor: "has_blood_order" },
    { header: "Oxygen", accessor: "has_oxygen_order" },
    { header: "StockBank", accessor: "has_stockbank_order" },
    { header: "Quip", accessor: "has_quip_order" },
    { header: "Process", accessor: "" },
  ];

  // const getPaginatedData = (): PaginatedData => {
  //   const filtered = filteredHospitals;

  //   const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
  //   const paginated = utilities.paginate(sorted, currentPage, pageSize);

  //   return { totalCount: filtered?.length, paginated };
  // };

  // const { totalCount, paginated }: PaginatedData = getPaginatedData();

  // useEffect(() => {
  //   const pageFromQuery =
  //     Number(new URLSearchParams(window.location.search).get("page")) || 1;
  //   setCurrentPage(pageFromQuery);
  // }, [currentPage]);

  // const goToPage = (page: number) => {
  //   setCurrentPage(Math.min(Math.max(page, 1), totalCount));
  //   applyFilters(
  //     {
  //       page: Math.min(Math.max(page, 1), totalCount),
  //     },
  //     navigate,
  //     "exodus/log-a-contact"
  //   );
  // };
  return (
    <div>
      <ExodusHeader
        Text={"Log a contact"}
        subText={"| Exodus Arena > Log a contact"}
      />
      {isPending ? (
        <div className="flex flex-col justify-between items-center w-full p-5 sm:p-10">
          <LoadingButton
            visible={isPending}
            message="Fetching Data... Please wait"
          />
        </div>
      ) : (
        <>
          <div className="w-10/12 flex flex-row m-4 items-center">
            <p className="text-8xl text-[#49209F]">
              <GoDash />
            </p>
            <p className="text-2xl">Log a contact to any hospital in exodus</p>
          </div>
          <div className="w-10/12 flex mx-auto mb-4">
            <Search
              handleSearch={handleSearch}
              placeholder="Enter name of hospital"
            />
          </div>
          <>
            <div className="max-w-screen-2xl mx-auto px-4 md:px-8 mb-5">
              <>
                <ProductTable
                  data={hospitals}
                  columns={columns}
                  renderCell={renderCell}
                  totalPages={paginate?.totalPages}
                  currentPage={currentPage}
                  goToPage={goToPage}
                />
              </>
            </div>
          </>
          {/* <div className="w-10/12 mx-auto flex flex-col mb-4">
            <table className="shadow">
              <thead>
                <tr>
                  <th className="font-extrabold text-black text-[18px]">
                    Name of hospital
                  </th>
                  <th className="font-extrabold text-[18px] text-black">
                    Blood
                  </th>
                  <th className="font-extrabold text-[18px] text-black">
                    Oxygen
                  </th>
                  <th className="font-extrabold text-[18px] text-black">
                    StockBank
                  </th>
                  <th className="font-extrabold text-[18px] text-black">
                    Quip
                  </th>
                  <th className="font-extrabold text-black text-[18px]">
                    Process
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginated
                  ? paginated.map((item, index) => {
                      const products = new Set(
                        item.hospital_details.productlist
                          ? item.hospital_details.productlist
                              .split(",")
                              .map((p: string) => p.trim())
                          : []
                      );
                      return (
                        <tr key={index} className="cursor-pointer">
                          <td
                            className="underline text-[20px]"
                            onClick={() => handleNavigateHospital(item)}
                          >
                            {item?.hospital_details.name}
                          </td>

                          {["Blood", "Oxygen", "StockBank", "Quip"].map(
                            (product, idx) => (
                              <td key={idx} className="text-[20px]">
                                {products.has(product) ? (
                                  <span className="text-green-600 font-semibold">
                                    Yes
                                  </span>
                                ) : (
                                  <span className="text-red-500">No</span>
                                )}
                              </td>
                            )
                          )}

                          <td className=" text-left font-bold text-[20px]">
                            <button onClick={() => handleHospital(item)}>
                              Process
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  : []}
              </tbody>
            </table>
            <div className="">
              <CustomPagination
                goToPage={goToPage}
                currentPage={currentPage}
                totalPages={
                  Math.ceil(totalCount / pageSize) > 0
                    ? Math.ceil(totalCount / pageSize)
                    : 1
                }
              />
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};
export default LogAContact;
