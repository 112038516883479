import { useQuery, useMutation, keepPreviousData } from "@tanstack/react-query";
import dashboardService from "../services/dashboard.service";
import complaintService from "../services/complaintsService";
import { AxiosResponse } from "axios";
import { getPrivileges } from "../services/authService";

const permission = getPrivileges();

interface ResponseData {
  active: never[];
  onboarded: never[];
  targets: never[];
  created_hospitals: never[];
  data: any;
  meta: any;
}
// const initialBreakdown = {
//   active: [],
//   created_hospitals: [],
//   onboarded: [],
//   targets: [],
// };
interface Target {
  active_hospitals: string;
  onboarded_hospitals: string;
  total_hospitals: string;
  visited_hospitals: string;
  mon: string;
}

export const useGetComplaint = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
  hospital_id?: string;
}) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["complaint", filters.hospital_id, filters.page],
    // enabled: !!id,
    queryFn: async () => {
      const response = await complaintService.getComplaints(filters);
      return response?.data;
    },
    placeholderData: keepPreviousData,
  });
  const complaints = (isSuccess && data?.data) ?? [];
  const paginate = data?.meta;
  return { isError, isSuccess, complaints, isPending, paginate };
};
export const useGetOrderComplaint = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  title?: string;
  status?: string;
  ref_id?: string;
  assign?: string;
}) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["order-complaint", filters.ref_id, filters.page],
    // enabled: !!id,
    queryFn: async () => {
      const response = await complaintService.getOrderComplaints(filters);
      return response?.data;
    },
    placeholderData: keepPreviousData,
  });
  const orderComplaints = (isSuccess && data?.data) ?? [];
  const paginate = data?.meta;
  return { isError, isSuccess, orderComplaints, isPending, paginate };
};

export const useGetDashboard = (id: string | number) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["dashboard", id],
    queryFn: async () => {
      const response = (await dashboardService.dashboardData(
        id
      )) as AxiosResponse<ResponseData>;
      return response;
    },
    enabled: permission.exodus,

    // enabled: permission.exodus || permission.createAgent,
  });

  const dashboard: any = data?.data || [];

  // console.log(dashboard);

  return { isError, isSuccess, dashboard, isPending };
};

export const useGetDashboardData = (userId: string) => {
  // const [breakdown, setBreakdown] = useState(initialBreakdown);
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["dashboard-data", userId],
    queryFn: async () => {
      const response = await dashboardService.getData(userId);
      return response?.data;
    },
  });

  const dashboard = (isSuccess && data) || [];

  const newDashboard = {
    active: (dashboard as ResponseData)?.active || [],
    created_hospitals: (dashboard as ResponseData)?.created_hospitals || [],
    onboarded: (dashboard as ResponseData)?.onboarded || [],
    targets: (dashboard as ResponseData)?.targets || [],
  };

  // setBreakdown(dashboard);
  // Assign "N/A" or "0" to any null key value
  const processDashboardData = (items: any[]) => {
    return items
      ? items?.map((item) => ({
          num: item.num || "0",
          mon: item.mon || "0",
          yr: item.yr || "0",
        }))
      : [];
  };

  const processedActive = processDashboardData(
    (dashboard as ResponseData)?.active || []
  );
  const processedOnboarded = processDashboardData(
    (dashboard as ResponseData)?.onboarded || []
  );
  // const targets = (dashboard as ResponseData)?.targets || [];

  const getTargetForTheMonth = () => {
    const month = new Date().getMonth() + 1;
    return newDashboard?.targets.find(
      (target: { mon: string }) => target.mon === month.toString()
    );
  };

  const getMonthFromBreakdown = (arrayItems: any[]) => {
    const today = new Date();
    const currentMonth = arrayItems.find(
      (item: { mon: string; yr: string }) =>
        parseInt(item.mon) === today.getMonth() + 1 &&
        parseInt(item.yr) === today.getFullYear()
    );
    return currentMonth ? currentMonth.num : "0";
  };

  const getMonthData = () => {
    return {
      activeCurrent: getMonthFromBreakdown(processedActive),
      onboardedCurrent: getMonthFromBreakdown(processedOnboarded),
      totalCurrent: getMonthFromBreakdown(
        (dashboard as ResponseData)?.created_hospitals || []
      ),
      visitedCurrent: "0",
    };
  };

  const getTargetData = () => {
    const currentMonthTarget = getTargetForTheMonth() as unknown as Target;
    if (!currentMonthTarget) return {};

    const { activeCurrent, onboardedCurrent, totalCurrent, visitedCurrent } =
      getMonthData();

    const activeTarget = +currentMonthTarget.active_hospitals;
    const onboardedTarget = +currentMonthTarget.onboarded_hospitals;
    const totalTarget = +currentMonthTarget.total_hospitals;
    const visitedTarget = +currentMonthTarget.visited_hospitals;

    const getPercentage = (numerator: number, denominator: number) =>
      Math.floor((numerator / denominator) * 100);

    return {
      activeExceeded: parseInt(activeCurrent) >= activeTarget,
      onboardedExceeded: parseInt(onboardedCurrent) >= onboardedTarget,
      totalExceeded: parseInt(totalCurrent) >= totalTarget,
      visitedExceeded: parseInt(visitedCurrent) >= visitedTarget,
      activePercentage: getPercentage(parseInt(activeCurrent), activeTarget),
      onboardedPercentage: getPercentage(
        parseInt(onboardedCurrent),
        onboardedTarget
      ),
      totalPercentage: getPercentage(parseInt(totalCurrent), totalTarget),
      visitedPercentage: getPercentage(parseInt(visitedCurrent), visitedTarget),
    };
  };

  const targetData = getTargetData();
  const monthData = getMonthData();
  const targetForTheMonth = getTargetForTheMonth();

  return {
    isError,
    isSuccess,
    dashboard,
    isPending,
    targetData,
    monthData,
    targetForTheMonth,
  };
};

export const useGetFeed = (
  filters: {
    page?: number | string;
    limit?: number;
    name?: string;
    user_id?: string;
    status?: string;
    hospitalid?: string;
  },
  trigger: string
) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["feed", filters],
    enabled: !!trigger,
    queryFn: async () => {
      const response = await dashboardService.getFeed(filters);
      return response?.data;
    },
    placeholderData: keepPreviousData,
  });

  const feed = (isSuccess && data?.data) || [];
  const paginate: any = data?.meta;

  return { isError, isSuccess, feed, paginate, isPending };
};
export const useGetSingleFeed = (user_id?: string) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["single-feed", user_id],
    enabled: !!user_id,
    queryFn: async () => {
      const response = await dashboardService.getSingleFeed(user_id);
      return response?.data;
    },
    placeholderData: keepPreviousData,
  });

  const singleFeed = (isSuccess && data?.data) || [];
  const paginate: any = data?.meta;

  return { isError, isSuccess, singleFeed, paginate, isPending };
};

export const useGetAgentRevenue = (userId: string, date_range: string) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["revenue", userId, date_range],
    queryFn: async () => {
      const response = await dashboardService.getAgentRevenue(
        userId,
        date_range
      );
      return response?.data;
    },
  });
  const revenue = (isSuccess && data?.data) || [];

  return { isError, isSuccess, revenue, isPending };
};

export const useGetTargetRevenue = (userId: string) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["performance", userId],
    queryFn: async () => {
      const response = await dashboardService.getTargetRevenue(userId);
      return response?.data;
    },
  });

  const target = (isSuccess && data?.data) || [];

  return { isError, isSuccess, target, isPending };
};

export const useGetTotalHospitals = (userId?: string) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["totalHospitals", userId],
    queryFn: async () => {
      const response = await dashboardService.getTotalHospitals(userId);
      return response?.data;
    },
  });

  const totalHospitals = (isSuccess && data?.meta?.totalItems) || [];

  return { isError, isSuccess, totalHospitals, isPending };
};

export const useSetTarget = () => {
  const {
    isError,
    isSuccess,
    mutate: mutateSetTarget,
    isPending,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await dashboardService.setTarget(data);
      return response;
    },
    onError: (e: { response: { data: { message: string } } }) => {},
  });

  return { isError, isSuccess, mutateSetTarget, isPending };
};

export const useGetCities = (country: string) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["cities", country],
    queryFn: async () => {
      const response = await dashboardService.getCities(country);
      return response;
    },
  });

  const cities = (isSuccess && data?.data?.data?.states) || [];

  return { isError, isSuccess, cities, isPending };
};
export const useGetHospitalsCount = (filters: {
  user_id?: string;
  status?: string;
  churn_status?: string;
  churn_exit_type?: string;
  health_status?: string;
}) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["hospital-count", filters],
    queryFn: async () => {
      const response = await dashboardService.getHospitalsCount(filters);
      return response;
    },
  });

  const count = data?.data?.data;

  return { isError, isSuccess, count, isPending };
};
export const useGetHospitalsOrderCount = (
  userId: string,
  date_range: string
) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["order-count", userId, date_range],
    queryFn: async () => {
      const response = await dashboardService.getHospitalsOrderCount(
        userId,
        date_range
      );
      return response;
    },
  });

  const orderCount = data?.data?.data;

  return { isError, isSuccess, orderCount, isPending };
};
