import { Link } from "react-router-dom";

const ChurnInfoCard = ({
  full,
  partial,
  risk,
  exit,
}: {
  full: number;
  partial: number;
  risk: number;
  exit: number;
}) => {
  const churnTypes = [
    { label: "Full Churn", value: full ?? 0, filter: "full" },
    { label: "Partial Churn", value: partial ?? 0, filter: "partial" },
    { label: "Churn Risk", value: risk ?? 0, filter: "risk" },
    { label: "In Exit", value: exit ?? 0, filter: "exit" },
  ];

  return (
    <div className="rounded-lg border border-gray-200 bg-white hover:shadow-lg p-6">
      <h2 className="text-lg font-bold text-gray-800 mb-4">
        Churn Information
      </h2>
      <div className="space-y-3">
        {churnTypes.map((type, index) => (
          <Link
            to={`/full-churn?status=${type.filter}&page=1`}
            key={index}
            className="flex justify-between"
          >
            <span className="font-semibold text-gray-700">{type.label}</span>
            <div className="text-gray-600">{type.value} hospitals</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ChurnInfoCard;
