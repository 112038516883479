import React, { useCallback, useEffect, useMemo, useState } from "react";

import Header from "../../../../components/blocks/header";
import SearchQuery from "../../../../components/forms/searchQuery";

import * as Dialog from "@radix-ui/react-dialog";
import { useGlobalContext } from "../../../../contexts/globalContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import applyFilters from "../../../../helpers/urlState";
import ProductTable, {
  ProductTableColumn,
} from "../../../../components/common/ProductTable";
import { Hospital } from "../../../../ts-utils/types";
import { SyncLoader } from "react-spinners";
import ReactSelect from "../../../../components/common/ReactSelect";
import {
  useAcceptReserveRequest,
  useAssignAgent,
  useGetReservedHospitals,
  useGetSalesAgent,
} from "../../../../hooks/agent/useAgent";
import { Button } from "../../../../@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { Equal, Handshake, MoreVertical } from "lucide-react";
import toastService from "../../../../services/toastService";
import { Renderable, Toast, ValueFunction } from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

const filterStatus = [
  {
    value: "",
    label: "All",
  },
  {
    value: "accepted",
    label: "Accepted",
  },

  {
    value: "pending",
    label: "Pending",
  },
];
const ReservedList = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { user } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");

  const pageSize: number = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState<string>(
    searchParams.get("status") || ""
  );
  const [search, setSearch] = useState(searchParams.get("search") || "");

  const filters = {
    user_id: user?.id as string,
    limit: pageSize,
    page: currentPage,
    name: search,
    status: status,
  };
  const { reservedHospitals, isPending, paginate } =
    useGetReservedHospitals(filters);

  const { mutateAcceptReserveRequest, isPending: isAccepting } =
    useAcceptReserveRequest();

  const onAcceptReserveRequest = (itemId: string) => {
    const payload = {
      id: itemId,
      admin_id: user?.id,
    };
    mutateAcceptReserveRequest(payload);
  };
  const [clickedRowId, setClickedRowId] = useState<number | string | null>(
    null
  );

  const { mutateAssignAgent, isPending: isAssignPending } = useAssignAgent();
  const [selectedAgent, setSelectedAgent] = useState<string>("");

  const [assign, setAssign] = useState<{
    hospital_id: string;
    name: string;
    username: string;
    state: string;
  } | null>(null);
  const [open, setOpen] = useState(false);
  const handleAssignAgent = (item: any) => {
    setAssign(item);
    setOpen(true);
  };

  const { mappedAgents } = useGetSalesAgent(assign?.state as string);

  const filterAgents = useMemo(
    () =>
      mappedAgents
        ?.filter((agent: { position: string }) =>
          ["sales_rep", "sales_lead"].includes(agent.position)
        )
        .map((agent: { id: string; username: string }) => {
          const nameParts = agent.username.split(/(?=[A-Z])/);
          return {
            id: agent.id,
            name:
              nameParts.length > 1
                ? `${nameParts[0]} ${nameParts[1]}`
                : agent.username,
          };
        })
        .filter((v: { id: string; name: string }, i: number, a: any[]) => {
          return a.findIndex((t: { id: string }) => t.id === v.id) === i;
        })
        .filter((v: { name: string }) => v.name !== null)
        .sort((a: { name: string }, b: { name: string }) =>
          a.name.localeCompare(b.name)
        ) || [],
    [mappedAgents]
  );

  const onAssignAgent = () => {
    const payload = {
      agent_id: selectedAgent,
      hospital_id: assign?.hospital_id,
      pitched: 0,
    };

    mutateAssignAgent(payload, {
      onSuccess: (data: {
        data: { message: Renderable | ValueFunction<Renderable, Toast> };
      }) => {
        toastService.success(data?.data?.message);
        queryClient.refetchQueries({
          queryKey: ["reserve"],
        });
        setOpen(false);
      },
      onError: () => {
        setOpen(false);
      },
    });
  };

  const handleSelectAgent = (item: any) => {
    setSelectedAgent(item);
  };

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "name":
        return (
          <>
            {hospital?.id ? (
              <Link
                className="text-[#49209F]  underline font-bold"
                to={`/hospitals/${hospital?.id}`}
              >
                {hospital?.name ?? "N/A"}
              </Link>
            ) : (
              <> {hospital?.name ?? "N/A"}</>
            )}
          </>
        );
      case "tym":
        const date = hospital[column.accessor]
          ? new Date(hospital[column.accessor] * 1000)
          : null;

        return (
          <span>
            {date
              ? `${date.getDate().toString().padStart(2, "0")}-${(
                  date.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${date.getFullYear()}`
              : "N/A"}
          </span>
        );
      case "status":
        return (
          <span
            style={{
              backgroundColor:
                hospital[column.accessor] === "accepted"
                  ? "#4CAF50"
                  : hospital[column.accessor] === "good"
                  ? "#3B82F6"
                  : hospital[column.accessor] === "close"
                  ? "#f44336"
                  : hospital[column.accessor] === "pending"
                  ? "#FFD700"
                  : "#f44336",
              color: "white",
              padding: "5px 10px",
              borderRadius: "20px",
              display: "inline-block",
              textTransform: "uppercase",
            }}
          >
            {hospital[column.accessor] ?? "N/A"}
          </span>
        );
      case "action":
        return (
          <>
            {hospital.status !== "accepted" && (
              <div>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="h-8 w-8 p-0">
                      <span className="sr-only">Open menu</span>
                      <MoreVertical className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    {hospital.status === "pending" &&
                    hospital.reassignment_count < 2 ? (
                      <>
                        <DropdownMenuItem
                          className="cursor-pointer"
                          onClick={() => {
                            handleAssignAgent(hospital);
                          }}
                        >
                          <Equal className="mr-2 h-4 w-4" /> Re-Assign
                        </DropdownMenuItem>
                      </>
                    ) : hospital.status === "pending" &&
                      hospital.reassignment_count === 2 ? (
                      <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => {
                          onAcceptReserveRequest(hospital.id);
                          setClickedRowId(hospital.id);
                        }}
                      >
                        <Handshake className="mr-2 h-4 w-4" />
                        {isAccepting && hospital.id === clickedRowId
                          ? "Accepting..."
                          : "Accept"}
                      </DropdownMenuItem>
                    ) : null}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            )}
          </>
        );

      default:
        return <span>{hospital[column.accessor] ?? "N/A"}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Hospital", accessor: "name" },
    { header: "Assigned", accessor: "reassignment_count" },
    { header: "Status", accessor: "status" },
    { header: "Date", accessor: "created_at", className: "text-nowrap" },

    { header: "Agent", accessor: "username", className: "max-w-32 w-32" },
    { header: "", accessor: "action" },
  ];

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);

  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search]
  );

  const handleStatusChange = (status: React.SetStateAction<string>) => {
    setStatus(status);
    navigate(`?status=${status}&page=${currentPage}`, {
      replace: true,
    });
  };

  const handleSearch = (e: any) => {
    setSearch(e);
    applyFilters(
      {
        page: 1,
        search: e,
        status,
      },
      navigate,
      pathname
    );
  };
  return (
    <React.Fragment>
      <Header title="Reserved Hospitals" />

      {isPending ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isPending}
            className="relative"
            size={30}
          />
        </div>
      ) : (
        <div className="hospitals-container">
          <div className="hospitals-container-top">
            <SearchQuery
              name="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => handleSearch(e.currentTarget.value)}
            />

            <div className="right-options">
              <ReactSelect
                placeholder="Filter by Status"
                // defaultValue={status}
                options={filterStatus?.map(
                  (status: { value: string; label: string }) => ({
                    value: status.value,
                    label: status.label,
                  })
                )}
                onChange={handleStatusChange}
              />
            </div>
          </div>

          <div className="hospitals-container-bottom table-responsive mb-5">
            <>
              <ProductTable
                data={reservedHospitals ?? []}
                columns={columns}
                renderCell={renderCell}
                totalPages={paginate?.totalPages}
                currentPage={currentPage}
                goToPage={goToPage}
                noDataMessage={
                  "You Currently don't have any Reserved hospitals"
                }
              />
            </>
          </div>
        </div>
      )}

      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4"
          >
            <div className="bg-white rounded-md shadow-lg px-4 py-6">
              <div className="flex items-center justify-end">
                <Dialog.Close className="p-2 text-gray-400 rounded-md hover:bg-gray-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <div className="max-w-sm mx-auto space-y-3 ">
                <Dialog.Title className="text-2xl font-bold text-gray-800 text-center ">
                  Assign Agent
                </Dialog.Title>

                <div className="space-y-5">
                  <p className="flex justify-between">
                    <span className="font-semibold inline-block">
                      Hospital Name:
                    </span>
                    <span className="inline-block">{assign?.name}</span>
                  </p>

                  <p className="flex justify-between">
                    <span className="font-semibold inline-block">
                      Current Agent:
                    </span>
                    <span className="inline-block">
                      {assign?.username ?? "N/A"}
                    </span>
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-semibold inline-block">
                    Select New Agent:
                  </span>
                  <div>
                    <ReactSelect
                      placeholder="Select New Agent"
                      options={filterAgents?.map(
                        (hospital: { id: string; name: string }) => ({
                          value: hospital.id,
                          label: hospital.name,
                        })
                      )}
                      onChange={handleSelectAgent}
                    />
                  </div>
                </div>
                <Button
                  disabled={isAssignPending}
                  onClick={onAssignAgent}
                  className=" w-full mt-3 py-3 px-4 font-medium text-sm text-center text-white primary hover:opacity-80   rounded-lg"
                >
                  Assign
                </Button>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </React.Fragment>
  );
};

export default ReservedList;
