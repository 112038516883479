import React, { useCallback, useEffect, useMemo, useState } from "react";

import LoadingButton from "../../../../components/common/loadingButton";

import { useGlobalContext } from "../../../../contexts/globalContext";
import applyFilters from "../../../../helpers/urlState";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useGetFeed,
  //  useGetSingleFeed
} from "../../../../hooks/useDashboard";
import ProductTable, {
  ProductTableColumn,
} from "../../../../components/common/ProductTable";
import { getPrivileges } from "../../../../services/authService";
import { Hospital } from "../../../../ts-utils/types";
import DateCell from "../../../../components/DateCell";
// import ActivityHistory from "../../../../components/modals/HistoryModal";
// import RequestTable from "../hospitals/RequestHistory";

const ActivityFeed = ({ pageSize = 10 }: { pageSize?: number }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");
  const [currentPage, setCurrentPage] = useState(1);
  // const [selectedFeed, setSelectedFeed] = useState("");
  // const [activityHistory, openActivityHistory] = useState(false);

  const privileges = getPrivileges();
  const { user } = useGlobalContext();
  const getUserId = useCallback(
    (user: any) => {
      if (!privileges.createAgent) {
        return user;
      }
      return "";
    },
    [privileges.createAgent]
  );

  // const [status, setStatus] = useState<string>(
  //   searchParams.get("status") || ""
  // );
  // const [search, setSearch] = useState(searchParams.get("search") || "");

  const filters = {
    user_id: getUserId(user?.id) as string,
    limit: pageSize,
    page: currentPage,
    // name: search,
    // status: status,
  };

  const { feed, isPending, paginate } = useGetFeed(filters, "activity");
  // const { singleFeed, isPending: isSingleFeed } =
  //   useGetSingleFeed(selectedFeed);

  // const handleOpenHistory = (id: string) => {
  //   setSelectedFeed(id);
  //   openActivityHistory(true);
  // };

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      // case "name":
      //   return (
      //     <>
      //       {hospital?.event?.toLowerCase().includes("notified")
      //         ? `${hospital.name} ${hospital.event}`
      //         : hospital?.event?.toLowerCase().includes("cadre")
      //         ? `${hospital.name} ${hospital.event} by ${hospital.agent_username}`
      //         : hospital.agent_username
      //         ? `${hospital.name} has been ${hospital.event}
      //       ${hospital?.event?.toLowerCase() === "assigned" ? "to" : "by"}
      //       ${hospital.agent_username}`
      //         : `${hospital.name} has been ${hospital.event}`}
      //     </>
      //   );
      case "name":
        return (
          <>
            {hospital?.id ? (
              <Link
                className="text-[#49209F]  underline font-bold"
                to={`/hospitals/${hospital?.id}`}
              >
                {hospital?.name ?? "N/A"}
              </Link>
            ) : (
              <> {hospital?.name ?? "N/A"}</>
            )}
          </>
        );
      case "date":
        return (
          <DateCell
            dateString={hospital[column.accessor]}
            format="datetime-12"
          />
        );
      case "status":
        return (
          <button
            disabled
            className={"status " + hospital?.status?.toLowerCase?.()}
          >
            {hospital.status}
          </button>
        );
      case "note":
        return <div>{hospital.note ?? hospital.event}</div>;

      // case "action":
      //   return (
      //     <>
      //       {hospital.user_id && hospital.user_id !== "0" ? (
      //         <Button
      //           variant={"outline"}
      //           onClick={() => {
      //             handleOpenHistory(hospital.user_id);
      //           }}
      //         >
      //           <Eye className="mr-2 h-4 w-4" /> View
      //         </Button>
      //       ) : (
      //         "No History"
      //       )}
      //     </>
      //   );

      default:
        return <span>{hospital[column.accessor] ?? "N/A"}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Hospital", accessor: "name" },
    { header: "Activity", accessor: "note" },
    { header: "Agent", accessor: "agent_username" },
    // { header: "Status", accessor: "status" },
    { header: "Date", accessor: "date", className: "text-nowrap" },
    // { header: "History", accessor: "action", className: "text-nowrap" },
  ];

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);

  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname]
  );

  // const historyColumns = [
  //   { label: "Date/Time", key: "date" },
  //   { label: "Hospital Name", key: "name" },
  //   { label: "Activity", key: "activity" },
  //   { label: "Agent", key: "username" },
  //   { label: "Status", key: "status" },
  // ];

  return (
    <div className={isPending ? "loading-background" : "activity-feed fade-in"}>
      <LoadingButton visible={isPending} message="Fetching Activities" />

      <header>
        <h1>History</h1>
      </header>
      <>
        <ProductTable
          data={feed ?? []}
          columns={columns}
          renderCell={renderCell}
          totalPages={paginate?.totalPages}
          currentPage={currentPage}
          goToPage={goToPage}
          noDataMessage={"You Currently don't have any History Feeds"}
        />
      </>
      {/* 
      <ActivityHistory
        pointHistory={activityHistory}
        openPointHistory={openActivityHistory}
        title="Activity History"
      >
        <RequestTable
          isLoading={isSingleFeed}
          data={singleFeed ?? []}
          columns={historyColumns}
        />
      </ActivityHistory> */}
    </div>
  );
};

export default ActivityFeed;
