import React, { useState } from "react";

import OptimizedRequestInfo, {
  PitchFormData,
  pitchFormSchema,
} from "./optimized";
import {
  useCloseAppointment,
  useGetSinglePendingRequest,
  useReserveAppointment,
  useScheduleAppointment,
} from "../../../../../hooks/sales/useAppointment";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useGlobalContext } from "../../../../../contexts/globalContext";
import { useLocation } from "react-router-dom";
import LogExpenses from "../../../../../components/modals/LogExpenses";
interface LogData {
  location: string;
  address: string;
}
const RequestInfo = () => {
  const { user } = useGlobalContext();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const request_id = searchParams.get("id");

  const { pitchInfo, isPending } = useGetSinglePendingRequest(
    request_id as string
  );

  const { mutate, isPending: isClosing } = useCloseAppointment();
  const { mutateReserve, isPending: isReserving } = useReserveAppointment();
  const { mutateSchedule } = useScheduleAppointment();

  const [logData, setLogData] = useState<LogData | null>(null);
  const [createExpense, setCreateExpense] = useState(false);

  const handleLocationData = (location: LogData) => {
    setLogData(location);
  };
  const form = useForm<PitchFormData>({
    resolver: zodResolver(pitchFormSchema),
    defaultValues: {
      summary: "",
      grade: "1",
      key: "Yes",
    },
  });

  const { watch } = form;

  const summary = watch("summary");

  const objectionType = watch("objectionType");
  const offerType = watch("offer_status");

  const isReserved = offerType === "No" || objectionType === "No";

  const onSubmit = (data: PitchFormData) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append(key, value);
      } else if (value !== undefined && value !== null && value !== "") {
        formData.append(key, value.toString());
      }
    });

    if (pitchInfo.id) {
      formData.append("id", pitchInfo.id);
    }

    if (logData?.location) {
      formData.append("location", logData.location as string);
    }

    if (isReserved) {
      const payload = {
        agent_id: pitchInfo?.user_id,
        request_by: user?.id,
        hospital_id: pitchInfo?.hospital_id,
      };
      mutateReserve(payload, {
        onSuccess: () => {
          setCreateExpense(true);
        },
      });
    } else {
      mutate(formData, {
        onSuccess: () => {
          const scheduleData = {
            hospital_id: pitchInfo?.hospital_id,
            orders: "",
            when: "",
            frequency: "",
            amount: "",
          };
          mutateSchedule(scheduleData);
          setCreateExpense(true);
        },
      });
    }
  };

  if (isPending) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <OptimizedRequestInfo
      hasHeader
        form={form}
        onSubmit={onSubmit}
        pitchInfo={pitchInfo}
        handleLocationData={handleLocationData}
      />

      <LogExpenses
        note={summary}
        destinationGeoCode={logData?.location as string}
        destinationAddress={
          pitchInfo?.address
            ? pitchInfo?.address
            : logData?.address
            ? (logData?.address as string)
            : null
        }
        createExpense={createExpense}
        setCreateExpense={setCreateExpense}
        hospital_id={pitchInfo?.hospital_id}
      />
    </div>
  );
};

export default RequestInfo;
