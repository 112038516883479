import React, { useEffect, useMemo, useState } from "react";
import { useGetAgentAdminHospitals } from "../../../../hooks/agent/useAgent";
import { useGlobalContext } from "../../../../contexts/globalContext";
import ReactSelect from "../../../../components/common/ReactSelect";
import Header from "../../../../components/blocks/header";
import { useGetQuotations } from "../../../../hooks/purchasingOrder/usePurchaseOrder";
import QuotationCard from "./QuotationCard";
import { useLocation, useNavigate } from "react-router-dom";
import { cn } from "../../../../@/lib/utils";
import { useDispatch } from "react-redux";
import {
  addHospitalId,
  setQuotationType,
} from "../../../../redux/slices/Quotations";
import { SyncLoader } from "react-spinners";

export default function Quotation() {
  const dispatch = useDispatch();
  const { user } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get("tab") || "stockbank";
  const hospital = queryParams.get("hospital") || "";
  const page = queryParams.get("page") || "1";
  const [selectedTab, setSelectedTab] = useState("stockbank");
  const [selectedHospital, setSelectedHospital] = useState(hospital);
  const [selectedHospitalName, setSelectedHospitalName] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const [itemsPerPage, setItemsPerPage] = useState(4); // For pagination

  const filter = {
    hospital_ref: selectedHospital,
    type: selectedTab,
  };
  const { quotations, isPending } = useGetQuotations(filter);
  const { hospitals } = useGetAgentAdminHospitals({
    page: 1,
    limit: 100000,
    userId: user?.id as string,
  });

  // const hospitalsWithRefId = useMemo(
  //   () =>
  //     hospitals?.filter((hospital: { ref_id: string }) => hospital.ref_id) ||
  //     [],
  //   [hospitals]
  // );

  const hospitalsWithRefId = useMemo(
    () =>
      hospitals?.filter(
        (hospital: { ref_id: string }) =>
          hospital.ref_id && hospital.ref_id !== "0"
      ) || [],
    [hospitals]
  );

  useEffect(() => {
    setSelectedTab(tab);
    setSelectedHospital(hospital);
    setCurrentPage(Number(page)); // Update page number
  }, [hospital, location.search, page, tab]);

  const handleTabChange = (tab: React.SetStateAction<string>) => {
    setSelectedTab(tab);
    navigate(`?tab=${tab}&hospital=${selectedHospital}&page=${currentPage}`, {
      replace: true,
    });
  };

  const handleHospitalChange = (hospital: React.SetStateAction<string>) => {
    setSelectedHospital(hospital);
    navigate(`?tab=${selectedTab}&hospital=${hospital}&page=${currentPage}`, {
      replace: true,
    });
  };

  useEffect(() => {
    if (hospitalsWithRefId && hospitalsWithRefId.length > 0) {
      if (selectedHospital) {
        const hospitalName = hospitalsWithRefId.find(
          (hosp: { ref_id: string }) => hosp.ref_id === selectedHospital
        );

        setSelectedHospitalName(hospitalName?.name || "Unknown Hospital");
        dispatch(addHospitalId(selectedHospital));
        dispatch(setQuotationType(selectedTab));
        navigate(
          `?tab=${selectedTab}&hospital=${selectedHospital}&page=${currentPage}`,
          {
            replace: true,
          }
        );
      } else {
        setSelectedHospital(hospitalsWithRefId[0].ref_id);
        navigate(
          `?tab=${selectedTab}&hospital=${hospitalsWithRefId[0].ref_id}&page=${currentPage}`,
          {
            replace: true,
          }
        );
      }
    }
  }, [
    hospitalsWithRefId,
    selectedHospital,
    selectedTab,
    currentPage,
    navigate,
    dispatch,
  ]);

  const handleOnClick = (id: string, status: string) => {
    navigate(`/quotations/details/${id}/${status}`);
  };

  const handleTrackClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any
  ) => {
    event.stopPropagation();

    navigate(`/order/track/${item.order_by}/${item.id}/${item.quipOrStock}`);
    // Add logic for "Track" button click here
  };

  const handlePageChange = (newPage: React.SetStateAction<number>) => {
    setCurrentPage(newPage);
    navigate(
      `?tab=${selectedTab}&hospital=${selectedHospital}&page=${newPage}`,
      { replace: true }
    );
  };

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedQuotations = quotations?.slice(startIndex, endIndex) || [];

  return (
    <div className="min-h-screen">
      <div className="w-full">
        <Header
          title={`${selectedHospitalName}  ${
            selectedTab === "stockbank" ? "Consumables" : "Equipments"
          } Quotations`}
        />
      </div>
      <div className="flex flex-col items-start justify-center">
        <h2 className="text-xl font-bold mb-1">Select Hospital</h2>

        <ReactSelect
          placeholder="Select a Hospital"
          defaultValue={selectedHospital}
          options={hospitalsWithRefId?.map(
            (hospital: { ref_id: string; name: string | number }) => ({
              value: hospital.ref_id,
              label: hospital.name,
            })
          )}
          onChange={handleHospitalChange}
        />

        <div className="flex flex-wrap space-x-4 mt-5 w-full">
          <button
            onClick={() => handleTabChange("stockbank")}
            className={`p-2 flex-1 rounded-md ${
              selectedTab === "stockbank"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
          >
            Consumables
          </button>
          <button
            onClick={() => handleTabChange("quip")}
            className={`p-2 flex-1 rounded-md ${
              selectedTab === "quip" ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
          >
            Quip
          </button>
        </div>

        {isPending ? (
          <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
            <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
            <SyncLoader
              color="#3B82F6"
              loading={isPending}
              className="relative"
              size={30}
            />
          </div>
        ) : (
          <div className="w-full h-full grid md:grid-cols-2 gap-4 mt-5">
            {paginatedQuotations.length > 0 ? (
              paginatedQuotations
                ?.sort(
                  (a: { tym: number }, b: { tym: number }) => b.tym - a.tym
                )
                .sort((a: { status: string }, b: any) =>
                  a.status === "ready" ? -1 : 1
                )
                .map(
                  (item: {
                    id: string;
                    status: string;
                    time: string;
                    total: string;
                    validity: string;
                    tym: string;
                  }) => (
                    <QuotationCard
                      key={item.id}
                      id={item.id}
                      status={item.status}
                      date={item.time}
                      total={item.total}
                      validity={item.validity}
                      tym={item.tym}
                      onClick={() => handleOnClick(item.id, item.status)}
                      handleTrackClick={(e) => handleTrackClick(e, item)}
                    />
                  )
                )
            ) : (
              <p className="text-2xl text-center flex justify-center h-80 items-center col-span-full">
                No quotation, Please send for a quotation
              </p>
            )}
          </div>
        )}

        {/* Pagination Controls */}
        <div className="flex justify-between items-center mt-5 w-full">
          <div className="">
            <label htmlFor="itemsPerPage" className="mr-2">
              Quotations per page:
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={(e) => {
                const newItemsPerPage = Number(e.target.value);
                setItemsPerPage(newItemsPerPage);
                if (
                  currentPage > Math.ceil(quotations?.length / newItemsPerPage)
                ) {
                  setCurrentPage(1);
                }
              }}
            >
              <option value="4">4</option>
              <option value="8">8</option>
              <option value="12">12</option>
            </select>
          </div>
          <div>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={cn(
                "p-2 rounded-md bg-gray-200 disabled:opacity-30",
                currentPage > 1 && "bg-blue-400 text-white"
              )}
            >
              Prev
            </button>
            <span className="mx-4">{currentPage}</span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={endIndex >= quotations?.length}
              className={cn(
                "p-2 rounded-md bg-gray-200 disabled:opacity-30",
                endIndex < quotations?.length && "bg-blue-400 text-white"
              )}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
