import React, { useEffect, useState } from "react";
import { GoDash } from "react-icons/go";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import { FaExclamationCircle } from "react-icons/fa";
import RadioButton from "../../../../components/exodusComponent/RadioButton";
import ExodusButton from "../../../../components/exodusComponent/ExodusButton";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { PiTelegramLogoThin } from "react-icons/pi";
import Modal from "../../../../components/exodusComponent/Modal";
import ExodusLoading from "../../../../components/exodusComponent/ExodusLoding";

import { success, error } from "../../../../services/toastService";

// import Tooltip from "@mui/material/Tooltip";

import { useGetDrip, usePostDrip } from "../../../../hooks/useExodus";
import EmailContent from "../drips/EmailContent";
import { useGlobalContext } from "../../../../contexts/globalContext";
import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";
import TransitionsModal from "../../../../components/modals/mod/CustomModal";
import { ParticularDetails } from "../../../../../types";
import { useGetAgentAdminHospitals } from "../../../../hooks/agent/useAgent";
import ReactSelect from "../../../../components/common/ReactSelect";
import { Button } from "../../../../@/components/ui/button";

const SendADrip = () => {
  const { user } = useGlobalContext();

  const { drip, baseUrl } = useGetDrip();

  const status = "exodus";
  const pageSize: number = 10000;

  const { hospitals } = useGetAgentAdminHospitals({
    page: 1,
    limit: pageSize,
    status,
    userId: user?.id as string,
  });

  const { mutatePostDrip, isPending } = usePostDrip();
  const [selectedHospital, setSelectedHospital] = useState<string>("");
  const [selectedHospitalId, setSelectedHospitalId] = useState<string>(
    "" as any
  );
  const [selectedPurpose, setSelectedPurpose] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [selectedLink, setSelectedLink] = useState<string | null>(null);
  const [selectedDripId, setSelectedDripId] = useState<string>("" as any);
  const [selectedDescription, setSelectedDescription] = useState() as any;
  const [selectedMessage, setSelectedMessage] = useState<string | null>(null);
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const [selectedWhatsApp, setSelectedWhatsApp] = useState<string | null>(null);

  const [selectedHospitalEmail, setSelectedHospitalEmail] = useState<
    string | null
  >(null);
  const [selectedHospitalPhone, setSelectedHospitalPhone] = useState<
    string | null
  >(null);
  const [selectedChannel, setSelectedChannel] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");
  const [availablePlatforms, setAvailablePlatforms] = useState<string[]>([]);
  const [purposeError, setPurposeError] = useState("") as any;
  const [hospitalError, setHospitalError] = useState("") as any;
  const [chanelError, setChanelError] = useState("") as any;
  const [isMobile, setIsMobile] = useState(false);
  const [viewDrip, setViewDrip] = useState<any>(null);

  // function extractAndSanitizeLink(message: string) {
  //   // Extract the URL part from the input string and handle any trailing ">"
  //   const urlPattern = /<img\s+src=['"]([^'"]+)['"]>/;
  //   const match = message.match(urlPattern);

  //   if (match && match[1]) {
  //     let url = match[1];

  //     // Remove any characters that are not part of the URL
  //     url = url.replace(/[^a-zA-Z0-9./:-]/g, "");

  //     // Add https:// to the start of the URL if it's not already there
  //     if (!url.startsWith("http://") && !url.startsWith("https://")) {
  //       url = "https://" + url;
  //     }

  //     // Replace the original <img> tag with the sanitized URL
  //     const sanitizedMessage = message.replace(urlPattern, url);

  //     return sanitizedMessage;
  //   } else {
  //     throw new Error("No valid URL found in the input message");
  //   }
  // }
  const [sms, setSms] = useState("");
  const [message, setMessage] = useState("");
  const [sanitizedMessage, setSanitizedMessage] = useState("");
  const [sanitizedUrl, setSanitizedUrl] = useState("");
  const [fileType, setFileType] = useState("");

  function extractAndSanitizeLink(message: string) {
    if (!message) {
      error("No message found in the input");
      return;
    }
    const urlPattern = /<img\s+src=['"]([^'"]+)['"]>/;
    const match = message.match(urlPattern);

    if (match && match[1]) {
      let url = match[1];
      url = url.replace(/[^a-zA-Z0-9./:-]/g, "");

      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "https://" + url;
      }

      const extension = url.split(".").pop()?.toLowerCase() || "";
      let fileType;
      if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
        fileType = "image";
      } else if (["mp4", "webm", "ogg"].includes(extension)) {
        fileType = "video";
      } else if (extension === "pdf") {
        fileType = "pdf";
      } else {
        fileType = "unknown";
      }

      // const sanitizedMessage = message.replace(
      //   urlPattern,
      //   `<img src="${url}">`
      // );

      const sanitizedMessage = message.split(urlPattern)[0].trim();

      return { sanitizedMessage, sanitizedUrl: url, fileType };
    } else {
      throw new Error("No valid URL found in the input message");
    }
  }

  useEffect(() => {
    if (message !== "") {
      const { sanitizedMessage, sanitizedUrl, fileType } =
        extractAndSanitizeLink(message) ?? {
          sanitizedMessage: message,
          sanitizedUrl: "",
          fileType: "unknown",
        };
      setSanitizedMessage(sanitizedMessage);
      setSanitizedUrl(sanitizedUrl);
      setFileType(fileType);
    }
  }, [message]);

  function stripHtmlTags(html: string) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  const handleCopy = () => {
    const textToCopy = sms
      ? sms
      : `${stripHtmlTags(sanitizedMessage)} ${sanitizedUrl}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        success("Copied to clipboard");
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  const handleHospitalChange = (value: string) => {
    const hospital = hospitals?.find(
      (item: ParticularDetails) => item.id === value
    );
    setSelectedHospital(hospital?.name as string);
    setSelectedHospitalId(hospital?.id as string);
    setSelectedHospitalEmail(hospital?.email);
    setSelectedHospitalPhone(hospital?.phone);
    setHospitalError("");
  };

  const handleChannelChange = (value: string) => {
  
    setSelectedChannel(value);
    setChanelError("");
  };

  const handlePurposeChange = (event: SelectChangeEvent<string>) => {
    setSelectedPurpose(event.target.value as string);
    const selectedDetails = drip?.find(
      (item: any) => item?.title === event.target.value
    );
    if (selectedDetails) {
      setSelectedLink(selectedDetails.asset);
      setSelectedDripId(selectedDetails.id);
      setSelectedDescription(selectedDetails.description);
      setSelectedMessage(selectedDetails.message);
      setSelectedEmail(selectedDetails.email);
      setSelectedWhatsApp(selectedDetails.whatsapp);

      setAvailablePlatforms(
        selectedDetails.platform ? selectedDetails.platform.split(",") : []
      );
      setSelectedType(selectedDetails.type);
    } else {
      setSelectedLink(null);
      setSelectedDripId("");
      setSelectedDescription("");
      setSelectedMessage(null);
      setSelectedType("");
      setAvailablePlatforms([]);
    }
    setPurposeError("");
  };

  const handleCloseViewDrip = () => {
    setViewDrip(null);
  };

  const handleBackdropClose = () => setViewDrip(null);

  const handlePreview = () => {
    if (!selectedHospital) {
      setHospitalError("No hospital was selected");
      return;
    }
    if (!selectedPurpose) {
      setPurposeError("No purpose was selected");
      return;
    }
    if (!selectedChannel) {
      setChanelError("No channel was selected");
      return;
    }
    setViewDrip({
      recipient: selectedHospital,
      title: selectedPurpose,
      description: selectedDescription,
      message: selectedMessage,
      email: selectedEmail,
      whatsapp: selectedWhatsApp,
      asset: selectedLink,
      platform: selectedChannel,
      type: selectedType,
    });
    // if (selectedLink) {
    //   window.open(selectedLink, "_blank");
    // }
  };
  // const sendEmail = (
  //   recipient: string,
  //   subject: string | number,
  //   body: string
  // ) => {
  //   window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(
  //     subject
  //   )}&body=${encodeURIComponent(extractAndSanitizeLink(body))}`;
  // };

  // const sendSMS = (number: number | string, message: string) => {
  //   if (!number || !message) {
  //     error("Number or message is missing");
  //     return;
  //   }

  //   // Check if the device is a mobile device
  //   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  //   if (!isMobile) {
  //     error("SMS functionality is only supported on mobile devices.");
  //     return;
  //   }

  //   const smsLink = `sms:+234${number}?body=${encodeURIComponent(message)}`;

  //   // Attempt to open the SMS link
  //   try {
  //     window.location.href = smsLink;
  //   } catch (err: any) {
  //     error("Failed to launch SMS:", err);
  //   }
  // };

  // const sendWhatsAppMessage = (number: string, message: string) => {
  //   const sanitizedMessage = extractAndSanitizeLink(message);
  //   const url = `https://wa.me/+234${number}?text=${encodeURIComponent(
  //     sanitizedMessage
  //   )}`;
  //   window.open(url, "_blank");
  // };

  const sendDrip = async () => {
    if (!selectedHospital) {
      setHospitalError("No hospital was selected");
      return;
    }
    if (!selectedPurpose) {
      setPurposeError("No purpose was selected");
      // return;
    }
    if (!selectedChannel) {
      setChanelError("No channel was selected");
      return;
    }

   
    const destination =
      selectedChannel === "Email"
        ? selectedHospitalEmail
        : selectedHospitalPhone;

    const formData = new FormData();
    formData.append("hospital_id", selectedHospitalId);
    formData.append("drip_id", selectedDripId);
    formData.append("channel", selectedChannel.toLowerCase());
    formData.append("rep_id", user?.id as string);
    formData.append("id", selectedHospitalId);
    formData.append("destination", destination as string);

    mutatePostDrip(formData, {
      onSuccess: (data: { data: { description: string; message: string } }) => {
        // console.log(data?.data?.message);

        if (selectedChannel === "SMS") {
          setSms(data?.data?.message);
          setShowModal(true);
          // alert("SMS drips are not supported in this environment");
          // return;
        } else setMessage(data?.data?.message);
        // if (selectedChannel === "Email") {
        //   sendEmail(
        //     selectedHospitalEmail as string,
        //     selectedDescription,
        //     data?.data?.Message as string
        //   );
        // }
        // if (selectedChannel === "WhatsApp") {
        //   sendWhatsAppMessage(
        //     selectedHospitalPhone as string,
        //     data?.data?.Message as string
        //   );
        // }
        // if (selectedChannel === "SMS") {
        //   sendSMS(
        //     selectedHospitalPhone as string,
        //     data?.data?.Message as string
        //   );
        // }
        if (viewDrip) {
          setViewDrip(null);
        }
        setShowModal(true);
        // success(data?.data?.description ?? "Drip sent successfully");
      },
      onError: (err: any) => {
        console.log(err);
        error(err?.response?.data?.description ?? "Error sending drip");
      },
    });
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  const renderPreview = (assetType: string, previewURL: string | undefined) => {
    if (!previewURL) return null;

    switch (assetType) {
      case "picture":
        return (
          <img
            className="rounded-md object-fit max-h-full"
            src={`https://${baseUrl}${previewURL}`}
            alt="preview"
          />
        );
      case "pdf":
        return (
          <embed
            src={`https://${baseUrl}${previewURL}`}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        );
      case "video":
        return (
          <video className="rounded-md object-fit max-h-full" controls>
            <source
              src={`https://${baseUrl}${previewURL}`}
              type={`${assetType}/mp4`}
            />
            Your browser does not support the video tag.
          </video>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <ExodusLoading message={"Submitting drip"} visible={isPending} />
      {showModal && <Modal text="Your Drip has been sent" />}
      <ExodusHeader
        Text={"Send A Drip"}
        subText={"| Exodus Arena > Send a Drip"}
      />
      <div className="w-11/12 mx-auto">
        <div className="flex  items-center">
          <p className=" text-[#49209F] text-8xl">
            <GoDash />
          </p>
          <p className="text-2xl">Find a hospital and send drip to them</p>
        </div>
        <div className="bg-[#FFFFFF] shadow-white shadow-sm rounded w-full mx-auto md:p-4 mb-2">
          <form>
            <div className="w-11/12 mx-auto mb-4">
              <p className="font-bold text-2xl mb-2">Find Hospital</p>

              <ReactSelect
                placeholder="Select Hospital"
                options={hospitals?.map(
                  (hospital: { id: string | number; name: string }) => ({
                    value: hospital.id,
                    label: hospital.name,
                  })
                )}
                onChange={handleHospitalChange}
              />
              {hospitalError && (
                <span className="text-red-500">{hospitalError}</span>
              )}

              <p className="font-bold text-2xl mb-4 mt-4">
                Select hospital value
              </p>

              <Select
                value={selectedPurpose}
                onChange={handlePurposeChange}
                displayEmpty
                inputProps={{ "aria-label": "Select hospital value" }}
                renderValue={(value) => (
                  <InputLabel>
                    {value ? value : "Select a hospital value"}
                  </InputLabel>
                )}
                className="w-full h-[50px]"
                placeholder="select hospital value"
              >
                {drip?.map((item: any) => (
                  <MenuItem key={item?.id} value={item?.title}>
                    {item?.title}
                  </MenuItem>
                ))}
              </Select>
              {purposeError && (
                <span className="text-red-500">{purposeError}</span>
              )}
            </div>

            <div className="border outline-dashed outline-2 outline-offset-2 outline-blue-500 md:w-11/12 mx-auto flex h-80 gap-3 max-h-72 justify-center p-3 items-center rounded mb-4">
              {renderPreview(
                selectedType?.toLowerCase(),
                selectedLink as string
              )}

              {/* <div className="flex flex-wrap justify-around gap-5 items-center my-auto">
                <div className="flex items-center justify-center max-w-72">
                  <Tooltip className="truncate" title={selectedDescription}>
                    <div>{selectedDescription}</div>
                  </Tooltip>
                </div>
              </div> */}
            </div>

            <div className="w-11/12 mx-auto flex mb-2 ml-2 ">
              <p className="text-[#b70303]">
                <FaExclamationCircle
                  size={20}
                  color={"#b70303"}
                  className="text-[#b70303] text-center mb-2 ml-2"
                />
              </p>

              <p className="mr-2 ml-4">
                Video drips can only be sent through Email and Whatsapp
              </p>
            </div>
            <div className="flex flex-col w-11/12 mx-auto">
              <p className="text-2xl mb-2 mt-2">Select channel</p>
              <div className="flex w-8/12 justify-between items-center mb-2 mt-2">
                {availablePlatforms?.map((platform: string, index: number) => (
                  <span
                    className="flex justify-center items-center"
                    key={index}
                  >
                    <RadioButton
                      id={platform}
                      value={platform}
                      checked={selectedChannel === platform}
                      onChange={() => handleChannelChange(platform)}
                      label={platform}
                    />
                    <div className="flex">
                      {/* You can add icons for each platform if needed */}
                    </div>
                  </span>
                ))}
              </div>
              {chanelError && (
                <p className="text-red-500">Please select a channel</p>
              )}

              <div className="flex justify-between gap-8 mt-4">
                <ExodusButton
                  text={isMobile ? "Preview" : "Preview Drip"}
                  onClick={handlePreview}
                  icon={<MdOutlineRemoveRedEye />}
                />
                <ExodusButton
                  onClick={sendDrip}
                  text={isMobile ? "Send" : "Proceed to Send"}
                  icon={<PiTelegramLogoThin />}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* Preview modal */}
      <TransitionsModal
        open={!!viewDrip}
        setOpen={setViewDrip}
        handleCloseModal={handleCloseViewDrip}
        handleBackdropClose={handleBackdropClose}
        title="Drip Preview"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: "10px",
        }}
        footer={
          <div className="w-44">
            <ExodusButton
              onClick={sendDrip}
              text={isMobile ? "Send" : "Proceed to Send"}
              icon={<PiTelegramLogoThin />}
            />
          </div>
        }
      >
        <>
          <div className="w-full border rounded-md">
            <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
              <div className="w-1/3 min-h-full">
                <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start rounded-tl-md">
                  To:
                </p>
              </div>

              <div className="bg-white h-full w-full">
                <p className="px-4 py-3 flex-1 w-full text-start">
                  {viewDrip?.recipient}{" "}
                  {(selectedHospitalPhone || selectedHospitalEmail) && (
                    <>
                      <span className="text-gray-400">|</span>{" "}
                      <span className="text-gray-400">
                        {selectedChannel === "Email"
                          ? selectedHospitalEmail
                          : selectedHospitalPhone}
                      </span>
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
              <div className="w-1/3 min-h-full">
                <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start rounded-tl-md">
                  Subject:
                </p>
              </div>

              <div className="bg-white h-full w-full">
                <p className="px-4 py-3 flex-1 w-full text-start">
                  {viewDrip?.description}
                </p>
              </div>
            </div>
            {/* <div className="flex items-start justify-between w-full border-b bg-[#A0A0A0A0]">
              <div className="w-1/3 min-h-full">
                <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start">
                  Description:
                </p>
              </div>
              <div className="bg-white h-full w-full">
                <p className="px-4 py-3 flex-1 w-full text-start">
                  {viewDrip?.description}
                </p>
              </div>
            </div> */}
            <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
              <div className="w-1/3 min-h-full">
                <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start">
                  Message:
                </p>
              </div>
              <div className="bg-white h-full w-full">
                <div className="px-4 py-3 flex-1 w-full text-start ">
                  {viewDrip?.platform === "Email" ? (
                    <EmailContent htmlContent={viewDrip?.email} />
                  ) : viewDrip?.platform === "WhatsApp" ? (
                    viewDrip?.whatsapp
                  ) : (
                    viewDrip?.message
                  )}
                </div>
              </div>
            </div>

            <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
              <div className="w-1/3 min-h-full">
                <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start rounded-bl-md">
                  Asset:
                </p>
              </div>

              <div className="bg-white h-full w-full">
                <div className="px-4 py-3 flex-1 w-full">
                  {renderPreview(
                    viewDrip?.type?.toLowerCase(),
                    viewDrip?.asset
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      </TransitionsModal>

      {/* Copy message modal */}
      <TransitionsModal
        open={showModal}
        setOpen={setShowModal}
        handleCloseModal={() => {
          setShowModal(false);
          setSms("");
          setMessage("");
          setSanitizedMessage("");
          setSanitizedUrl("");
          setFileType("");
        }}
        handleBackdropClose={handleBackdropClose}
        title={
          selectedChannel !== "Email"
            ? "Copy Drip to send"
            : "Email Sent Successfully"
        }
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: "10px",
        }}
        footer={
          <div className="w-">
            {selectedChannel !== "Email" ? (
              <Button
                onClick={handleCopy}
                className="bg-[#4D26A0] hover:bg-[#9166ee] text-[#fff] text-center h-[50px] w-full flex items-center justify-center rounded cursor-pointer font-bold"
              >
                Copy Message To Send On Your Device
              </Button>
            ) : (
              "Email Sent Successfully!"
            )}
          </div>
        }
      >
        {
          <div className="w-full border rounded-md">
            <div className="bg-[#A0A0A0A0] flex items-start justify-between w-full border-b">
              <div className="w-1/3 min-h-full">
                <p className="px-4 py-3 font-bold text-[0.8rem] md:text-base w-full text-start rounded-tl-md">
                  Message:
                </p>
              </div>

              <div className="bg-white h-full w-full">
                <div className="px-4 py-3 flex-1 w-full text-start">
                  {sms ? (
                    <p>{sms}</p>
                  ) : (
                    <EmailContent htmlContent={sanitizedMessage} />
                  )}
                  {/* {stripHtmlTags(sanitizedMessage)} */}
                </div>
              </div>
            </div>
          </div>
        }
        {/* <div dangerouslySetInnerHTML={{ __html: sanitizedMessage }}></div> */}
        {fileType === "image" && (
          <img src={sanitizedUrl} alt="content" style={{ width: "100%" }} />
        )}
        {fileType === "video" && (
          <video src={sanitizedUrl} controls style={{ width: "100%" }} />
        )}
        {fileType === "pdf" && (
          <embed
            src={`${sanitizedUrl}`}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        )}
        {fileType === "unknown" && <p>Cannot preview this file type.</p>}
      </TransitionsModal>
    </div>
  );
};

export default SendADrip;
