import Login from "../view/login";
import ForgotPassword from "../view/forgotPassword";
import Logout from "../view/logout";
import navigationRoutes from "../../../routes/navigationRoutes";
import SelectCountry from "../view/selectCountry";
import UserType from "../view/userType";
import MergeAccount from "../view/mergeAccount";

const authRoutes = [
  {
    path: navigationRoutes.login,
    element: <Login />,
  },

  {
    path: navigationRoutes.logout,
    element: <Logout />,
  },
  {
    path: navigationRoutes.forgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: navigationRoutes.selectCountry,
    element: <SelectCountry />,
  },
  {
    path: navigationRoutes.userType,
    element: <UserType />,
  },
  {
    path: navigationRoutes.forgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: navigationRoutes.mergeAccount,
    element: <MergeAccount />,
  },

  // {
  //   path: "/404",
  //   element: <NotFoundPage />,
  // },
];

export default authRoutes;
