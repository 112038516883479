import { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import httpService from "./httpService";
import { QuotationItemForBackend } from "../ts-utils/interfaces";

export const product = (item: string) => {
  return httpService.get(
    `https://lab.lifebank.ng/labx/api/v1/orders/stockbank/products/${item}`
  );
};

export const sendQuotations = (payload: any) => {
  const options: AxiosRequestConfig = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  return httpService.post(
    `https://lab.lifebank.ng/labx/api/v1/orders/stockbank/quotations`,
    payload,
    options
  );
};

export const uploadQuotation = (
  payload: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options: AxiosRequestConfig = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    },
  };
  // https://lab.lifebank.ng/labx/api/v1/orders/stockbank/po/upload
  return httpService.post(
    `https://lab.lifebank.ng/labx/api/v1/orders/stockbank/po/upload`,
    payload,
    options
  );
};

export const requestItem = (payload: any) => {
  const options: AxiosRequestConfig = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  return httpService.post(
    `https://lab.lifebank.ng/labx/api/v1/orders/stockbank/find/items`,
    payload,
    options
  );
};
export const requestEquipment = (payload: any) => {
  const options: AxiosRequestConfig = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  return httpService.post(
    `https://lab.lifebank.ng/labx/api/v1/orders/quip/find/items`,
    payload,
    options
  );
};

export const getEquipment = () => {
  return httpService.get(
    `https://lab.lifebank.ng/labx/api/v1/orders/quip/departments`
  );
};

export const getEquipmentList = (id: string) => {
  return httpService.get(
    `https://lab.lifebank.ng/labx/api/v1/orders/quip/departments/${id}`
  );
};

export const sendQuipQuotation = (data: any) => {
  return httpService.post(
    `https://lab.lifebank.ng/labx/api/v1/orders/quip/quotations`,
    data
  );
};

export const getAllOrders = (id: string) => {
  return httpService.get(`/admin/orders/history/${id}`);
};

export const getPendingOrders = (id: string) => {
  return httpService.get(`/admin/pending/orders/consumables/${id}`);
};

export const trackOrder = (
  order_id: string,
  dispatch: string,
  product: string
) => {
  return httpService.get(
    `/admin/orders/tracker/${dispatch}/${product}/${order_id}`
  );
};

export const requestTracking = (
  order_id: string,
  dispatch: string,
  product: string
) => {
  return httpService.post(
    `https://lab.lifebank.ng/labx/api/v1/orders/tracker/${dispatch}/${product}/${order_id}`
  );
};
export const getOrderHistory = (order_id: string, order_type: string) => {
  return httpService.get(
    `https://lab.lifebank.ng/labx/api/v1/orders/history/single/${order_id}/${order_type}`
  );
};

export const confirmDelivery = (order: { id: string; product: string }) => {
  return httpService.post(
    `https://lab.lifebank.ng/labx/api/v1/orders/tracker/confirm`,
    order
  );
};

export const getQuotations = (quotation: {
  hospital_ref: string;
  type: string;
}) => {
  return httpService.get(
    `https://lab.lifebank.ng/labx/api/v1/orders/${quotation.type}/quotations/${quotation.hospital_ref}`
  );
};
export const getQuotationDetails = (id: string) => {
  return httpService.get(
    `https://lab.lifebank.ng/labx/api/v1/orders/stockbank/quotations/items/${id}`
  );
};

export const dismissQuotation = (payload: {
  quote_id: string;
  stockOrQuip: string;
}) => {
  return httpService.post(
    `https://lab.lifebank.ng/labx/api/v1/orders/${payload.stockOrQuip}/quotation/reject`,
    {
      quote_id: payload.quote_id,
    }
  );
};

export const placeOrder = (data: {
  quotation_id: string;
  hospital_id: string;
  payment: string;
  discount: string;
  total: number;
  requester: string;
  items?: QuotationItemForBackend[];
  item?: QuotationItemForBackend;
  channel_type: string;
  stockOrQuip: string;
}) => {
  return httpService.post(
    `https://lab.lifebank.ng/labx/api/v1/orders/${data.stockOrQuip}/placeorder`,
    data
  );
};

export const getPaymentTerms = (id: string) => {
  return httpService.get(
    `https://lab.lifebank.ng/labx/api/v1/auth/user/${id}`
  );
};

const purchaseOrderServices = {
  product,
  sendQuotations,
  uploadQuotation,
  requestItem,
  requestEquipment,
  getEquipment,
  sendQuipQuotation,
  getEquipmentList,
  getAllOrders,
  getPendingOrders,
  trackOrder,
  requestTracking,
  getOrderHistory,
  confirmDelivery,
  getQuotations,
  getQuotationDetails,
  dismissQuotation,
  placeOrder,
  getPaymentTerms,
};

export default purchaseOrderServices;
