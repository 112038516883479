import React, {
  // SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as Dialog from "@radix-ui/react-dialog";

import Header from "../../../../components/blocks/header";
import SearchQuery from "../../../../components/forms/searchQuery";

import { getPrivileges } from "../../../../services/authService";
import { useGlobalContext } from "../../../../contexts/globalContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import applyFilters from "../../../../helpers/urlState";
import ProductTable, {
  ProductTableColumn,
} from "../../../../components/common/ProductTable";
import { Hospital } from "../../../../ts-utils/types";
import { SyncLoader } from "react-spinners";
import ReactSelect from "../../../../components/common/ReactSelect";

import { useGetAllExodus } from "../../../../hooks/useExodus";

import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { Button } from "../../../../@/components/ui/button";
import { ChevronDown, MoreVertical, Pencil } from "lucide-react";
import {
  useGetAllAgents,
  useUpdateChurn,
} from "../../../../hooks/agent/useAgent";
import { Separator } from "../../../../@/components/ui/separator";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import DateCell from "../../../../components/DateCell";
import { AgentFilter } from "../hospitals/hospitals";

const updateSchema = z.object({
  reason: z.string().min(1, "Reason is required"),
  status: z
    .string({ message: "Status is required" })
    .min(1, "Status is required"),
});

type UpdateFormData = z.infer<typeof updateSchema>;

export const formatDateToUnix = (date: Date) => {
  if (date) {
    // Adjust for timezone offset
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return Math.floor(localDate.getTime() / 1000); // Convert to Unix timestamp (seconds)
  }
};

const filterStatus = [
  {
    value: "open",
    label: "Open",
  },
  {
    value: "good",
    label: "Good",
  },
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "close",
    label: "Close",
  },
];
const ChurnPage = (): JSX.Element => {
  const privileges = getPrivileges();

  const [updateChurn, setUpdateChurn] = useState(false);
  const { mutateUpdateChurn, isPending: isUpdating } = useUpdateChurn();
  const { user } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");

  const pageSize: number = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState<string>(
    searchParams.get("status") || "not_null"
  );
  const [search, setSearch] = useState(searchParams.get("search") || "");

  const userId = searchParams.get("user_id") || "";

  const getUserId = useCallback(
    (user: string) => {
      if (!privileges.createAgent) {
        return user;
      } else if (userId) {
        return userId;
      }
      return "";
    },
    [privileges.createAgent, userId]
  );

  const filters = {
    user_id: getUserId(user?.id as string),
    limit: pageSize,
    page: currentPage,
    name: search,
    status: "churn",

    churnstatus: status,
  };

  const { allExodus, paginate, isPending } = useGetAllExodus(
    { ...filters },
    "churn"
  );

  const [hospitalInfoToUpdate, setHospitalInfoToUpdate] =
    useState<Hospital | null>(null);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateFormData>({
    resolver: zodResolver(updateSchema),
  });

  const onSubmit = async (data: UpdateFormData) => {
    mutateUpdateChurn(
      { ...data, hospital_id: hospitalInfoToUpdate.id },
      {
        onSuccess: () => {
          setUpdateChurn(false);
          setHospitalInfoToUpdate(null);
        },
      }
    );
  };

  const handleUpdateSelect = (hospital: Hospital): void => {
    setHospitalInfoToUpdate(hospital);
    setUpdateChurn(true);
  };
  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "name":
        return (
          <>
            {hospital?.id ? (
              <Link
                className="text-[#49209F]  underline font-bold"
                to={`/hospitals/${hospital?.id}`}
              >
                {hospital?.name ?? "N/A"}
              </Link>
            ) : (
              <> {hospital?.name ?? "N/A"}</>
            )}
          </>
        );
      case "agent_notified_date":
        return (
          <DateCell
            dateString={hospital[column.accessor]}
            format="ordinal-short"
          />
        );
      case "productlist":
        return (
          <span>
            {hospital[column.accessor] === ""
              ? "N/A"
              : hospital[column.accessor]}
          </span>
        );
      case "status":
        return (
          <span
            style={{
              backgroundColor:
                hospital[column.accessor] === "open"
                  ? "#4CAF50"
                  : hospital[column.accessor] === "good"
                  ? "#3B82F6"
                  : hospital[column.accessor] === "close"
                  ? "#f44336"
                  : hospital[column.accessor] === "pending"
                  ? "#FFD700"
                  : "#f44336",
              color: "white",
              padding: "5px 10px",
              borderRadius: "20px",
              display: "inline-block",
              textTransform: "uppercase",
            }}
          >
            {hospital[column.accessor] ?? "N/A"}
          </span>
        );
      case "action":
        return (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <>
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={() => {
                      handleUpdateSelect(hospital);
                    }}
                  >
                    <Pencil className="mr-2 h-4 w-4" /> Update
                  </DropdownMenuItem>
                </>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      default:
        return <span>{hospital[column.accessor] ?? "N/A"}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Hospital", accessor: "name" },
    { header: "Assigned", accessor: "username" },
    { header: "Product", accessor: "productlist" },
    {
      header: "Date",
      accessor: "agent_notified_date",
      className: "text-nowrap",
    },

    {
      header: "Churn Note",
      accessor: "churn_note",
      className: "max-w-32 w-32",
    },
    { header: "Reason", accessor: "reason", className: "max-w-32 w-32" },
    {
      header: "Next Steps",
      accessor: "action",
      className: "max-w-32 w-32",
    },
  ];
  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);

  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search]
  );

  const handleStatusChange = (status: React.SetStateAction<string>) => {
    setStatus(status);
    applyFilters(
      {
        page: 1,
        status,
      },
      navigate,
      pathname
    );
  };

  const handleSearch = (e: any) => {
    setSearch(e);
    applyFilters(
      {
        page: 1,
        search: e,
        status,
      },
      navigate,
      pathname
    );
  };

  const { agents } = useGetAllAgents(1, 1000, "", "", "0");

  const usernameFilter = Array.from(
    new Set(
      agents
        ?.filter((agent: { position: string }) =>
          ["sales_rep", "sales_lead"].includes(agent.position)
        )
        .map((agent: { id: string; username: string }) => {
          const nameParts = agent.username.replace(/([a-z])([A-Z])/g, "$1 $2");
          return {
            id: agent.id,
            name: nameParts,
          };
        })
    )
  ).sort((a, b) =>
    (a as { name: string }).name.localeCompare((b as { name: string }).name)
  );

  const handleAgentFilter = (agent: string) => {
    applyFilters(
      {
        page: 1,
        user_id: agent,
      },
      navigate,
      pathname
    );
  };
  return (
    <React.Fragment>
      <Header title="Churn Action" />

      {isPending ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isPending}
            className="relative"
            size={30}
          />
        </div>
      ) : (
        <div className="hospitals-container">
          <div className="hospitals-container-top">
            <SearchQuery
              name="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => handleSearch(e.currentTarget.value)}
            />

            <div className="right-options gap-5">
              <div className="w-full flex items-center gap-3 self-end">
                {privileges.createAgent && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="outline"
                        className="w-full md:w-44 flex justify-between capitalize text-blue-800 text-sm bg-white"
                      >
                        {userId
                          ? (
                              usernameFilter?.find((user) => {
                                return (user as AgentFilter).id === userId;
                              }) as AgentFilter
                            )?.name || "Filter by Agent"
                          : "Filter by Agent"}
                        <ChevronDown className="ml-2 h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="max-h-44 overflow-y-auto">
                      <DropdownMenuItem onSelect={() => handleAgentFilter("")}>
                        All
                      </DropdownMenuItem>
                      {usernameFilter?.map((agent, index) => (
                        <DropdownMenuItem
                          key={index + 1}
                          onSelect={() =>
                            handleAgentFilter((agent as AgentFilter).id)
                          }
                          className="capitalize"
                        >
                          {(agent as AgentFilter).name}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
                <ReactSelect
                  placeholder="Filter by Status"
                  options={[
                    {
                      value: "not_null",
                      label: "All",
                    },
                    ...filterStatus,
                  ]?.map((status: { value: string; label: string }) => ({
                    value: status.value,
                    label: status.label,
                  }))}
                  onChange={handleStatusChange}
                />
              </div>
            </div>
          </div>

          <div className="hospitals-container-bottom table-responsive mb-5">
            <>
              <ProductTable
                data={allExodus ?? []}
                columns={columns}
                renderCell={renderCell}
                totalPages={paginate?.totalPages}
                currentPage={currentPage}
                goToPage={goToPage}
                noDataMessage={"You Currently don't have any churn hospitals"}
              />
            </>
          </div>
        </div>
      )}
      <Dialog.Root open={updateChurn} onOpenChange={setUpdateChurn}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-xl mx-auto px-1"
          >
            <div className="bg-white rounded-md shadow-lg py-3">
              <div className="flex items-center justify-between px-4">
                <Dialog.Title className="text-lg font-medium text-gray-800 flex items-center flex-wrap">
                  <span className="text-nowrap font-semibold mr-2">
                    Update Status
                  </span>
                </Dialog.Title>
                <Dialog.Close
                  className="p-2 text-black rounded-md hover:bg-gray-100"
                  onClick={() => {}}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <Separator className="my-2 bg-black" />
              <div className="mx-auto space-y-3 text-start ">
                <div className="flex items-center justify-center p-4 w-full">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="space-y-4 w-full"
                  >
                    <div>
                      <label htmlFor="partner-tier">Select Status</label>{" "}
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) => (
                          <ReactSelect
                            placeholder="Select Status"
                            options={filterStatus.map((status) => ({
                              value: status.value,
                              label: status.label,
                            }))}
                            onChange={(selectedOption) =>
                              field.onChange(selectedOption)
                            }
                          />
                        )}
                      />
                    </div>
                    <div className="w-full">
                      <label>Reason</label>
                      <textarea
                        {...register("reason")}
                        placeholder="Why do you want to update the hospital status?"
                        className={`w-full p-1 border ${
                          errors.reason ? "border-red-500" : "border-gray-300"
                        } rounded-md focus:ring-2 focus:ring-indigo-500`}
                      />
                      {errors.reason && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.reason.message}
                        </p>
                      )}
                    </div>

                    <Button
                      disabled={isUpdating}
                      type="submit"
                      className="w-full p-3 bg-blue-600 text-white rounded-md shadow-md transition-all"
                    >
                      {isUpdating ? "Submitting..." : "Submit"}
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      {/* <NotificationWithSound /> */}
    </React.Fragment>
  );
};

export default ChurnPage;
