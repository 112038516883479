import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";

import Header from "../../../../components/blocks/header";
import SearchQuery from "../../../../components/forms/searchQuery";

import { getPrivileges } from "../../../../services/authService";

import { useGlobalContext } from "../../../../contexts/globalContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import applyFilters from "../../../../helpers/urlState";
import ProductTable, {
  ProductTableColumn,
} from "../../../../components/common/ProductTable";
import { Hospital } from "../../../../ts-utils/types";
import { SyncLoader } from "react-spinners";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../@/components/ui/dropdown-menu";
import { Button } from "../../../../@/components/ui/button";
import { Bell, ChevronDown, MoreVertical } from "lucide-react";
import { z } from "zod";
import {
  useChurnNotify,
  useGetAgentAdminHospitals,
  useGetAllAgents,
} from "../../../../hooks/agent/useAgent";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { Separator } from "../../../../@/components/ui/separator";

import DateCell from "../../../../components/DateCell";
import { AgentFilter } from "../hospitals/hospitals";
import { cn } from "../../../../@/lib/utils";

const updateSchema = z.object({
  note: z
    .string({ message: "Additional comment is required" })
    .min(1, "Additional comment is required is required"),
});

type UpdateFormData = z.infer<typeof updateSchema>;

const ChurnWorld = (): JSX.Element => {
  const privileges = getPrivileges();
  const { user } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");

  const [search, setSearch] = useState(searchParams.get("search") || "");

  const [updateChurn, setUpdateChurn] = useState(false);
  const { mutateChurnNotify, isPending: isUpdating } = useChurnNotify();

  const [currentPage, setCurrentPage] = useState(1);
  const userId = searchParams.get("user_id") || "";

  const getUserId = useCallback(
    (user: string) => {
      if (!privileges.createAgent) {
        return user;
      } else if (userId) {
        return userId;
      }
      return "";
    },
    [privileges.createAgent, userId]
  );

  const filters = useMemo(() => {
    return {
      limit: 20,
      userId: getUserId(user?.id as string),
      page: currentPage,
      name: search,
      churn_status: "churn",
    };
  }, [getUserId, user?.id, currentPage, search]);

  const { hospitals, paginate, isPending } = useGetAgentAdminHospitals(filters);

  const churnHospital = hospitals?.map(
    (churn: {
      id: string | number;
      name: string;
      health_score: string;
      productlist: string;
      score_track: {
        group: string;
        hascomplaint: boolean;
        latedelivery: boolean;
        placedorder: boolean;
        productscount: number;
        usenerve: boolean;
      } | null;
      reward_track: {
        usenervepay: boolean;
        referredothers: boolean;
        onrewardprogramme: boolean;
        creditplan: boolean;
      } | null;
    }) => {
      const scoreTrack =
        typeof churn?.score_track === "string"
          ? JSON.parse(churn.score_track)
          : churn.score_track;

      const rewardTrack =
        typeof churn?.reward_track === "string"
          ? JSON.parse(churn.reward_track)
          : churn.reward_track;

      const ordered = scoreTrack.placedorder ? "Yes" : "No";
      const product = scoreTrack.productscount;
      const nerve_usage = scoreTrack.usenerve ? "Yes" : "No";
      const hot_complaint = scoreTrack.hascomplaint ? "Yes" : "No";
      const late_delivery = scoreTrack.latedelivery ? "Yes" : "No";
      const referrals = rewardTrack.referredothers ? "Yes" : "No";
      const reward = rewardTrack.onrewardprogramme ? "Yes" : "No";
      const pay_plan = rewardTrack.creditplan ? "Yes" : "No";

      return {
        ...churn,
        ordered,
        product,
        nerve_usage,
        hot_complaint,
        late_delivery,
        referrals,
        reward,
        pay_plan,
      };
    }
  );

  const [hospitalInfoToUpdate, setHospitalInfoToUpdate] =
    useState<Hospital | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateFormData>({
    resolver: zodResolver(updateSchema),
  });

  const onSubmit = async (data: UpdateFormData) => {
    mutateChurnNotify(
      {
        ...data,
        hospital_id: hospitalInfoToUpdate.id,
        agent_id: hospitalInfoToUpdate.user_id,
        type: hospitalInfoToUpdate.type,
      },
      {
        onSuccess: () => {
          setUpdateChurn(false);
          setHospitalInfoToUpdate(null);
        },
      }
    );
  };

  const handleUpdateSelect = (hospital: Hospital): void => {
    setHospitalInfoToUpdate(hospital);

    setUpdateChurn(true);
  };

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "name":
        return (
          <>
            {hospital?.id ? (
              <Link
                className={cn(
                  "text-ohaRed-500 hover:text-ohaRed-600 active:text-ohaRed-700 underline font-bold",
                  hospital.churn_status === "risk" &&
                    "text-ohaYellow-700 hover:text-ohaYellow-800 active:text-ohaYellow-900"
                )}
                to={`/hospitals/${hospital?.id}`}
              >
                {hospital?.name ?? "N/A"}
              </Link>
            ) : (
              <> {hospital?.name ?? "N/A"}</>
            )}
          </>
        );

      case "last_order_date":
        return (
          <DateCell
            dateString={hospital[column.accessor]}
            format="datetime-12"
          />
        );

      case "action":
        return (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <>
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={() => {
                      handleUpdateSelect(hospital);
                    }}
                  >
                    <Bell className="mr-2 h-4 w-4" /> Notify
                  </DropdownMenuItem>
                </>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );

      default:
        return <span>{hospital[column.accessor] ?? "N/A"}</span>;
    }
  }

  let columns: ProductTableColumn[] = [
    { header: "Hospital Name", accessor: "name", className: "max-w-44 w-44" },
    { header: "Address", accessor: "address", className: "max-w-32 w-32" },
    {
      header: "Last Known Order",
      accessor: "last_order_date",
      className: "max-w-32 w-32",
    },
    {
      header: "No of Product",
      accessor: "product",
      className: "max-w-32 w-32",
    },
    {
      header: "Lite Orders",
      accessor: "lite_order",
      className: "max-w-32 w-32",
    },

    {
      header: "Nerve Accounts",
      accessor: "nerve_usage",
      className: "max-w-32 w-32",
    },
    {
      header: "Last Known Health Score",
      accessor: "health_score",
      className: "max-w-32 w-32",
    },
    {
      header: "Complaints",
      accessor: "hot_complaint",
      className: "max-w-32 w-32",
    },
  ];

  //   if (privileges.createAgent) {
  //     columns.push({
  //       header: "Action",
  //       accessor: "action",
  //       className: "max-w-32 w-32",
  //     });
  //   }

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);

  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search]
  );

  const handleSearch = (e: any) => {
    setSearch(e);
    applyFilters(
      {
        page: 1,
        search: e,
      },
      navigate,
      pathname
    );
  };

  const { agents } = useGetAllAgents(1, 1000, "", "", "0");

  const usernameFilter = Array.from(
    new Set(
      agents
        ?.filter((agent: { position: string }) =>
          ["sales_rep", "sales_lead"].includes(agent.position)
        )
        .map((agent: { id: string; username: string }) => {
          const nameParts = agent.username.replace(/([a-z])([A-Z])/g, "$1 $2");
          return {
            id: agent.id,
            name: nameParts,
          };
        })
    )
  ).sort((a, b) =>
    (a as { name: string }).name.localeCompare((b as { name: string }).name)
  );
  const handleAgentFilter = (agent: string) => {
    applyFilters(
      {
        page: 1,
        user_id: agent,
      },
      navigate,
      pathname
    );
  };

  return (
    <React.Fragment>
      <Header title="Churn World" />

      {isPending ? (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader
            color="#3B82F6"
            loading={isPending}
            className="relative"
            size={30}
          />
        </div>
      ) : (
        <>
          <div className="hospitals-container">
            <div className="hospitals-container-top">
              <SearchQuery
                name="search"
                placeholder="Search"
                value={search}
                onChange={(e: any) => handleSearch(e.currentTarget.value)}
              />

              <div className="right-options gap-5">
                {privileges.createAgent && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="outline"
                        className="w-full md:w-44 flex justify-between capitalize text-blue-800 text-sm bg-white"
                      >
                        {userId
                          ? (
                              usernameFilter?.find((user) => {
                                return (user as AgentFilter).id === userId;
                              }) as AgentFilter
                            )?.name || "Filter by Agent"
                          : "Filter by Agent"}
                        <ChevronDown className="ml-2 h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="max-h-44 overflow-y-auto">
                      <DropdownMenuItem onSelect={() => handleAgentFilter("")}>
                        All
                      </DropdownMenuItem>
                      {usernameFilter?.map((agent, index) => (
                        <DropdownMenuItem
                          key={index + 1}
                          onSelect={() =>
                            handleAgentFilter((agent as AgentFilter).id)
                          }
                          className="capitalize"
                        >
                          {(agent as AgentFilter).name}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </div>
            </div>
            <div className="w-full grid grid-cols-2 lg:grid-cols-4 gap-3 break-words hyphens-auto">
              <Link
                to={`/churn-world/in-exit?page=1&user_id=${userId}`}
                role="button"
                className={cn(
                  "border hover:shadow-md hover:shadow-red-200 p-2 rounded-md bg-ohaRed-500 bg-gradient-to-b from-ohaRed-500 to-ohaRed-600 hover:from-ohaRed-600 hover:to-ohaRed-700 active:from-ohaRed-700 active:to-ohaRed-800"
                )}
              >
                <div className="w-full flex flex-col items-center text-center">
                  <h2 className="font-semibold text-ohaRed-50 text-xl">
                    In Exit
                  </h2>
                  <p className="text-ohaRed-200">No Orders in 2 years</p>
                  <p className="text-ohaRed-50">Will need to be re-boarded</p>
                </div>
              </Link>
              <Link
                to={`/churn-world/full?page=1&user_id=${userId}`}
                role="button"
                className={cn(
                  "border hover:shadow-md hover:shadow-blue-200 p-2 rounded-md bg-ohaRed-400 bg-gradient-to-b from-ohaRed-400 to-ohaRed-500 hover:from-ohaRed-500 hover:to-ohaRed-600 active:from-ohaRed-600 active:to-ohaRed-700"
                )}
              >
                <div className="w-full flex flex-col items-center text-center">
                  <h2 className="font-semibold text-ohaRed-50 text-xl">
                    Full Churn
                  </h2>
                  <p className="text-ohaRed-200">No Orders in a year</p>
                  <p className="text-ohaRed-50">
                    You cannot reach them and we don't know why.
                  </p>
                </div>
              </Link>
              <Link
                to={`/churn-world/partial?page=1&user_id=${userId}`}
                role="button"
                className={cn(
                  "border hover:shadow-md hover:shadow-orange-200 p-2 rounded-md bg-ohaRed-300 bg-gradient-to-b from-ohaRed-300 to-ohaRed-400 hover:from-ohaRed-400 hover:to-ohaRed-500 active:from-ohaRed-500 active:to-ohaRed-600"
                )}
              >
                <div className="w-full flex flex-col items-center text-center">
                  <h2 className="font-semibold text-ohaRed-50 text-xl">
                    Partial Churn
                  </h2>
                  <p className="text-ohaRed-200">No Orders in a year</p>
                  <p className="text-ohaRed-50">
                    Reason is listed, we can resolve the reason
                  </p>
                </div>
              </Link>
              <Link
                to={`/churn-world/risk?page=1&user_id=${userId}`}
                role="button"
                className={cn(
                  "border hover:shadow-md hover:shadow-green-200 p-2 rounded-md bg-ohaYellow-600 bg-gradient-to-b from-ohaYellow-600 to-ohaYellow-700 hover:from-ohaYellow-700 hover:to-ohaYellow-800 active:from-ohaYellow-800 active:to-ohaYellow-900"
                )}
              >
                <div className="w-full flex flex-col items-center text-center">
                  <h2 className="font-semibold text-ohaYellow-100 text-xl">
                    Churn Risk
                  </h2>
                  <p className="text-ohaYellow-200">
                    No Orders in a quarter, but not up to a year
                  </p>
                </div>
              </Link>
            </div>
            {paginate?.totalItems === 0 ? (
              <p className="my-3 text-xl font-semibold">
                Great Job! You currently have 0 hospital in Churn world. Keep it
                up!
              </p>
            ) : (
              <p className="my-3 text-xl font-semibold">
                {`  You have ${paginate?.totalItems} hospital in the Churn world.
              Please start the work to get these hospitals to active. Good luck!`}
              </p>
            )}

            <div className="hospitals-container-bottom table-responsive mb-5">
              <>
                <ProductTable
                  data={churnHospital ?? []}
                  columns={columns}
                  renderCell={renderCell}
                  totalPages={paginate?.totalPages}
                  currentPage={currentPage}
                  goToPage={goToPage}
                  noDataMessage={"You Currently don't have any churn hospitals"}
                />
              </>
            </div>
          </div>
        </>
      )}

      <Dialog.Root open={updateChurn} onOpenChange={setUpdateChurn}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-xl mx-auto px-1"
          >
            <div className="bg-white rounded-md shadow-lg py-3">
              <div className="flex items-center justify-between px-4">
                <Dialog.Title className="text-lg font-medium text-gray-800 flex items-center flex-wrap">
                  <span className="text-nowrap font-semibold mr-2">
                    Notify Agent
                  </span>
                </Dialog.Title>
                <Dialog.Close
                  className="p-2 text-black rounded-md hover:bg-gray-100"
                  onClick={() => {}}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <Separator className="my-2 bg-black" />
              <div className="mx-auto space-y-3 text-start ">
                <div className="flex items-center justify-center p-4 w-full">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="space-y-4 w-full"
                  >
                    <div className="flex gap-2">
                      <p className="font-semibold">Agent-In-Charge:</p>{" "}
                      <span>{hospitalInfoToUpdate?.username}</span>
                    </div>
                    <div>
                      <label className="font-semibold mb-2">Comment</label>{" "}
                      <Controller
                        name="note"
                        control={control}
                        render={({ field }) => (
                          <textarea
                            {...field}
                            placeholder="Add Additional comment"
                            className={`w-full p-1 border ${
                              errors.note
                                ? "!border-red-500"
                                : "border-gray-300"
                            } rounded-md focus:ring-2 focus:ring-indigo-500`}
                          />
                        )}
                      />{" "}
                      {errors.note && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.note.message}
                        </p>
                      )}
                    </div>

                    <Button
                      disabled={isUpdating}
                      type="submit"
                      className="w-full p-3 bg-blue-600 text-white rounded-md shadow-md transition-all"
                    >
                      {isUpdating ? "Submitting..." : "Submit"}
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </React.Fragment>
  );
};

export default ChurnWorld;
