import httpService from "../services/httpService";
import config from "../utils/config";

export const dashboardData = (id: string | number) => {
  return httpService.get(`/admin/new/dashboard/data/${id}`);
};

export const getData = (userId: string) => {
  return httpService.get("/admin/dashboard/data/" + userId);
};

export const getFeed = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
  status?: string;
  hospitalid?: string;
}) => {
  const queryParams = new URLSearchParams();

  if (
    filters.page !== null &&
    filters.page !== undefined &&
    filters.page !== 0 &&
    filters.page !== ""
  ) {
    queryParams.append("page", filters.page.toString());
  }
  if (
    filters.limit !== null &&
    filters.limit !== undefined &&
    filters.limit !== 0
  ) {
    queryParams.append("limit", filters.limit.toString());
  }

  if (
    filters.name !== null &&
    filters.name !== undefined &&
    filters.name !== ""
  ) {
    queryParams.append("name", filters.name.toLowerCase());
  }
  if (
    filters.user_id !== null &&
    filters.user_id !== undefined &&
    filters.user_id !== ""
  ) {
    queryParams.append("userid", filters.user_id);
  }
  if (
    filters.status !== null &&
    filters.status !== undefined &&
    filters.status !== ""
  ) {
    queryParams.append("status", filters.status);
  }

  if (
    filters.hospitalid !== null &&
    filters.hospitalid !== undefined &&
    filters.hospitalid !== ""
  ) {
    queryParams.append("hospitalid", filters.hospitalid);
  }

  // return httpService.get("/admin/news/feeds/" + userId);
  return httpService.get(`/admin/news/feeds?${queryParams.toString()}`);
};
export const getSingleFeed = (user_id?: string) => {
  return httpService.get(`/admin/news/feed/${user_id}`);
};

export const getAgentRevenue = (userId: string, date_range: string) => {
  return httpService.get(
    `/admin/agent/revenue/${userId}?date_range=${date_range}`
  );
};

export const getTargetRevenue = (userId: string) => {
  return httpService.get("/admin/target/indie/" + userId);
};

export const getTotalHospitals = (userId?: string) => {
  return httpService.get(`/admin/hospitals/all?userid=${userId}`);
};

export const setTarget = (data: any) => {
  return httpService.post("/admin/target/monthly", data);
};
export const getCities = (country: string) => {
  const url = config.countriesApiUrl + "/countries/states";

  return httpService.post(url, { country });
};

export const getHospitalsCount = (filters: {
  user_id?: string;
  status?: string;
  churn_status?: string;
  churn_exit_type?: string;
  health_status?: string;
}) => {
  const queryParams = new URLSearchParams();

  if (
    filters.user_id !== null &&
    filters.user_id !== undefined &&
    filters.user_id !== ""
  ) {
    queryParams.append("userid", filters.user_id);
  }
  if (
    filters.status !== null &&
    filters.status !== undefined &&
    filters.status !== ""
  ) {
    queryParams.append("status", filters.status);
  }

  if (
    filters.health_status !== null &&
    filters.health_status !== undefined &&
    filters.health_status !== ""
  ) {
    queryParams.append("health_status", filters.health_status);
  }

  if (
    filters.churn_status !== null &&
    filters.churn_status !== undefined &&
    filters.churn_status !== ""
  ) {
    queryParams.append("churn_status", filters.churn_status);
  }
  if (
    filters.churn_exit_type !== null &&
    filters.churn_exit_type !== undefined &&
    filters.churn_exit_type !== ""
  ) {
    queryParams.append("churn_exit_type", filters.churn_exit_type);
  }

  return httpService.get(`/admin/hospitals/count?${queryParams.toString()}`);
};
export const getHospitalsOrderCount = (userId: string, date_range: string) => {
  return httpService.get(
    `/manage/hospital/orders/count?user_id=${userId}&date_range=${date_range}`
  );
};

const dashboardService = {
  dashboardData,
  getData,
  getFeed,
  getSingleFeed,
  getAgentRevenue,
  getTargetRevenue,
  getTotalHospitals,
  setTarget,
  getCities,
  getHospitalsCount,
  getHospitalsOrderCount,
};

export default dashboardService;
